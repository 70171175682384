
import React, {useMemo, useState} from "react";
import styled from 'styled-components';
import {useSelector} from "react-redux";

const Map = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const positionSrs = 4326;

export const GeoPortailMap = ( props ) => {
    const el = React.useRef(null);
    const mapRef = React.useRef(null);
    const selectedEvent = useSelector((state) => state.data.selectedEvent);
    const poiConfig = useSelector((state) => state.data.poiConfig);
    let zoom = 16;
    const theme = useSelector((state) => state.config.theme);

    let markerFolder = "default";
    if (theme.geoMarker) markerFolder = theme.geoMarker;
    let markerSelected =  `/images/geomarker/${markerFolder}/ic_marker_selected.png`;
    let markerBorne =  `/images/geomarker/${markerFolder}/ic_marker_borne.png`;

    let center = useMemo(() => {
        let output = [];
        if (selectedEvent) {
            output[0] = selectedEvent.venue.position.lng ? selectedEvent.venue.position.lng : poiConfig.longitude;
            output[1] = selectedEvent.venue.position.lat ? selectedEvent.venue.position.lat : poiConfig.latitude;
        }

        return output;
    }, [selectedEvent]);

    React.useEffect(() => {

        if (!mapRef.current) {
            const mapElement = el.current;
            if (!mapElement) return;

            const map = mapRef.current = new window.lux.Map({
                target: mapElement,
                bgLayer: 'basemap_2015_global',
                positionSrs: positionSrs,
                zoom: zoom,
                position: center
            });


            map.showMarker({
                positioning: 'center-center',
                iconURL: markerSelected,
                //onClick: handleClick
            });

            let pos = [];
            pos[0] = poiConfig.longitude;
            pos[1] = poiConfig.latitude;


            map.showMarker({
                positioning: 'center-center',
                position: pos,
                iconURL: markerBorne,
                positionSrs: positionSrs,
                //onClick: handleClick
            });
        }
        // eslint-disable-next-line
    }, [selectedEvent]);

    // Dispose The Map Reference
    React.useEffect(() => {
        const map = mapRef.current;

        return () => {
            if (map) {
                // map.dispose();
                mapRef.current = null;
            }
        };
    }, [mapRef]);

    if (!selectedEvent.venue.position.lat) return null;

    return (
        <Map
            className = { "geoportailmap" }
            ref = { el }
        />
    );
}


export default GeoPortailMap;