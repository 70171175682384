import React, {useState} from 'react';
import {Translate} from "react-localize-redux";
import {useSelector} from 'react-redux';
import styled, {css} from 'styled-components';

const PollWrapper = styled.div`
    background-color: ${props => props.theme.white};
    color: ${props => props.theme.white};
    height: 100%;
    
    ${props => props.theme.layout === 3 && `
        display: flex;
        flex-direction: row;
    `}
`;

const AnswerWrapper = styled.div`
    ${props => props.theme.layout === 3 && `
        width: 50%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    `}
`;

const Question = styled.div`
    font-size: ${props => props.theme.scale * 52}px;
    font-family: 'MuseoSans-900';
    text-align: center;
    color: ${props => props.theme.black};
    padding: ${props => props.theme.scale * 120}px ${props => props.theme.scale * 40}px;

    ${(props) =>
        props.theme.textDefault
            ? `
            color: ${props.theme.textDefault};
            `
            : `
            color: ${props.theme.black};
    `}

    ${props => props.theme.layout === 3 && `
        width: 50%;
        padding: 0 ${props => props.theme.scale * 40}px;
        display: flex;
        align-items: center;
        justify-content: center;  
    `}
`;

const Answers = styled.div`
    pading: 0;
    margin: 0;
    text-align: center;
    list-style: none;
`;

const AnswerBlock = styled.div`
    position: relative;
`;

const selectedMixin = css`
    svg {
        path, line {
            stroke: ${props => props.theme.shade1};
        }
    }
    ${(props) =>
        props.theme.pollHighlight
            ? `
            svg {
                path, line {
                    stroke: ${props.theme.pollHighlight};
                }
            }
            `
            : `
            svg {
                path, line {
                    stroke: ${props.theme.shade1};
                }
            }
    `}
`;

const Answer = styled.div`
    display: inline-block;
    font-size: ${props => props.theme.scale * 80}px;
    margin: ${props => props.theme.scale * 20}px ${props => props.theme.scale * 10}px;
    padding: ${props => props.theme.scale * 20}px;
    width: ${props => props.theme.scale * 120}px;
    height: ${props => props.theme.scale * 120}px;
    border-radius: 100%;

    svg {
        .st0 {
            display: block;
            fill: none;
        }
    } 

    ${(props) =>
        props.theme.textDefault
            ? `
            svg {
                path, line {
                    stroke: ${props.theme.textDefault};
                }
            }
            `
            : `
            svg {
                path, line {
                    stroke: ${props.theme.highlight};
                }
            }
    `}
    
    ${props => props.selected && selectedMixin};
`;

const SendWrapper = styled.div`
    padding: ${props => props.theme.scale * 120}px 0;
    text-align: center;
    position: absolute;
    left: calc(50% - ${props => props.theme.scale * 130}px);
    
    ${props => props.theme.layout === 3 ? `
        top: ${props.theme.scale * 120}px;
    ` : `
        top: ${props.theme.scale * 240}px;
    `}
`;

const Send = styled.button`
    width: ${props => props.theme.scale * 260}px;
    border-radius: 500px;
    padding: ${props => props.theme.scale * 30}px 0;   
    font-size: ${props => props.theme.scale * 36}px;
    font-family: 'MuseoSans-900';

    ${(props) =>
        props.theme.pollButtonFG
            ? `
            border: ${props.theme.scale * 4}px solid ${props.theme.pollButtonFG};
            color: ${props.theme.pollButtonFG};
            `
            : `
            border: ${props.theme.scale * 4}px solid ${props.theme.shade1};
            color: ${props.theme.shade1}; 
    `}

    ${(props) =>
        props.theme.pollButtonBG
            ? `
            background-color: ${props.theme.pollButtonBG}; 
            `
            : `
            background-color: ${props.theme.white}; 
    `}

    ${props => props.theme.layout === 3 ? `
        top: ${props.theme.scale * 120}px;
    ` : `
        top: ${props.theme.scale * 240}px;
    `}
`;

const Message = styled.div`
    color: ${props => props.theme.shade1};
    font-size: ${props => props.theme.scale * 38}px;
    font-family: 'MuseoSans-900';
    width: ${props => props.theme.scale * 260}px;
    text-align: center;

    ${(props) =>
        props.theme.textDefault
            ? `
            color: ${props.theme.textDefault};
            `
            : `
            color: ${props.theme.shade1};
    `}
`;

const Poll = () => {
    const [itemSelected, setItemSelected] = useState({ value: undefined });
    const [replyText, setReplyText] = useState(false);
    const poll = useSelector(state => state.data.poll);

    if (!poll) return (null);

    const answerClicked = (item) => { 
        setItemSelected(item);
    }

    const sendClicked = async () => { 
        try {
            let httpre = /^https?:/;
            let submitUrl = itemSelected.submiturl;

            if (!httpre.test(submitUrl))
                submitUrl = process.env.REACT_APP_API_URL + submitUrl;

            const response = await fetch(submitUrl);
            const mailStatus = await response.json();

            if (mailStatus.replytext) setReplyText(mailStatus.replytext);
        } catch (error) {
            console.log(error);
        }   
    }

    return (
        <PollWrapper>
            <Question>{poll.question}</Question>
            <AnswerWrapper>
                <AnswerBlock>
                    <Answers>
                        {poll.answers.map((item, i) => (
                            <Answer key={i} onClick={() => answerClicked(item)} selected = {itemSelected.value === item.value} dangerouslySetInnerHTML={{__html: item.icon}}></Answer>
                        ))}
                    </Answers>
                    <SendWrapper>
                        {replyText ?
                            <Message>{replyText}</Message>
                            :
                            <Send onClick={() => sendClicked()}><Translate id={"poll.send"} /></Send>
                        }
                    </SendWrapper>
                </AnswerBlock>
            </AnswerWrapper>
        </PollWrapper>
    );
}

export default Poll;