import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { 
    SET_ACTIONBUTTON
 } from '../redux/actions';

const Content = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.shade3};
    color: ${props => props.theme.white};
    box-sizing: border-box;
    padding: ${props => props.theme.scale * 50}px 0;
`;

const Table = styled.div`
    box-sizing: border-box;
    margin: 0 auto;
    width: ${props => props.theme.scale * 650}px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const ColLeft = styled.div`
    flex: 0 0 40%;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: ${props => props.theme.scale * 20}px 0;
`;

const ColRight = styled.div`
    flex: 0 0 60%;
    text-align: left;
    padding: ${props => props.theme.scale * 20}px 0;
`;

const TitleLeft = styled.div`
    font-size: ${props => props.theme.scale * 44}px;
    font-family: 'MuseoSans-300';
    color: ${props => props.theme.highlight};
    margin-bottom: ${props => props.theme.scale * 20}px;
`;

const TitleRight = styled.div`
    font-size: ${props => props.theme.scale * 44}px;
    font-family: 'MuseoSans-300';
    margin-bottom: ${props => props.theme.scale * 20}px;
`;

const FloorNumber = styled.div`
    font-size: ${props => props.theme.scale * 80}px;
    font-family: 'MuseoSans-900';
    color: ${props => props.theme.highlight};
`;

const FloorItems = styled.div`
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-300';
`;

const FloorPlan = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIONBUTTON, actionButton: "copyright"});
    }, [dispatch]);

    return (
        <Content>
            <Table>
                <ColLeft>
                    <TitleLeft>ETAGE</TitleLeft>
                </ColLeft>
                <ColRight>
                    <TitleRight>HÔTEL DE VILLE</TitleRight>
                </ColRight>

                <ColLeft>
                    <FloorNumber>4</FloorNumber>
                </ColLeft>
                <ColRight>
                    <FloorItems>
                        Délégation des Salaries<br/>
                        Délégation des Fonctionnaires et<br/>
                        Employés Communaux<br/>
                        Salle de Réunion
                    </FloorItems>
                </ColRight>

                <ColLeft>
                    <FloorNumber>3</FloorNumber>
                </ColLeft>
                <ColRight>
                    <FloorItems>
                        Recette communale<br/>
                        Cellule Sécurité<br/>
                        Egalité des Chances<br/>
                        Comptabilité<br/>
                        Développement Urbain et Expansion<br/>
                        Economique
                    </FloorItems>
                </ColRight>

                <ColLeft>
                    <FloorNumber>2</FloorNumber>
                </ColLeft>
                <ColRight>
                    <FloorItems>
                        Structures et Organisation<br/>
                        Service du Personnel<br/>
                        Travaux Municipaux - Gestion Financière
                    </FloorItems>
                </ColRight>

                <ColLeft>
                    <FloorNumber>1</FloorNumber>
                </ColLeft>
                <ColRight>
                    <FloorItems>
                        Collège des Bourgmestre et Echevins<br/>
                        Secrétariat Général<br/>
                        Grande salle des séances<br/>
                        Salle de Mariage<br/>
                        Relations Publiques<br/>
                        Développement Economique / Fête et Manifestation 
                    </FloorItems>
                </ColRight>

                <ColLeft>
                    <FloorNumber>0</FloorNumber>
                </ColLeft>
                <ColRight>
                    <FloorItems>
                        Accueil<br/>
                        Biergeramt<br/>
                        Caisse<br/>
                        Service Doléances<br/>
                        Etat Civil<br/>
                        Service de l’Huissier 
                    </FloorItems>
                </ColRight>
            </Table>
        </Content>
    );
}

export default FloorPlan;