import React, {useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";
import videojs from "video.js";
import {Container} from "../components/Layout";
import VideoJS from '../components/Player';
import images from "../config/images";
import {SET_HOME} from "../redux/actions";

const AdsContainer = styled(Container)`
    background: ${(props) => props.theme.white};
`;

const Ad = styled.div`
    width: 100%;
    height: 100%;
`;

const ShowTouchStyled = styled.div`
    position: absolute;
    right: 0;
    bottom: ${(props) => props.theme.scale * 235}px;
    width: ${(props) => props.theme.scale * 160}px;
    z-index: 100;

    ${(props) =>
        props.theme.showTouchBackground
            ? `
            svg .background {
                fill: ${props.theme.showTouchBackground};
            }
            `
            : `
            svg .background {
                fill: ${props.theme.shade1};
            }
    `}
`;

const SwiperItem = styled.div`
   
        max-height: ${props => props.theme.scale * props.theme.contentHeight}px;          
        transition: max-height 0.5s;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        video {
            width: 100%;
        }
`;

const StyledSlickSlider = styled(SlickSlider)`
    height: 100%;
    
    &:focus {
        outline: none;
    }
    .slick-list {
        padding: 0;
    }
`;

const ThemeIcon = styled.div`
    text-align: center;
    display: block;
`;

const Ads = () => {
    const ads = useSelector((state) => state.data.ads);
    const theme = useSelector(state => state.config.theme);
    const dispatch = useDispatch();
    const mySlider = useRef(undefined);
    const SliderTime = 10000;

    const toggleNav = (e) => {
        dispatch({ type: SET_HOME, home: false });
        e.stopPropagation();
    };

    let autoplay = false;

    const params = {
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        fade: true,
        autoplaySpeed: 1000,
        autoplay: autoplay,
        pauseOnHover: false,
        afterChange: function(index) {

            mySlider.current.slickPause();
            let video = document.querySelector('[data-index="' + index + '"] video');
            if (video){
                var playPromise = video.play();

                if (playPromise !== undefined) {
                    playPromise.then(_ => {
                        // Automatic playback started!
                        // Show playing UI.
                    })
                        .catch(error => {
                            // Auto-play was prevented
                            // Show paused UI.
                        });
                }
                video.currentTime = 0;

                video.onended = function () {
                    mySlider.current.slickNext();
                };

            } else {
                setTimeout(() => { mySlider.current.slickGoTo(index+1); }, SliderTime);
            }
        
        },
        beforeChange: function(index, newIndex) {
            let video = document.querySelector('div[data-index="' + index + '"] video');
            if (video) {
                video.currentTime = 0;
                var playPromise = video.pause();

                if (playPromise !== undefined) {
                    playPromise.then(_ => {
                        // Automatic playback started!
                        // Show playing UI.
                    })
                        .catch(error => {
                            // Auto-play was prevented
                            // Show paused UI.
                        });
                };
            }
        }
    };
   
    const playerRef = React.useRef(null);

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // you can handle player events here
        player.on('ended', () => {
            mySlider.current.slickNext();
        });
    };

    const handleImgOnload = () => {
        setTimeout(() => { if (mySlider.current) mySlider.current.slickGoTo(1); }, SliderTime);
    };

    let slides = [];
    
    if (ads) {
        slides = ads.map(
            (ad, i) => {
                if (ad.video)  {
                    let defaultOptions = { 
                        muted: true,
                        controls: false,
                        responsive: true,
                        preload: 'auto',
                        fluid: true,
                        aspectRatio: '540:763',
                        sources : [{
                            src: ad.video,
                            type: 'video/mp4'
                        }],
                        poster : ad.placeholderurl
                    };
    
                    let videoJsOptionsAuto = {...defaultOptions, autoplay: 'any'};
                    let videoJsOptions = {...defaultOptions, autoplay: false};

                    return(
                        <SwiperItem key={i}>
                        <div className="swiper-slide__container" id={"video" + i}>
                            {i === 0 ? <VideoJS options={videoJsOptionsAuto} onReady={handlePlayerReady} key={i} /> : <VideoJS options={videoJsOptions} key={i} />}
                        </div>
                    </SwiperItem>
                    );
                } else {
                    return (
                        <SwiperItem key={i}>
                            {i === 0 ? <img src={ad.image} alt={ad.title} onLoad={handleImgOnload} /> : <img src={ad.image} alt={ad.title} />}
                        </SwiperItem>
                    )
                }
            },[SwiperItem]
        );
    }

    let showTouch;
    if (!theme.iconSet) {
        const ShowTouch = images.icons.showtouch;
        showTouch = <ShowTouch />;
    } else {
        showTouch = <ThemeIcon><img src={`images/themes/${theme.iconSet}/ic_showtouch.svg`} alt="MR" /></ThemeIcon>;
    }

    let linkTo = '/events';
    if (theme.navAfterAds) linkTo = theme.navAfterAds;

    console.log(theme);

    return (
        <AdsContainer direction="column">
            <Ad>
                <Link to={linkTo} onClick={toggleNav}>
                    <ShowTouchStyled>
                        {showTouch}
                    </ShowTouchStyled>
                    <StyledSlickSlider ref={mySlider} {...params}>{slides}</StyledSlickSlider>
                </Link>
            </Ad>
        </AdsContainer>
    );
};

export default Ads;
