
import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from 'styled-components';
import {SET_SELECTED_IMXPOI, SET_SELECTED_POI, SET_SELECTED_WHATTODO} from "../redux/actions";

const positionSrs = 4326;

const MapDefault = styled.div`
    width: ${(props) => props.theme.scale * props.theme.contentWidth - props.theme.scale * 322}px;
    height: 100%;
    overflow: hidden;
`;

const MapLandscape = styled.div`
    width: ${(props) => props.theme.scale * props.theme.contentWidth - props.theme.scale * 322}px;
    height: 100%;
    overflow: hidden;
`;

const MapPoster = styled.div`
    width: ${(props) => props.theme.scale * props.theme.contentWidth - props.theme.scale * 362}px;
    height: 100%;
    overflow: hidden;
`;

export const GeoPortailMap = ( props ) => {
    const poi = useSelector(state => state.data.poi);
    const poi_helperknapp = useSelector((state) => state.data.poi_helperknapp);
    const poi_imx = useSelector((state) => state.data.imxpoi);
    const poi_whattodo = useSelector((state) => state.data.whattodo);
    const busData = useSelector((state) => state.data.busData);
    //const poiConfig = useSelector(state => state.data.poiConfig);
    const locationConfig = useSelector(state => state.config.location);
    const selectedDefaultPoi = useSelector((state) => state.data.selectedPoi);
    const selectedIMXPoi = useSelector(state => state.data.selectedimxpoi);
    const selectedWhattodo = useSelector(state => state.data.selectedWhattodo);
    const layout = useSelector((state) => state.config.layout);
    const theme = useSelector((state) => state.config.theme);
    const dispatch = useDispatch();
    const [allMarkers, setAllMarkers] = useState([]);

    const el = React.useRef(null);
    const mapRef = React.useRef(null);
    const { type, poitype } = props;
    let zoom = 16;

    let markerFolder = "default";
    if (theme.geoMarker) markerFolder = theme.geoMarker;

    let markerDefault =  `/images/geomarker/${markerFolder}/ic_marker.png`;
    let markerBorne =  `/images/geomarker/${markerFolder}/ic_marker_borne.png`;
    let markerSelected =  `/images/geomarker/${markerFolder}/ic_marker_selected.png`;

    if (layout === 3 || layout === 4)
        zoom = 17

    const addPath = (selectedPoi, map) => {
        console.log(selectedPoi.gpx);
        map.addGPX(selectedPoi.gpx, {click: true, onClick: () => markerClicked(poi)});
    }

    // HACK - We need to change this
    let currentPoi = poi;
    if (poitype === "helperknapp") currentPoi = poi_helperknapp;
    if (poitype === "imxpoi") currentPoi = poi_imx;
    if (poitype === "whattodo") currentPoi = poi_whattodo;
    if (poitype === "bus") currentPoi = busData;

    let selectedPoi;
    if (poitype === 'imxpoi') selectedPoi = selectedIMXPoi; else if (poitype === 'whattodo') selectedPoi = selectedWhattodo; else selectedPoi = selectedDefaultPoi;

    let center = useMemo(() => {
        let output = [];
        if (selectedPoi) {
            output[0] = selectedPoi.position.lng;
            output[1] = selectedPoi.position.lat;
        } else {
            output[0] = locationConfig.longitude;
            output[1] = locationConfig.latitude;
        }

        return output;
    }, [selectedPoi, locationConfig]);


    if (selectedPoi) {
        addPath(selectedPoi, mapRef.current);
    }

    const markerClicked = (poi) => {
        if (poitype === 'imxpoi') dispatch({type: SET_SELECTED_IMXPOI, imxpoi: poi}); else if (poitype === 'whattodo') dispatch({type: SET_SELECTED_WHATTODO, whattodo: poi}); else dispatch({type: SET_SELECTED_POI, poi: poi});
    };

    const addPois = (currentPoi, map, type, isPosUpdated, updatedData) => {

        if (allMarkers.length > 0) {
            currentPoi[type].map((poi, i) => {
                map.removeOverlay(allMarkers[i]);
                return true;
            });
        }

        let myMarkers = [];

        currentPoi[type].map((poi, i) => {
            let pos = [];
            if (isPosUpdated === false){
                pos[0] = poi.position.lng;
                pos[1] = poi.position.lat;
            }
            else {
                pos = updatedData;
            }

            let marker = map.showMarker({
                position: pos,
                positionSrs: positionSrs,
                positioning: 'center-center',
                iconURL: selectedPoi && selectedPoi.position === poi.position ? markerSelected : markerDefault,
                onClick: () => markerClicked(poi)
            });


            myMarkers.push(marker);

            return true;
        });

        setAllMarkers(myMarkers);
    }

    React.useEffect(() => {
        
        if (!mapRef.current) {
            const mapElement = el.current;
            if (!mapElement) return;

            const map = mapRef.current = new window.lux.Map({
                target: mapElement,
                bgLayer: 'basemap_2015_global',
                positionSrs: positionSrs,
                zoom: zoom,
                position: center
            });

            map.showMarker({
                positioning: 'center-center',
                iconURL: markerBorne,
                //onClick: handleClick
            });

            addPois(currentPoi, map, type, false);

        } else {
            // You can update the Map here
            const map = mapRef.current

            map.setCenter(center, zoom, positionSrs);

            if (selectedPoi) addPois(currentPoi, map, type, false);

            if (map.getLayers()) {
                map.getLayers().forEach(layer => {
                    if (layer && layer.get("name") !== "basemap_2015_global") {
                        map.removeLayer(layer);
                    }
                });
            }
        }
        // eslint-disable-next-line
    }, [selectedPoi]);

    // Dispose The Map Reference
    React.useEffect(() => {
      const map = mapRef.current;

      return () => {
        if (map) {
         // map.dispose();
          mapRef.current = null;
        }
      };
    }, [mapRef]);

    return (
        <>
            {layout === 3 ? (
                <MapLandscape
                    className = { "geoportailmap" }
                    // The ref prop here is key it returns the actual dom element and not the virtual react dom elements
                    // Which is need by the player
                    ref = { el }
                />
            ) : layout === 4 ? (
                <MapPoster
                    className = { "geoportailmap" }
                    ref = { el }
                />
            ) : (
                <MapDefault
                    className = { "geoportailmap" }
                    ref = { el }
                />             
            )}
        </>
    );
  }


export default GeoPortailMap;