import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/fr";
import "dayjs/locale/lb";
import React from "react";
import {withLocalize} from "react-localize-redux";
import {useDispatch, useSelector} from 'react-redux';
import styled from "styled-components";
import {Loading} from "../components/Layout";
import SwipeIndicator from '../components/SwipeIndicator';
import {
    SET_SWIPEINDICATOR
} from '../redux/actions';

const WeatherWrapper = styled.div`
    background-color: ${(props) => props.theme.white};
    color: ${(props) => props.theme.white};
    height: 100%;
    font-family: "MuseoSans-100", serif;
    overflow-y: auto;
    display: flex;

    ${(props) =>
        props.theme.layout === 3
            ? `
        flex-direction: row;
    `
            : `
        flex-direction: column;
    `}
`;

const SwipeIndicatorWrapper = styled(SwipeIndicator)`
    position: absolute;
    top: ${props => props.theme.scale * ((props.theme.contentHeight / 2) - 97)}px;
    left: ${props => props.theme.scale * ((props.theme.contentWidth / 2) - 45)}px;
`;

const MapWrapper = styled.div`
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MapContainer = styled.div`
    position: relative;
    ${(props) =>
        props.theme.layout !== 3 &&
        `
        margin: ${props.theme.scale * 40}px 0;
    `}
`;

const Map = styled.img`
    width: ${(props) => props.theme.scale * 607}px;
    height: ${(props) => props.theme.scale * 878}px;
`;

const Upcoming = styled.div`
    background-color: ${(props) => props.theme.shade1};
    display: flex;
    height: ${(props) => (props.$mr  ? props.theme.scale * 450 + "px" : "auto")};

    ${(props) =>
        props.theme.layout === 3
            ? `
        flex-direction: column;
        flex: 0 0 320px;
    `
            : `
        flex-direction: row;
        flex: 0 0 auto;
    `}

    > div:nth-child(1) {
        background-color: ${(props) => props.theme.meteo1};
    }

    > div:nth-child(2) {
        background-color: ${(props) => props.theme.meteo2};
    }

    > div:nth-child(3) {
        background-color: ${(props) => props.theme.meteo3};
    }
`;

const Station = styled.div`
    position: absolute;
`;

const StationTemp = styled.div`
    text-align: center;
    font-family: "MuseoSans-900", serif;
    color: ${(props) => props.theme.white};
    font-size: ${(props) => props.theme.scale * 28}px;
`;

const StationIcon = styled.img`
    width: 66px;
`;

const Day = styled.div`
    flex: 1 1 33%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: ${(props) => props.theme.scale * 20}px 0;

    ${(props) =>
        props.theme.layout === 3
            ? `
        justify-content: center;
    `
            : `
        justify-content: flex-start;
    `}
`;

const Title = styled.div`
    font-family: "MuseoSans-900", serif;
    color: ${(props) => props.theme.white};
    font-size: ${(props) => props.theme.scale * 28}px;
    text-transform: capitalize;
`;

const Icon = styled.img`
    width: ${(props) => props.theme.scale * 100}px;
    margin: ${(props) => props.theme.scale * 20}px 0;
`;

const Temp = styled.div`
    font-family: "MuseoSans-900", serif;
    color: ${(props) => props.theme.white};
    font-size: ${(props) => props.theme.scale * 36}px;
`;

const TempMin = styled.div`
    font-family: "MuseoSans-900", serif;
    color: ${(props) => props.theme.white};
    font-size: ${(props) => props.theme.scale * 32}px;

    span {
        font-size: 0.6em;
    }
`;

const TempMax = styled.div`
    font-family: "MuseoSans-900", serif;
    color: ${(props) => props.theme.white};
    font-size: ${(props) => props.theme.scale * 32}px;
    span {
        font-size: 0.6em;
    }
`;

const Weather = ({ activeLanguage, type }) => {
    const defaultWeather = useSelector((state) => state.data.meteo);
    const rtlWeather = useSelector((state) => state.data.rtl_weather);
    const scale = useSelector((state) => state.config.scale);
    const swipeIndicator = useSelector(state => state.config.swipeIndicator);
    const weatherStation = useSelector((state) => state.config.weatherStation);
    const mobiliteReduite = useSelector(
        (state) => state.config.mobiliteReduite
    );
    const dispatch = useDispatch();
    
    if (!defaultWeather || !rtlWeather)
        return (
            <WeatherWrapper>
                <Loading />
            </WeatherWrapper>
        );

    let weather;
    type === "rtl" ? weather = rtlWeather : weather = defaultWeather;
        
    const hideSwiper = () => { 
        if (swipeIndicator === true) dispatch({ type: SET_SWIPEINDICATOR, state: false});
    }

    const citiesOnMap = [
        "Wiltz",
        "Diekirch",
        "Mersch",
        "Echternach",
        "Luxembourg",
        "Remich",
        "Mondercange",
    ];

    const cityLocation = {
        Wiltz: [90, 160],
        Diekirch: [290, 70],
        Mersch: [400, 240],
        Echternach: [440, 470],
        Luxembourg: [560, 280],
        Remich: [630, 380],
        Mondercange: [670, 160],
    };

    let cities = weather.filter((city) => citiesOnMap.includes(city.name));
    let city = weather.filter(
        (city) => city.name.toLowerCase() === weatherStation.toLowerCase()
    );

    if (city.length === 0) {
        city = weather.filter((city) => city.name === "Luxembourg");
    }
    city = city[0];

    let cLang = activeLanguage.code;
    if (activeLanguage.code === "lu") cLang = "lb";

    return (
        <WeatherWrapper onMouseDown={hideSwiper} onTouchStart={hideSwiper} onScroll={hideSwiper}>
            <MapWrapper>
                <MapContainer>
                    <Map
                        src={`${process.env.PUBLIC_URL}/images/map_luxembourg.svg`}
                        alt={"Map"}
                    />
                    {cities.map((station, i) => (
                        <Station
                            key={i}
                            style={{
                                top:
                                    scale * cityLocation[station.name][0] +
                                    "px",
                                left:
                                    scale * cityLocation[station.name][1] +
                                    "px",
                            }}
                        >
                            <StationIcon src={station.img} />
                            <StationTemp>{station.temp}°</StationTemp>
                        </Station>
                    ))}
                </MapContainer>
            </MapWrapper>
            <Upcoming $mr={mobiliteReduite}>
                <Day>
                    <Title>Actuellement</Title>
                    <Icon src={city.img} />
                    <Temp>{city.temp}°</Temp>
                </Day>
                <Day>
                    <Title>
                        {dayjs().add(1, "day").locale(cLang).format("dddd")}
                    </Title>
                    <Icon src={city.imgJ1} />
                    <TempMin>
                        {city.tempMaxJ1}° <span>Min</span>
                    </TempMin>
                    <TempMax>
                        {city.tempMinJ1}° <span>Max</span>
                    </TempMax>
                </Day>
                <Day>
                    <Title>
                        {dayjs().add(2, "day").locale(cLang).format("dddd")}
                    </Title>
                    <Icon src={city.imgJ2} />
                    <TempMin>
                        {city.tempMaxJ2}° <span>Min</span>
                    </TempMin>
                    <TempMax>
                        {city.tempMinJ2}° <span>Max</span>
                    </TempMax>
                </Day>
            </Upcoming>
            {(swipeIndicator) && <SwipeIndicatorWrapper direction="v" />}
        </WeatherWrapper>
    );
};

export default withLocalize(Weather);
