import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {SET_HOME} from "../redux/actions";

const Weather = styled.div`
  width: 100%;
  text-align: center;
`;

const NavItem = styled(Link)`
  display: block;
  text-decoration: none;

  ${(props) =>
    props.theme.layout === 4 &&
    `
        display: flex;
        flex-direction: row-reverse;
    `}
`;

const Temperature = styled.div`
  margin-top: ${(props) => props.theme.scale * 6}px;
  font-size: ${(props) => props.theme.scale * 28}px;
  font-family: "MuseoSans-900", serif;
  text-align: center;

  ${(props) =>
    props.theme.layout === 4 &&
    `
        color: ${props.theme.black};
        font-size: ${props.theme.scale * 34}px;
        margin-right: ${props.theme.scale * 20}px;
    `}

  ${(props) =>
    props.theme.layout !== 4 &&
    `
        color: ${props.theme.white};
        font-size: ${props.theme.scale * 28}px;
    `}

    ${(props) => props.theme.headerMeteo && `color: ${props.theme.headerMeteo};` }
`;

const IconToday = styled.img`
  height: ${(props) => props.theme.scale * 46}px;
`;

const WeatherWidget = ({ className }) => {
  const weather = useSelector((state) => state.data.meteo);
  const weatherStation = useSelector((state) => state.config.weatherStation);
  const layout = useSelector((state) => state.config.layout);
  const theme = useSelector((state) => state.config.theme);
  const dispatch = useDispatch();

  if (!weather) return null;

  const toggleNav = (e) => {
    dispatch({ type: SET_HOME, home: false });
    e.stopPropagation();
  };

  let station = weather.filter(
    (city) => city.name.toLowerCase() === weatherStation.toLowerCase()
  );
  if (station.length > 0) station = station[0];

  if (layout === 4 && station.img) {
    station.img = station.img.replace("ng_white", "ng_black");
  }

  if (theme.meteoIconColor) {
    station.img = station.img.replace("ng_white", "ng_" + theme.meteoIconColor);
  }

  if (!station.img && !station.temp) return null;

  return (
    <Weather className={className}>
      <NavItem to="/weather" onClick={toggleNav}>
        {station && <IconToday src={station.img} alt="Icon" />}
        {station && <Temperature>{station.temp}°</Temperature>}
      </NavItem>
    </Weather>
  );
};

export default WeatherWidget;
