import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {Container, LeftCol, List, RightCol} from '../components/Layout';
// import PoiMap from '../components/PoiMap';
import GeoPortailMap from '../components/GeoPortailMap';
import SubNavigation from '../components/SubNavigation';
import SwipeIndicator from '../components/SwipeIndicator';
import images from '../config/images';
import {
    SET_ACTIONBUTTON,
    SET_SELECTED_IMXPOI,
    SET_SWIPEINDICATOR
} from '../redux/actions';


const PoiItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    margin-bottom: 1px;
    cursor: pointer;
    user-select: none;
    background-repeat: no-repeat;
    background-position: ${props => props.theme.scale * 268}px ${props => props.theme.scale * 25}px;
    background-size: ${props => props.theme.scale * 25}px;
    padding: ${props => props.theme.scale * 15}px ${props => props.theme.scale * 50}px ${props => props.theme.scale * 0}px ${props => props.theme.scale * 10}px;
    box-sizing: border-box;
`;

const PoiDescription = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: ${props => props.theme.scale * 194}px;
    box-sizing: border-box;
    padding-left: ${props => props.theme.scale * 20}px;
    padding-top: ${props => props.theme.scale * 10}px;
    padding-bottom: ${props => props.theme.scale * 10}px;
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}

`;

const PoiTitle = styled.div`
    font-size: ${props => props.theme.scale * 18}px;
    font-family: 'MuseoSans-900';
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const PoiAdress = styled.div`
    font-size: ${props => props.theme.scale * 16}px;
`;

const Detail = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding: ${props => props.theme.scale * 30}px;
    box-sizing: border-box;
`;

const DetailTitle = styled.div`
    font-size: ${props => props.theme.scale * 24}px;
    font-family: 'MuseoSans-900';
    padding-right: ${props => props.theme.scale * 30}px;
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const DetailDescription = styled.div`
    margin: ${props => props.theme.scale * 15}px 0;
    font-family: 'MuseoSans-300';
    overflow-y: auto;
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;
const DetailDescriptionLandscape = styled.div`
    margin: ${props => props.theme.scale * 15}px 0;
    font-family: 'MuseoSans-300';
    overflow-y: auto;
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const DetailDescriptionPoster = styled.div`
    margin: ${props => props.theme.scale * 15}px 0;
    font-family: 'MuseoSans-300';
    overflow-y: auto;
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const DetailPoints = styled.div`
font-size: ${props => props.theme.scale * 16}px;
font-family: 'MuseoSans-300';
margin: ${props => props.theme.scale * 7}px 0;
`;

const DetailPointsLandscape = styled.div`
font-size: ${props => props.theme.scale * 16}px;
font-family: 'MuseoSans-300';
margin: ${props => props.theme.scale * 7}px 0;
`;

const DetailPointsPoster = styled.div`
font-size: ${props => props.theme.scale * 16}px;
font-family: 'MuseoSans-300';
margin: ${props => props.theme.scale * 15}px 0;
`;

const DetailText = styled.div`
    font-size: ${props => props.theme.scale * 16}px;
    max-height: ${props => props.theme.scale * 200}px;
    inline-size: ${props => props.theme.scale * 250}px;
    overflow-wrap: break-word;
    font-family: 'MuseoSans-300';
`;

const DetailTextLandscape = styled.div`
    font-size: ${props => props.theme.scale * 16}px;
    max-height: ${props => props.theme.scale * 100}px;
    inline-size: ${props => props.theme.scale * 250}px;
    overflow-wrap: break-word;
    font-family: 'MuseoSans-300';
`;

const DetailTextPoster = styled.div`
    font-size: ${props => props.theme.scale * 16}px;
    max-height: ${props => props.theme.scale * 3500}px;
    inline-size: ${props => props.theme.scale * 250}px;
    overflow-wrap: break-word;
    font-family: 'MuseoSans-300';
`;

const DetailMapWrapper= styled.div``;

const DetailQRCodes = styled.div`
    width: 100%;
    height: ${props => props.theme.scale * 150}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    bottom: 300px;
    background-color: #fff;

    img {
        width: ${props => props.theme.scale * 90}px;
    }

    h4 {
        font-size: ${props => props.theme.scale * 12}px;
        text-transform: uppercase;
        margin: 0;
    }
`;

const QRCode = styled.div`
    text-align: center;
    flex: 0 1 auto;
    width: ${props => props.theme.scale * 100}px;
    margin: ${props => props.theme.scale * 20}px auto;
    display: block;
    position: absolute;
    bottom: ${props => props.theme.scale * 300}px;
    left: ${props => props.theme.scale * 20}px;
    background-color: white;
    padding-top: 5px;
`;

const GPXQRCode = styled.div` 
    background-color: white;   
    text-align: center;
    flex: 0 1 auto;
    width: ${props => props.theme.scale * 100}px;
    margin: ${props => props.theme.scale * 20}px auto;
    display: block;
    position: absolute;
    bottom: ${props => props.theme.scale * 300}px;
    left: ${props => props.theme.scale * 180}px;
    background-color: white;
    padding-top: 5px;
`;

const DetailImageWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${props => props.theme.scale * 300}px;
    overflow: hidden;
`;

const DetailImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;


const Close = styled.div`
    cursor: Pointer;
    user-select: none;
    width: ${props => props.theme.scale * 28}px;
    height: ${props => props.theme.scale * 28}px;
    position: absolute;
    top: ${props => props.theme.scale * 28}px;
    right: ${props => props.theme.scale * 20}px;
`;

const LeftColWrapper = styled(LeftCol)`
    max-height: calc(100% - ${props => props.theme.scale * 60}px);
`;

const SwipeIndicatorWrapper = styled(SwipeIndicator)`
    position: absolute;
    top: ${props => props.theme.scale * ((props.theme.contentHeight / 2) - 97)}px;
    left: ${props => props.theme.scale * 130}px;
`;

const Explore = () => {
    const poi = useSelector(state => state.data.imxpoi);
    const selectedPoi = useSelector(state => state.data.selectedimxpoi);
    const subNavItem = useSelector(state => state.config.subNavItem);
    const mobiliteReduite = useSelector(state => state.config.mobiliteReduite);
    const swipeIndicator = useSelector(state => state.config.swipeIndicator);
    const layout = useSelector((state) => state.config.layout);
    const theme = useSelector(state => state.config.theme);
    const dispatch = useDispatch();

    const itemClicked = (explore) => { 
        dispatch({ type: SET_SELECTED_IMXPOI, imxpoi: explore});
        dispatch({ type: SET_ACTIONBUTTON, actionButton: "mail", action: explore.website});
    }

    const closeClicked = () => { 
        dispatch({ type: SET_SELECTED_IMXPOI, imxpoi: undefined});
        dispatch({ type: SET_ACTIONBUTTON, actionButton: undefined});
    }

    let selectedNav = 'hiking';
    if (subNavItem) selectedNav = subNavItem.id;

    if (!poi || !poi[selectedNav]) return(null);

    const hideSwiper = () => { 
        if (swipeIndicator === true) dispatch({ type: SET_SWIPEINDICATOR, state: false});
    }

    let infoIcon;
    if (!theme.iconSet || theme.useDefaultListInfoIcons) {
        infoIcon = images.icons.info;
    } else {
        infoIcon = `images/themes/${theme.iconSet}/ic_info_black.svg`;
    }

    return(
        <Container>
            <LeftColWrapper onMouseDown={hideSwiper} onTouchStart={hideSwiper} onScroll={hideSwiper}>
                {!mobiliteReduite && <SubNavigation />}
                {selectedPoi ? (
                    <Detail>
                        <Close onClick={() => closeClicked()}><img src={images.icons.close} alt="Close" /></Close>
                        <DetailTitle>{selectedPoi.name}</DetailTitle>
                        {layout === 3 ? (
                            <DetailDescriptionLandscape>
                                {selectedPoi.startingPoint && <DetailPointsLandscape dangerouslySetInnerHTML={{__html: selectedPoi.startingPoint}}></DetailPointsLandscape>}
                                {selectedPoi.destination && <DetailPointsLandscape dangerouslySetInnerHTML={{__html: selectedPoi.destination}}></DetailPointsLandscape>}
                                {selectedPoi.difficulties && <DetailPointsLandscape dangerouslySetInnerHTML={{__html: selectedPoi.difficulties}}></DetailPointsLandscape>}
                                {selectedPoi.abstract && <DetailTextLandscape dangerouslySetInnerHTML={{__html: selectedPoi.description.replace(/<a(.*)href="(.*?)">/g,"").replace(/<\/a>/g,"")}}></DetailTextLandscape>}
                            </DetailDescriptionLandscape>
                        ): layout === 4 ?(
                            <DetailDescriptionPoster>
                                {selectedPoi.startingPoint && <DetailPointsPoster dangerouslySetInnerHTML={{__html: selectedPoi.startingPoint}}></DetailPointsPoster>}
                                {selectedPoi.destination && <DetailPointsPoster dangerouslySetInnerHTML={{__html: selectedPoi.destination}}></DetailPointsPoster>}
                                {selectedPoi.difficulties && <DetailPointsPoster dangerouslySetInnerHTML={{__html: selectedPoi.difficulties}}></DetailPointsPoster>}
                                {selectedPoi.abstract && <DetailTextPoster dangerouslySetInnerHTML={{__html: selectedPoi.description.replace(/<a(.*)href="(.*?)">/g,"").replace(/<\/a>/g,"")}}></DetailTextPoster>}
                            </DetailDescriptionPoster>
                        ):(
                            <DetailDescription>
                                {selectedPoi.startingPoint && <DetailPoints dangerouslySetInnerHTML={{__html: selectedPoi.startingPoint}}></DetailPoints>}
                                {selectedPoi.destination && <DetailPoints dangerouslySetInnerHTML={{__html: selectedPoi.destination}}></DetailPoints>}
                                {selectedPoi.difficulties && <DetailPoints dangerouslySetInnerHTML={{__html: selectedPoi.difficulties}}></DetailPoints>}
                                {selectedPoi.abstract && <DetailText dangerouslySetInnerHTML={{__html: selectedPoi.description.replace(/<a(.*)href="(.*?)">/g,"").replace(/<\/a>/g,"")}}></DetailText>}
                            </DetailDescription>
                        )}
                        <DetailMapWrapper>
                            <DetailQRCodes>
                                {selectedPoi.qrcode && <QRCode><h4>{selectedPoi.trans_itinerary}</h4><img src={selectedPoi.qrcode} alt="QR Code" /></QRCode>}
                                {selectedPoi.qrcode_gpx && <GPXQRCode><h4>{selectedPoi.trans_download_gpx}</h4><img src={selectedPoi.qrcode_gpx} alt="QR Code" /></GPXQRCode>}
                            </DetailQRCodes>
                        </DetailMapWrapper>
                        {selectedPoi.photourl ? <DetailImageWrapper><DetailImage src={selectedPoi.photourl} alt={selectedPoi.name} /></DetailImageWrapper> : <DetailImageWrapper><DetailImage src={images.placeholder[selectedNav]} alt={selectedPoi.name} /></DetailImageWrapper>}
                    </Detail>
                ) : (
                    <List>
                        {poi[selectedNav].map((item, index) => (
                            <PoiItem key={index} onClick={() => itemClicked(item)} style={{ backgroundImage: `url("${infoIcon}")` }} data-on="click" data-event-category="Explore" data-event-action="open" data-event-label={item.name}>
                                
                                <PoiDescription>
                                    <PoiTitle>{item.name}</PoiTitle>
                                    <PoiAdress dangerouslySetInnerHTML={{__html: item.address}}></PoiAdress>
                                </PoiDescription>
                            </PoiItem>
                        ))}
                    </List>
                )}
                {mobiliteReduite && <SubNavigation />}
                {swipeIndicator && <SwipeIndicatorWrapper direction="v" />}  
            </LeftColWrapper>
            <RightCol>
                <GeoPortailMap type={selectedNav} poitype={"imxpoi"}></GeoPortailMap>
            </RightCol>
        </Container>
    );
}

export default Explore;
