import {GoogleMap, LoadScriptNext, Marker} from "@react-google-maps/api";
import {useSelector} from "react-redux";
import images from "../config/images";
import {centerControl} from "../config/mapHelper";
import mapStyles from "../config/mapStyles";

const getDefaultBorneMarker = (color) => {
    var encoded = window.btoa(
        '<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path d="M15,30c-3.84,0-7.68-1.46-10.61-4.39C1.56,22.78,0,19.01,0,15S1.56,7.23,4.4,4.39C7.23,1.56,11,0,15,0s7.77,1.56,10.61,4.39c5.85,5.85,5.85,15.37,0,21.22-2.92,2.92-6.77,4.39-10.61,4.39" style="fill:' +
        color +
        '"/><path d="M15.3,9.62h-.59c-.46,0-.89,.26-1.09,.68l-.64,1.29c-.18,.36-.52,.61-.92,.67l-1.43,.21c-.46,.07-.84,.39-.99,.83l-.18,.56c-.14,.44-.03,.93,.31,1.25l1.03,1.01c.29,.28,.42,.68,.35,1.08l-.24,1.42c-.08,.46,.11,.92,.49,1.19l.48,.35c.21,.15,.46,.23,.72,.23,.19,0,.39-.05,.57-.14l1.28-.67c.18-.09,.37-.14,.57-.14s.39,.05,.57,.14l1.28,.67c.18,.09,.37,.14,.57,.14,.25,0,.51-.08,.72-.23l.48-.35c.38-.27,.56-.73,.49-1.19l-.24-1.42c-.07-.4,.06-.8,.35-1.08l1.03-1.01c.33-.32,.45-.81,.31-1.25l-.18-.56c-.14-.44-.53-.76-.98-.83l-1.43-.21c-.4-.06-.74-.31-.92-.67l-.64-1.29c-.21-.42-.63-.68-1.1-.68" style="fill:#fff;"/></svg>'
    );

    return "data:image/svg+xml;base64," + encoded;
};

const GEventMap = () => {
    const selectedEvent = useSelector((state) => state.data.selectedEvent);
    const poiConfig = useSelector((state) => state.data.poiConfig);
    const theme = useSelector((state) => state.config.theme);
    const layout = useSelector((state) => state.config.layout);
    let zoom = 15;

    if (!poiConfig) return null;

    const style = {
        width: "100%",
        height: "100%",
    };

    let center;

    if (selectedEvent) {
        center = selectedEvent.venue.position;
        zoom = 18;
    } else {
        center = {
            lat: poiConfig.latitude,
            lng: poiConfig.longitude,
        };
    }

    let markerBorne, markerBorneSize, markerEvent, markerEventSize;

    let borneMarkerColor = "#f29137";
    if (theme.borneMarker) borneMarkerColor = theme.borneMarker;

    if (!theme.iconSet || theme.useDefaultMapIcons) {
        markerBorne = getDefaultBorneMarker(borneMarkerColor);
        markerBorneSize = {width: 25, height: 25};
        markerEvent = images.icons.marker;
        markerEventSize = {width: 20, height: 25};
    } else {
        markerBorne = `images/themes/${theme.iconSet}/icpin_default.svg`;
        markerBorneSize = {width: 38, height: 42};
        markerEvent = `images/themes/${theme.iconSet}/icpin_events.svg`;
        markerEventSize = {width: 38, height: 42};
    }

    const homeMarker = (
        <Marker
            onLoad={(marker) => {
                //console.log('marker: ', marker)
            }}
            position={{
                lat: poiConfig.latitude,
                lng: poiConfig.longitude,
            }}
            icon={{
                url: markerBorne,
                scaledSize: markerBorneSize,
            }}
        />
    );

    const mapOnLoad = (map, position) => {
        const centerControlDiv = document.createElement("div");
        let useTheme;
        if (theme.iconSet && !theme.useDefaultMapControlIcons) {
            useTheme = theme.iconSet;
        }
        centerControl(centerControlDiv, map, center, useTheme, theme, layout);

        map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
            centerControlDiv
        );
    };

    return (
        <LoadScriptNext
            id="script-loader"
            googleMapsApiKey="AIzaSyC0KS2RkGwP6i9-vgSeiEwCmZ-p2Bq2rSQ"
        >
            <GoogleMap
                id="map"
                center={center}
                zoom={zoom}
                mapContainerStyle={style}
                onLoad={mapOnLoad}
                options={{
                    disableDefaultUI: true,
                    styles: mapStyles,
                }}
            >
                {homeMarker}

                <Marker
                    onLoad={(marker) => {
                        //console.log('marker: ', marker)
                    }}
                    position={selectedEvent.venue.position}
                    icon={{
                        url: markerEvent,
                        scaledSize: markerEventSize,
                    }}
                />
            </GoogleMap>
        </LoadScriptNext>
    );
};

export default GEventMap;
