import React from 'react';
import {Translate} from 'react-localize-redux';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import PoiMap from '../components/PoiMap';
// import GeoPortailMap from '../components/GeoPortailMap';
import {Container, LeftCol, List, RightCol} from '../components/Layout';
import SubNavigation from '../components/SubNavigation';
import SwipeIndicator from '../components/SwipeIndicator';
import images from '../config/images';
import {
    SET_ACTIONBUTTON, SET_SELECTED_POI,

    SET_SWIPEINDICATOR
} from '../redux/actions';



const PoiItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    margin-bottom: 4px;
    cursor: pointer;
    user-select: none;
    background-repeat: no-repeat;
    background-position: ${props => props.theme.scale * 268}px ${props => props.theme.scale * 25}px;
    background-size: ${props => props.theme.scale * 25}px;
    padding: ${props => props.theme.scale * 15}px ${props => props.theme.scale * 50}px ${props => props.theme.scale * 0}px ${props => props.theme.scale * 10}px;
    box-sizing: border-box;
`;

const PoiDescription = styled.div`
    width: ${props => props.theme.scale * 194}px;
    box-sizing: border-box;
    padding-left: ${props => props.theme.scale * 20}px;
    padding-top: ${props => props.theme.scale * 15}px;
    padding-bottom: ${props => props.theme.scale * 15}px;
`;

const PoiTitle = styled.div`
    font-size: ${props => props.theme.scale * 18}px;
    font-family: 'MuseoSans-900';
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const PoiAdress = styled.div`
    font-size: ${props => props.theme.scale * 16}px;
    font-family: 'MuseoSans-500';
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const PoiInfo = styled.div`
    font-size: ${props => props.theme.scale * 16}px;
    font-family: 'MuseoSans-500';
    padding-top: ${props => props.theme.scale * 10}px;
`;

const Detail = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding: ${props => props.theme.scale * 30}px;
    box-sizing: border-box;
`;

const DetailTitle = styled.div`
    font-size: ${props => props.theme.scale * 24}px;
    font-family: 'MuseoSans-900';
    padding-right: ${props => props.theme.scale * 30}px;
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const DetailDescription = styled.div`
    margin: ${props => props.theme.scale * 20}px 0;
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const DetailAdress = styled.div`
    font-size: ${props => props.theme.scale * 14}px;
`;

const DetailPhone = styled.div`
    font-size: ${props => props.theme.scale * 14}px;
`;

const DetailWebsite = styled.div`
    font-size: ${props => props.theme.scale * 14}px;
    font-family: 'MuseoSans-900';
`;

const DetailOpeningHours = styled.div`
    font-size: ${props => props.theme.scale * 14}px;
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const DetailImageWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${props => props.theme.scale * 300}px;
    overflow: hidden;
`;

const DetailImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

const DetailQR = styled.img`
    width: ${props => props.theme.scale * 100}px;
    margin: ${props => props.theme.scale * 20}px auto;
    display: block;
    position: absolute;
    bottom: ${props => props.theme.scale * 340}px;
    left: ${props => props.theme.scale * 20}px;
`;

const Close = styled.div`
    cursor: Pointer;
    user-select: none;
    width: ${props => props.theme.scale * 30}px;
    height: ${props => props.theme.scale * 30}px;
    position: absolute;
    top: ${props => props.theme.scale * 28}px;
    right: ${props => props.theme.scale * 20}px;
`;

const LeftColWrapper = styled(LeftCol)`
    ${props => props.showsubnav ? props.$mr  ?`
            display: grid;
            grid-template-rows: 1fr min-content;
        ` : `
            display: grid;
            grid-template-rows: min-content 1fr;
        ` 
    : `
        max-height: calc(100% - ${props.theme.scale * 60}px);
    `};
`;

const SDG = styled.div`
    width: 100%;
    max-height: 100%;
`;

const SDGContent= styled.div`
    width: 100%;
    max-height: calc(100% - ${props => props.theme.scale * 60}px);
    overflow-y: scroll;
`;

const SDGTitle = styled.div`
    font-size: ${props => props.theme.scale * 22}px;
    font-family: 'MuseoSans-500';
    color: ${props => props.theme.highlight};
    padding: ${props => props.theme.scale * 40}px ${props => props.theme.scale * 40}px 0 ${props => props.theme.scale * 40}px;
    text-transform: uppercase;
`;

const SDGSubtitle = styled.div`
    font-size: ${props => props.theme.scale * 22}px;
    font-family: 'MuseoSans-500';
    color: ${props => props.theme.highlight};
    padding: 0 ${props => props.theme.scale * 40}px ${props => props.theme.scale * 40}px ${props => props.theme.scale * 40}px;
`;

const SDGItems = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const SDGItem = styled.div`
    width: 33%;
    box-sizing: border-box;
    padding: ${props => props.theme.scale * 15}px ${props => props.theme.scale * 40}px;
`;

const SDGItemBig = styled.div`
    width: 66%;
    box-sizing: border-box;
    padding: ${props => props.theme.scale * 10}px ${props => props.theme.scale * 40}px;
`;

const SwipeIndicatorWrapper = styled(SwipeIndicator)`
    position: absolute;
    top: ${props => props.theme.scale * ((props.theme.contentHeight / 2) - 97)}px;
    left: ${props => props.theme.scale * 130}px;
`;


const Health = () => {
    const poi = useSelector(state => state.data.poi);
    const selectedPoi = useSelector(state => state.data.selectedPoi);
    const subNavItem = useSelector(state => state.config.subNavItem);
    const mobiliteReduite = useSelector(state => state.config.mobiliteReduite);
    const showSubNav = useSelector(state => state.config.theme.showSubNav);
    const swipeIndicator = useSelector(state => state.config.swipeIndicator);
    const theme = useSelector(state => state.config.theme);
    const dispatch = useDispatch()

    if (!poi) return(null);
    if (!poi.hospital) return(null);
    if (!poi.pharmacy) return(null);
    if (!poi.doctor) return(null);

    const itemClicked = (poi) => { 
        dispatch({ type: SET_SELECTED_POI, poi: poi});
        
        if (poi.google_url) {
            dispatch({ type: SET_ACTIONBUTTON, actionButton: "mail", action: poi.google_url});
        } else {
            dispatch({ type: SET_ACTIONBUTTON, actionButton: undefined});
        } 
    }

    const closeClicked = () => { 
        dispatch({ type: SET_SELECTED_POI, poi: undefined});
        dispatch({ type: SET_ACTIONBUTTON, actionButton: undefined});
    }

    let selectedNav = 'hospital';
    if (subNavItem) selectedNav = subNavItem.id;

    const hideSwiper = () => { 
        if (swipeIndicator === true) dispatch({ type: SET_SWIPEINDICATOR, state: false});
    }

    let infoIcon;
    if (!theme.iconSet || theme.useDefaultListInfoIcons) {
        infoIcon = images.icons.info;
    } else {
        infoIcon = `images/themes/${theme.iconSet}/ic_info_black.svg`;
    }

    let output;
    if (selectedNav === 'sdg') {
        output = <SDG>
            {!mobiliteReduite && <SubNavigation />}
            <SDGContent>
            {poi[selectedNav].pharmacies && <React.Fragment><SDGTitle><Translate id={"sdg.pharmacies_title"} /></SDGTitle>
            <SDGSubtitle><Translate id={"sdg.pharmacies_subtitle"} /></SDGSubtitle>
            <SDGItems>
                {poi[selectedNav].pharmacies.map((item, index) => (
                    <SDGItem key={index} onClick={() => itemClicked(item)} data-on="click" data-event-category="Sante" data-event-action="open" data-event-label={item.name}>
                        <PoiTitle>Pharmacie de {item.nom}</PoiTitle>
                        <PoiAdress>{item.addressnum} {item.address}</PoiAdress>
                        <PoiAdress>L-{item.code_postale} {item.ville}</PoiAdress>
                        <PoiAdress>Tel: {item.tel}</PoiAdress>
                    </SDGItem>
                ))}
            </SDGItems></React.Fragment>} 
            
            {poi[selectedNav].cliniques && <React.Fragment>
            <SDGTitle><Translate id={"sdg.hospital_title"} /></SDGTitle>
            <SDGSubtitle><Translate id={"sdg.region_center"} /></SDGSubtitle>
            <SDGItems>
                {poi[selectedNav].cliniques.centre.map((item, index) => (
                    <React.Fragment key={index}>
                        {index === 0 ? (
                            <SDGItemBig onClick={() => itemClicked(item)} data-on="click" data-event-category="Sante" data-event-action="open" data-event-label={item.name}>
                                <PoiTitle>{item.name}</PoiTitle>
                                <PoiAdress>{item.city}</PoiAdress>
                                <PoiInfo dangerouslySetInnerHTML={{__html: item.info.replace(/<a(.*)href="(.*?)">/g,"").replace(/<\/a>/g,"")}}></PoiInfo>
                            </SDGItemBig>
                        ) : (
                            <SDGItem onClick={() => itemClicked(item)} data-on="click" data-event-category="Sante" data-event-action="open" data-event-label={item.name}>
                                <PoiTitle>{item.name}</PoiTitle>
                                <PoiAdress>{item.city}</PoiAdress>
                                <PoiInfo dangerouslySetInnerHTML={{__html: item.info.replace(/<a(.*)href="(.*?)">/g,"").replace(/<\/a>/g,"")}}></PoiInfo>
                            </SDGItem>
                        )}
                    </React.Fragment>
                    
                ))}
            </SDGItems>

            <SDGTitle><Translate id={"sdg.hospital_title"} /></SDGTitle>
            <SDGSubtitle><Translate id={"sdg.region_nord"} /></SDGSubtitle>
            <SDGItems>
                {poi[selectedNav].cliniques.nord.map((item, index) => (
                    <React.Fragment key={index}>
                        {index === 0 ? (
                            <SDGItemBig onClick={() => itemClicked(item)} data-on="click" data-event-category="Sante" data-event-action="open" data-event-label={item.name}>
                                <PoiTitle>{item.name}</PoiTitle>
                                <PoiAdress>{item.city}</PoiAdress>
                                <PoiInfo dangerouslySetInnerHTML={{__html: item.info.replace(/<a(.*)href="(.*?)">/g,"").replace(/<\/a>/g,"")}}></PoiInfo>
                            </SDGItemBig>
                        ) : (
                            <SDGItem onClick={() => itemClicked(item)} data-on="click" data-event-category="Sante" data-event-action="open" data-event-label={item.name}>
                                <PoiTitle>{item.name}</PoiTitle>
                                <PoiAdress>{item.city}</PoiAdress>
                                <PoiInfo dangerouslySetInnerHTML={{__html: item.info.replace(/<a(.*)href="(.*?)">/g,"").replace(/<\/a>/g,"")}}></PoiInfo>
                            </SDGItem>
                        )}
                    </React.Fragment>
                    
                ))}
            </SDGItems>

            <SDGTitle><Translate id={"sdg.hospital_title"} /></SDGTitle>
            <SDGSubtitle><Translate id={"sdg.region_south"} /></SDGSubtitle>
            <SDGItems>
                {poi[selectedNav].cliniques.sud.map((item, index) => (
                    <React.Fragment key={index}>
                        {index === 0 ? (
                            <SDGItemBig onClick={() => itemClicked(item)} data-on="click" data-event-category="Sante" data-event-action="open" data-event-label={item.name}>
                                <PoiTitle>{item.name}</PoiTitle>
                                <PoiAdress>{item.city}</PoiAdress>
                                <PoiInfo dangerouslySetInnerHTML={{__html: item.info.replace(/<a(.*)href="(.*?)">/g,"").replace(/<\/a>/g,"")}}></PoiInfo>
                            </SDGItemBig>
                        ) : (
                            <SDGItem onClick={() => itemClicked(item)} data-on="click" data-event-category="Sante" data-event-action="open" data-event-label={item.name}>
                                <PoiTitle>{item.name}</PoiTitle>
                                <PoiAdress>{item.city}</PoiAdress>
                                <PoiInfo dangerouslySetInnerHTML={{__html: item.info.replace(/<a(.*)href="(.*?)">/g,"").replace(/<\/a>/g,"")}}></PoiInfo>
                            </SDGItem>
                        )}
                    </React.Fragment>
                    
                ))}
            </SDGItems>
            </React.Fragment>}
            </SDGContent>
            {mobiliteReduite && <SubNavigation />}
        </SDG>;
    } else {
        output = <React.Fragment>
            <LeftColWrapper onMouseDown={hideSwiper} onTouchStart={hideSwiper} onScroll={hideSwiper} showsubnav={showSubNav} $mr={mobiliteReduite}>
            {!mobiliteReduite && <SubNavigation />}
            {selectedPoi ? (
                <Detail>
                    <Close onClick={() => closeClicked()}><img src={images.icons.close} alt="Close" /></Close>
                    <DetailTitle>{selectedPoi.name}</DetailTitle>
                    <DetailDescription>
                        {selectedPoi.address && <DetailAdress>{selectedPoi.address}</DetailAdress>}
                        {selectedPoi.phone && <DetailPhone>{selectedPoi.phone}</DetailPhone>}
                        {selectedPoi.website && <DetailWebsite>{selectedPoi.website}</DetailWebsite>}
                    </DetailDescription>
                    {selectedPoi.opening_hours && 
                        <DetailOpeningHours>
                            <b><Translate id={"pois.opening_hours"} /></b><br/>
                            {selectedPoi.opening_hours.map((hours, index) => (
                                <div key={index}>
                                    {hours}
                                </div>
                            ))}
                        </DetailOpeningHours>
                    }
                    {selectedPoi.qrcodeurl && <DetailQR src={selectedPoi.qrcodeurl} alt={selectedPoi.name} />}
                    {selectedPoi.photourl ? <DetailImageWrapper><DetailImage src={selectedPoi.photourl} alt={selectedPoi.name} /></DetailImageWrapper> : <DetailImageWrapper><DetailImage src={images.placeholder.pharmacie} alt={selectedPoi.name} /></DetailImageWrapper>}
                </Detail>
            ) : (
                <List $mr={mobiliteReduite}>
                    {poi[selectedNav].map((item, index) => (
                        <PoiItem key={index} onClick={() => itemClicked(item)} style={{ backgroundImage: `url("${infoIcon}")` }} data-on="click" data-event-category="Sante" data-event-action="open" data-event-label={item.name}>
                            <PoiDescription>
                                <PoiTitle>{item.name}</PoiTitle>
                                <PoiAdress>{item.address}</PoiAdress>
                            </PoiDescription>
                        </PoiItem>
                    ))}
                </List>
            )}
            {mobiliteReduite && <SubNavigation />}
            {swipeIndicator && <SwipeIndicatorWrapper direction="v" />}  
        </LeftColWrapper>
        <RightCol>
            <PoiMap type={selectedNav}></PoiMap>
        </RightCol>
        </React.Fragment>;
    }

    return(
        <Container>
           {output}
        </Container>
    );
}

export default Health;