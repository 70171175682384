const themes = {
    global: {
        black: '#000000',
        white: '#ffffff',
        darkgray: '#4c505f',
        lightgray: '#e9ebf0',
        rtlred: '#e00f22',
        open: '#056a0f',
        closed: '#e00f22'
    },
    /*
    petange: {
        shade1: '#bf301a',
        shade2: '#ae2c18',
        shade3: '#9d2816',
        shade4: '#8b2313',
        shade5: '#7a1f11',
        highlight: '#004796',
        logo: 'logo_petange_white.svg'
    },
    mondorf: {
        shade1: '#12367a',
        shade2: '#11326f',
        shade3: '#0f2d64',
        shade4: '#0e2859',
        shade5: '#0c234e',
        highlight: '#ffd600',
        logo: 'logo_mondorf_white.svg'
    },
    hesperange: {
        shade1: '#2e2c70',
        shade2: '#2a2866',
        shade3: '#26255c',
        shade4: '#222152',
        shade5: '#1e1d48',
        highlight: '#ed1c24',
        logo: 'logo_hesperange_white.svg'
    }
    */
};

export default themes;