// Remove when api calls are final
export const getApiUrl = () => {
    const myStorage = window.localStorage;
    const local = myStorage.getItem('local');

    if (local === '1') {
        const original = process.env.REACT_APP_API_URL;
        let localUrl = 'http://localhost:8000/proxy/';

        if (original.substr(0, 8) === 'https://') {
            localUrl += original.substr(8);
        } else if (original.substr(0, 7) === 'http://') {
            localUrl += original.substr(7);
        } else {
            localUrl += original;
        }

        return localUrl;
    }

    return process.env.REACT_APP_API_URL;
}