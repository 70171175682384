export const centerControl = (
    controlDiv,
    map,
    position,
    themeName,
    theme,
    mobiliteReduite,
    layout
) => {
    // Set CSS for the control border.
    const controlUI = document.createElement("div");

    controlUI.style.backgroundColor = "#fff";
    controlUI.style.cursor = "pointer";
    controlUI.style.textAlign = "center";
    controlUI.style.position = "absolute";
    if (mobiliteReduite) {
        controlUI.style.top = "1160px";
        controlUI.style.right = "20px";
    } else {
        switch (layout) {
            case 3:
                controlUI.style.top = "740px";
                controlUI.style.right = "20px";
                break;
            default:
                controlUI.style.top = "15px";
                controlUI.style.right = "20px";
        }
    }

    controlUI.title = "Click to recenter the map";
    if (theme.mapControlRadius)
        controlUI.style.borderRadius = theme.mapControlRadius;
    else controlUI.style.borderRadius = "50px";
    controlDiv.appendChild(controlUI);

    let themeSet = "default";
    if (themeName) themeSet = themeName;

    // Add Center Icon
    const centerWrapper = document.createElement("div");
    centerWrapper.style.padding = "14px";
    controlUI.appendChild(centerWrapper);

    const centerIMG = document.createElement("img");

    centerIMG.src = `images/themes/${themeSet}/map_center.svg`;
    centerIMG.width = "30";
    centerIMG.height = "30";
    centerWrapper.appendChild(centerIMG);

    // Add Zoom In Icon
    const zoomInWrapper = document.createElement("div");
    zoomInWrapper.style.padding = "14px";
    controlUI.appendChild(zoomInWrapper);

    const zoomInIMG = document.createElement("img");

    zoomInIMG.src = `images/themes/${themeSet}/map_zoom_in.svg`;
    zoomInIMG.width = "30";
    zoomInIMG.height = "30";
    zoomInWrapper.appendChild(zoomInIMG);

    // Add Zoom In Icon
    const zoomOutWrapper = document.createElement("div");
    zoomOutWrapper.style.padding = "14px";
    controlUI.appendChild(zoomOutWrapper);

    const zoomOutIMG = document.createElement("img");

    zoomOutIMG.src = `images/themes/${themeSet}/map_zoom_out.svg`;
    zoomOutIMG.width = "30";
    zoomOutIMG.height = "30";
    zoomOutWrapper.appendChild(zoomOutIMG);

    // Setup the click event listeners
    centerWrapper.addEventListener("click", () => {
        map.setCenter(position);
    });

    zoomInWrapper.addEventListener("click", () => {
        let currentZoomLevel = map.getZoom();
        if (currentZoomLevel !== 17) {
            map.setZoom(currentZoomLevel + 1);
        }
    });

    zoomOutWrapper.addEventListener("click", () => {
        let currentZoomLevel = map.getZoom();
        if (currentZoomLevel !== 0) {
            map.setZoom(currentZoomLevel - 1);
        }
    });
};
