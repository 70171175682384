import { combineReducers } from 'redux'
import { localizeReducer } from "react-localize-redux";
import data from './data'
import config from './config'

const reducer = combineReducers({
  localize: localizeReducer,
  data,
  config
})

export default reducer
