import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Translate } from 'react-localize-redux';
import dayjs from 'dayjs';

import { Container, LeftCol, RightCol, List } from '../components/Layout';
import SubNavigation from '../components/SubNavigation';

const TraficMap = styled.img`
    width: ${props => props.theme.scale * 590}px;
`;

const ErrorMessage = styled.div`
    font-size: ${props => props.theme.scale * 24}px;
    font-family: 'MuseoSans-700';
    padding: ${props => props.theme.scale * 30}px;
`;

const Item = styled.div`
    width: 100%;
    margin: ${props => props.theme.scale * 10}px 0;
    flex: 0 0 auto;
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;
    padding: ${props => props.theme.scale * 0}px ${props => props.theme.scale * 20}px;
`;

const ItemTime = styled.div`
    font-size: ${props => props.theme.scale * 18}px;
    font-family: 'MuseoSans-500';
`;

const ItemTitle = styled.div`
    font-size: ${props => props.theme.scale * 18}px;
    font-family: 'MuseoSans-900';
`;

const ItemTimeOfDay = styled.div`
    font-size: ${props => props.theme.scale * 24}px;
    font-family: 'MuseoSans-500';
    text-transform: capitalize;
`;

const ItemDirection = styled.div`
    font-size: ${props => props.theme.scale * 16}px;
    font-family: 'MuseoSans-500';
    padding-top: ${props => props.theme.scale * 10}px;
    padding-bottom: ${props => props.theme.scale * 10}px;
`;

const ItemDescription = styled.div`
    box-sizing: border-box;
    padding-top: ${props => props.theme.scale * 10}px;
    padding-bottom: ${props => props.theme.scale * 10}px;
`;

const ItemCity = styled.div`
    font-size: ${props => props.theme.scale * 16}px;
    font-family: 'MuseoSans-900';
    padding-top: ${props => props.theme.scale * 10}px;
`;

const ItemStreet = styled.div`
    box-sizing: border-box;
    padding-bottom: ${props => props.theme.scale * 10}px;
`;

const ItemQuartier = styled.div`
    font-size: ${props => props.theme.scale * 16}px;
    font-family: 'MuseoSans-500';
`;

const ItemStatus = styled.span`
    text-transform: capitalize;
`;

const ItemPlaces = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: ${props => props.theme.scale * 10}px;
`;

const ItemCapacity = styled.div`
    width: 50%;
    font-size: ${props => props.theme.scale * 16}px;
    font-family: 'MuseoSans-500';
`;

const Copyright = styled.div`
    margin: ${props => props.theme.scale * 20}px 0;
    padding: ${props => props.theme.scale * 0}px ${props => props.theme.scale * 20}px;
`;

const LeftColWrapper = styled(LeftCol)`
    max-height: calc(100% - ${props => props.theme.scale * 60}px);
`;

const TraficInfo = () => {
    const traficInfo = useSelector(state => state.data.trafic);
    const radar = useSelector(state => state.data.radar);
    const parking = useSelector(state => state.data.parking);
    const subNavItem = useSelector(state => state.config.subNavItem);
    const mobiliteReduite = useSelector(state => state.config.mobiliteReduite);

    if (!traficInfo) return(null);
    if (!radar) return(null);

    let selectedNav = 'trafic_info_acl';
    if (subNavItem) selectedNav = subNavItem.id;

    let output;
    switch (selectedNav) {
        case 'trafic_info_acl':
            if (traficInfo.acl.length === 0) {
                output = <ErrorMessage><Translate id={"trafic.noinfo"} /></ErrorMessage>;
            } else {
                output = traficInfo.acl.map((item, i) => (
                    <Item key={i}>
                        <ItemTime>{dayjs(item.timestamp).format('HH:mm')}</ItemTime>
                        <ItemTitle dangerouslySetInnerHTML={{__html: item.text}}></ItemTitle>
                    </Item>
                ));
                output = <List><Copyright>Source: RTL/ACL</Copyright>{output}</List>;
            }
            break;
        case 'roadworks':
            if (traficInfo.tmc.chantiers.length === 0) {
                output = <ErrorMessage><Translate id={"trafic.noinfo"} /></ErrorMessage>;
            } else {
                output = traficInfo.tmc.chantiers.map((item, i) => (
                    <Item key={i}>
                        <ItemTitle>{item.title}</ItemTitle>
                        <ItemDirection>{item.directiontext}</ItemDirection>
                        <ItemDescription>{item.eventtext}</ItemDescription>
                    </Item>
                ));
                output = <List><Copyright>Source: RTL/ACL</Copyright>{output}</List>;
            }
            break;
        case 'radar':
            if (radar.items.length === 0) {
                output = <ErrorMessage><Translate id={"trafic.noinfo"} /></ErrorMessage>;
            } else {
                output = radar.items.map((item, i) => (
                    <Item key={i}>
                        <ItemTimeOfDay>{item.timeofday}</ItemTimeOfDay>
                        <ItemCity>{item.city}</ItemCity>
                        <ItemStreet>{item.street}</ItemStreet>
                    </Item>
                ));
                output = <List>{output}</List>;
            }
            break;
        case 'parking':
            if (parking.length === 0) {
                output = <ErrorMessage><Translate id={"trafic.noinfo"} /></ErrorMessage>;
            } else {
                output = parking.map((item, i) => (
                    <Item key={i}>
                        <ItemTitle>{item.name}</ItemTitle>
                        <ItemQuartier>{item.quartier} / <ItemStatus>{item.status}</ItemStatus></ItemQuartier>
                        <ItemPlaces>
                            <ItemCapacity><Translate id={"trafic.capacity"} /> <br /><b>{item.total}</b></ItemCapacity>
                            <ItemCapacity><Translate id={"trafic.free"} /> <br /><b>{item.free}</b></ItemCapacity>
                        </ItemPlaces>
                        
                    </Item>
                ));
                output = <List>{output}</List>;
            }
            break;
        default:
            output = <ErrorMessage><Translate id={"trafic.noinfo"} /></ErrorMessage>;
    }

    if (traficInfo.maps.small)
    
    return (
        <Container>
            <LeftColWrapper width={370}>
                {!mobiliteReduite && <SubNavigation />}
                {output}
                {mobiliteReduite && <SubNavigation />}
            </LeftColWrapper>
            <RightCol>
                <TraficMap src={traficInfo.maps.small} alt="Trafic" />
            </RightCol>
        </Container>
    );
}

export default TraficInfo;