import React from 'react';
import styled, {keyframes} from 'styled-components';

/**
 * Container
 */

const ContainerWrapper = styled.div`
    display: flex;
    flex-direction: ${props => props.direction ? props.direction: 'row'};
    max-height: 100%;
    height: 100%;
    transform: translateZ(0);
    background-color: ${props => props.color && props.color};
`;

const Container = ({ children, direction, color, className }) => {
    return (
        <ContainerWrapper className={className} direction={direction && direction} color={color}>
            {children}
        </ContainerWrapper>
    );
}

/**
 * Left Col
 */

const LeftColWrapper = styled.div`
    max-height: 100%;
    flex: 0 0 ${props => props.theme.scale * props.width}px;
    background-color: ${props => props.color && props.color};
    position: relative;
`;

const LeftCol = ({ children, color, width, className, onClick, onScroll, onMouseDown, onTouchStart }) => {
    let defaultWidth = 320;
    if (width) defaultWidth = width;

    return (
        <LeftColWrapper onClick={onClick} onScroll={onScroll} onMouseDown={onMouseDown} onTouchStart={onTouchStart} className={className} color={color} width={defaultWidth}>
            {children}
        </LeftColWrapper>
    );
}

/**
 * Right Col
 */

const RightColWrapper = styled.div`
    height: 100%;
    flex: 1 0 auto;
    overflow: hidden;
    position: relative;
    background-color: ${props => props.color && props.color};
`;

const RightCol = ({ children, onClick, onScroll, onMouseDown, onTouchStart, color }) => {
    return (
        <RightColWrapper onClick={onClick} onScroll={onScroll} onMouseDown={onMouseDown} color={color} onTouchStart={onTouchStart}>
            {children}
        </RightColWrapper>
    );
}

/**
 * List
 */

const ListWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    position: relative;

    ${props => props.$mr && `
        & > :first-child {
            margin-top: auto !important;
        }
    `}
    
`;

const List = ({ children, className, mr }) => {
    return (
        <ListWrapper className={className} $mr={mr}>
            {children}
        </ListWrapper>
    );
}

/**
 * ListTitle
 */

const ListTitleWrapper = styled.div`
    font-size: ${props => props.theme.scale * 24}px;
    font-family: 'MuseoSans-900';
    padding: ${props => props.theme.scale * 20}px;
`;

const ListTitle = ({ children }) => {
    return (
        <ListTitleWrapper>
            {children}
        </ListTitleWrapper>
    );
}

/**
 * Grid
 */

const GridWrapper = styled.div`
    max-height: 100%;
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    flex-wrap: wrap;
`;

const Grid = ({ children }) => {
    return (
        <GridWrapper>
            {children}
        </GridWrapper>
    );
}


/**
 * Grid Horizontal
 */

const GridHorizontalWrapper = styled.div`    
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    
     
    ${props => props.theme.layout === 0 && `
        flex-direction: row;
        overflow-x: scroll;
        height: ${props.theme.scale * 1050}px;
    `}

    ${props => props.theme.layout === 2 && `
        flex-direction: row;
        overflow-x: scroll;
        height: ${props.theme.scale * 1050}px;
    `}

    ${props => props.theme.layout === 3 && `
        flex-direction: column;
        height: ${props.theme.scale * 954}px;
    `}

    ${props => props.theme.layout === 4 && `
        flex-direction: row;
        overflow-x: scroll;
        height: ${props.theme.scale * 1541}px;
    `}
`;

const GridHorizontal = ({ children }) => {
    return (
        <GridHorizontalWrapper>
            {children}
        </GridHorizontalWrapper>
    );
}

/**
 * PageTitle
 */

const PageTitleWrapper = styled.div`
    z-index: 444;
    position: fixed;
    top: 0px;
    left: 0;
    text-align: center;
    width: 100%;
    padding: ${props => props.theme.scale * 20}px 0;
`;

const PageTitleText = styled.div`
    z-index: 444;
    background-color: rgba(30,30,30,0.8);
    color: ${props => props.theme.white};
    border-radius: ${props => props.theme.scale * 30}px;
    padding: ${props => props.theme.scale * 16}px ${props => props.theme.scale * 40}px;
    display: inline-block;
    text-transform: uppercase;
    font-size: ${props => props.theme.scale * 22}px;
    font-family: 'MuseoSans-900';
`;

const PageTitle = ({ children }) => {
    return (
        <PageTitleWrapper>
            <PageTitleText>
                {children}
            </PageTitleText>
        </PageTitleWrapper>
    );
}

/**
 * Loading
 */

const rotate = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const LoadingWrapper = styled.div`
    position: absolute;
    top: 20%;
    left: calc(50% - ${props => props.theme.scale * 32}px);
    display: inline-block;
    width: ${props => props.theme.scale * 64}px;
    height: ${props => props.theme.scale * 64}px;

    &:after {
        content: " ";
        display: block;
        width: ${props => props.theme.scale * 46}px;
        height: ${props => props.theme.scale * 46}px;
        margin: ${props => props.theme.scale * 1}px;
        border-radius: 50%;
        border: ${props => props.theme.scale * 5}px solid ${props => props.theme.shade5};
        border-color: ${props => props.theme.shade5} transparent ${props => props.theme.shade5} transparent;
        animation: ${rotate} 1.2s linear infinite;
    }
`;


const Loading = () => {
    return (
        <LoadingWrapper />
    );
}

export {
    Container, Grid,
    GridHorizontal, LeftCol, List,
    ListTitle, Loading, PageTitle, RightCol
};

