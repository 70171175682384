export const CONFIG_RECEIVED = 'CONFIG_RECEIVED';
export const NAVIGATION_UPDATE = 'NAVIGATION_UPDATE';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SET_ERROR = 'SET_ERROR';

export const SET_NAVITEM = 'SET_NAVITEM';
export const SET_SUBNAVITEM = 'SET_SUBNAVITEM';
export const SET_ACTIONBUTTON = 'SET_ACTIONBUTTON';
export const SET_SHOWCOPYRIGHT = 'SET_SHOWCOPYRIGHT';
export const SET_SHOWHOWTO = 'SET_SHOWHOWTO';
export const SET_SWIPEINDICATOR = 'SET_SWIPEINDICATOR';

export const SET_DIMENSIONS = 'SET_DIMENSIONS';
export const SET_CUSTOMER = 'SET_CUSTOMER';

export const POLL_START = 'POLL_START';
export const POLL_STOP = 'POLL_STOP';

export const SET_SELECTED_POI = 'SET_SELECTED_POI';
export const SET_SELECTED_IMXPOI = 'SET_SELECTED_IMXPOI';
export const SET_SELECTED_POI_HELPERKNAPP = 'SET_SELECTED_POI_HELPERKNAPP';
export const SET_SELECTED_WHATTODO = 'SET_SELECTED_WHATTODO';
export const SET_SELECTED_EVENT = 'SET_SELECTED_EVENT';

export const SET_MOBILITE_REDUITE = 'SET_MOBILITE_REDUITE';

export const NEWS_RECEIVED = 'NEWS_RECEIVED';
export const VIDEOADS_RECEIVED = 'VIDEOADS_RECEIVED';
export const METEO_RECEIVED = 'METEO_RECEIVED';
export const EVENTS_TODAY_RECEIVED = 'EVENTS_TODAY_RECEIVED';
export const POI_RECEIVED = 'POI_RECEIVED';
export const IMXPOI_RECEIVED = 'IMXPOI_RECEIVED';
export const POI_HELPERKNAPP_RECEIVED = 'POIS_HELPERKNAPP_RECEIVED';
export const WHATTODO_RECEIVED = 'WHATTODO_RECEIVED';
export const EREIDER_RECEIVED = 'EREIDER_RECEIVED';
export const TRAFIC_RECEIVED = 'TRAFIC_RECEIVED';
export const BIKES_RECEIVED = 'BIKES_RECEIVED';
export const TRAINS_RECEIVED = 'TRAINS_RECEIVED';
export const BUS_RECEIVED = 'BUS_RECEIVED';
export const JOBS_RECEIVED = 'JOBS_RECEIVED'; 
export const POLL_RECEIVED = 'POLL_RECEIVED'; 
export const SDG_RECEIVED = 'SDG_RECEIVED';
export const RADAR_RECEIVED = 'RADAR_RECEIVED';
export const PARKING_RECEIVED = 'PARKING_RECEIVED';
export const ADS_RECEIVED = 'ADS_RECEIVED';
export const RTLNEWS_RECEIVED = 'RTLNEWS_RECEIVED';
export const RTLWEATHER_RECEIVED = 'RTLWEATHER_RECEIVED';
export const RTLSDG_RECEIVED = 'RTLSDG_RECEIVED';

export const SET_DEFAULT_PATH = 'DEFAULT_PATH';
export const SET_HOME = 'SET_HOME';
export const SET_LARGE_LAYOUT = 'SET_LARGE_LAYOUT';

export const fetchError = (error) => ({
    type: FETCH_ERROR,
    error: error
});

export const setError = () => ({
    type: SET_ERROR
});

export const setActionButton = (actionButton, action) => ({
    type: SET_ACTIONBUTTON,
    actionButton: actionButton,
    action: action
});

export const setShowHowto = (value) => ({
    type: SET_SHOWHOWTO,
    value: value
});

export const setShowCopyright = (value) => ({
    type: SET_SHOWCOPYRIGHT,
    value: value
});

export const setNavItem = (navItem) => ({
    type: SET_NAVITEM,
    navItem: navItem
});

export const setDefaultPath = (defaultPath) => ({
    type: SET_DEFAULT_PATH,
    defaultPath: defaultPath
});

export const setHome = (home) => ({
    type: SET_HOME,
    home: home
});

export const setLargeLayout = (largeLayout) => ({
    type: SET_LARGE_LAYOUT,
    largeLayout: largeLayout
});

export const setSwipeIndicator = (state) => ({
    type: SET_SWIPEINDICATOR,
    state: state
});

export const setSubNavItem = (subNavItem) => ({
    type: SET_SUBNAVITEM,
    subNavItem: subNavItem
});

export const setDimensions = (layout) => ({
    type: SET_DIMENSIONS,
    layout: layout
});

export const setCustomer = (customer, screen, local) => ({
    type: SET_CUSTOMER,
    customer: customer,
    screen: screen,
    local: local
});

export const pollStart = () => ({
    type: POLL_START,
});

export const pollStop = () => ({
    type: POLL_STOP,
});

export const setSelectedPoi = (poi) => ({
    type: SET_SELECTED_POI,
    poi: poi
});

export const setSelectedIMXPoi = (imxpoi) => ({
    type: SET_SELECTED_IMXPOI,
    poi: imxpoi
});

export const setSelectedWhattodo = (whattodo) => ({
    type: SET_SELECTED_WHATTODO,
    poi: whattodo
});

export const setSelectedEvent = (event) => ({
    type: SET_SELECTED_EVENT,
    poi: event
});

export const setMobiliteReduite = (mobiliteReduite) => ({
    type: SET_MOBILITE_REDUITE,
    mobiliteReduite: mobiliteReduite
});
