import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled, {css} from 'styled-components';
import {Container, LeftCol, List, RightCol} from '../components/Layout';

import SwipeIndicator from '../components/SwipeIndicator';

import {
    SET_ACTIONBUTTON,
    SET_SWIPEINDICATOR
} from '../redux/actions';

const selectedMixin = css`
    background-color: ${props => props.theme.darkgray};
    color: ${props => props.theme.white};
`;

const Item = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    margin-bottom: 4px;
    cursor: pointer;
    user-select: none;
    ${props => props.selected && selectedMixin}
`;

const ItemDescription = styled.div`
    width: ${props => props.theme.scale * 265}px;
    box-sizing: border-box;
    padding-left: ${props => props.theme.scale * 30}px;
    padding-top: ${props => props.theme.scale * 20}px;
    padding-bottom: ${props => props.theme.scale * 20}px;
`;

const ItemTitle = styled.div`
    font-size: ${props => props.theme.scale * 16}px;
    font-family: 'MuseoSans-300';
`;

const JobContent = styled.div`
    overflow-y: auto;
    height: 100%;
    max-width: ${props => props.theme.scale * props.theme.contentWidth - 320}px;
`;

const JobTitle = styled.div`
    font-size: ${props => props.theme.scale * 30}px;
    font-family: 'MuseoSans-900';
    padding: ${props => props.theme.scale * 40}px ${props => props.theme.scale * 40}px ${props => props.theme.scale * 10}px ${props => props.theme.scale * 40}px;
    box-sizing: border-box;
`;

const JobBody = styled.div`
    padding: 0 ${props => props.theme.scale * 40}px ${props => props.theme.scale * 40}px ${props => props.theme.scale * 40}px;
    box-sizing: border-box;
`;

const ListWrapper = styled(List)`
    padding-top: ${props => props.theme.scale * 30}px;
    box-sizing: border-box;
`;

const SwipeIndicatorWrapper = styled(SwipeIndicator)`
    position: absolute;
    top: ${props => props.theme.scale * 380}px;
    left: ${props => props.theme.scale * 280}px;
`;

const Jobs = () => {
    const [itemSelected, setItemSelected] = useState(undefined);
    const jobs = useSelector(state => state.data.jobs);
    const theme = useSelector(state => state.config.theme);
    const swipeIndicator = useSelector(state => state.config.swipeIndicator);
    const dispatch = useDispatch();

    if (!jobs) return(null);

    const itemClicked = (item) => { 
        setItemSelected(item);
        dispatch({ type: SET_ACTIONBUTTON, actionButton: "mail", action: item.link});
        document.getElementById('jobcontent').scrollTo(0, 0);
    }

    if (!itemSelected) {
        if (jobs.length > 0) {
            setItemSelected(jobs[0]);        
        
            console.log(jobs[0]);
            dispatch({ type: SET_ACTIONBUTTON, actionButton: "mail", action: jobs[0].link});
        }
    }

    if (!itemSelected) return(null);

    const hideSwiper = () => { 
        if (swipeIndicator === true) dispatch({ type: SET_SWIPEINDICATOR, state: false});
    }

    return (
        <Container>
            <LeftCol color={theme.lightgray} width={320}>
                <ListWrapper>
                    {jobs.map((item, i) => (
                        <Item key={i} onClick={() => itemClicked(item)} selected = {itemSelected.title === item.title}>
                            <ItemDescription>
                                <ItemTitle>{item.title.trim() ? item.title : 'NO TITLE'}</ItemTitle>
                            </ItemDescription>
                        </Item>
                    ))}
                </ListWrapper>
            </LeftCol>
            <RightCol onMouseDown={hideSwiper} onTouchStart={hideSwiper} onScroll={hideSwiper}>
                <JobContent id="jobcontent">
                    <JobTitle>{itemSelected.title}</JobTitle>
                    <JobBody dangerouslySetInnerHTML={{ __html: itemSelected.body }}></JobBody>
                </JobContent>
                {swipeIndicator && <SwipeIndicatorWrapper direction="v" />}  
            </RightCol>
        </Container>
    );
}

export default Jobs;