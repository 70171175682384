import { all } from 'redux-saga/effects';

import fetchDataWatcher from './data.js';
import fetchConfigWatcher from './config.js';

export default function* rootSaga() {
   yield all([
         fetchConfigWatcher(),
         fetchDataWatcher()
   ]);
}