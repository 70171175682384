import dayjs from 'dayjs';
import React from 'react';
import {Translate} from 'react-localize-redux';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import PoiMap from '../components/PoiMap';
// import GeoPortailMap from '../components/GeoPortailMap';
import {Container, LeftCol, List, RightCol} from '../components/Layout';
import SubNavigation from '../components/SubNavigation';
import SwipeIndicator from '../components/SwipeIndicator';
import images from '../config/images';
import {
    SET_ACTIONBUTTON, SET_SELECTED_POI,

    SET_SWIPEINDICATOR
} from '../redux/actions';



const PoiItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: ${props => props.theme.scale * 10}px 0;
    flex: 0 0 auto;
    cursor: pointer;
    user-select: none;
    background: url(${images.icons.info});
    background-repeat: no-repeat;
    background-position: ${props => props.theme.scale * 268}px ${props => props.theme.scale * 25}px;
    background-size: ${props => props.theme.scale * 25}px;
    padding: ${props => props.theme.scale * 15}px ${props => props.theme.scale * 50}px ${props => props.theme.scale * 0}px ${props => props.theme.scale * 15}px;
    box-sizing: border-box;
`;

const PoiDescription = styled.div`
    width: ${props => props.theme.scale * 194}px;
    box-sizing: border-box;
    padding-left: ${props => props.theme.scale * 20}px;
    padding-top: ${props => props.theme.scale * 15}px;
    padding-bottom: ${props => props.theme.scale * 15}px;
`;

const PoiTitle = styled.div`
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-900';
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const Detail = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding: ${props => props.theme.scale * 30}px;
    box-sizing: border-box;
    overflow-y: auto;
`;

const DetailTitle = styled.div`
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-900';
    padding-right: ${props => props.theme.scale * 30}px;
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const TrainListItem = styled.div`
    margin: ${props => props.theme.scale * 20}px;
    position: relative;
    height: ${props => props.theme.scale * 52}px;
`;

const TrainName = styled.div`
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-900';
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const TrainDirection = styled.div`
    font-size: ${props => props.theme.scale * 16}px;
    font-family: 'MuseoSans-300';
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const TrainTime = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const TrainExpected = styled.div`
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-900';
`;

const BusDetail = styled.div`
    margin: ${props => props.theme.scale * 22}px 0;
    position: relative;
`;

const BusDetailTime = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-900';
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const BusDetailName = styled.div`
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-900';
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const BusDetailInfo = styled.div`
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-500';
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const BusDetailDirection = styled.div`
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-500';
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const BusListItem = styled.div`
    margin: ${props => props.theme.scale * 30}px 0;
    padding: 0 ${props => props.theme.scale * 45}px 0 ${props => props.theme.scale * 30}px;
    position: relative;    
    flex: 0 0 auto;

    ${(props) =>
        props.hasInfo
            && `
            background: url(${images.icons.info});
            background-repeat: no-repeat;
            background-position: ${props.theme.scale * 278}px ${props.theme.scale * 0}px;
            background-size: ${props.theme.scale * 26}px;
            min-height: ${props => props.theme.scale * 54}px;
    `}
`;

const BusName = styled.span`
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-900';

    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const BusInfo = styled.span`
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-500';
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const BusDirection = styled.div`
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-500';
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const BusTime = styled.div`
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-900';
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const ItemTitle = styled.div`
    font-size: ${props => props.theme.scale * 18}px;
    font-family: 'MuseoSans-900';
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const Item = styled.div`
    width: 100%;
    margin: ${props => props.theme.scale * 10}px 0;
    flex: 0 0 auto;
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;
    padding: ${props => props.theme.scale * 0}px ${props => props.theme.scale * 20}px;
`;

const ItemQuartier = styled.div`
    font-size: ${props => props.theme.scale * 18}px;
    font-family: 'MuseoSans-300';
`;

const ItemStatus = styled.span`
    text-transform: capitalize;
`;

const ItemPlaces = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: ${props => props.theme.scale * 10}px;
`;

const ItemCapacity = styled.div`
    width: 50%;
    font-size: ${props => props.theme.scale * 18}px;
    font-family: 'MuseoSans-300';

    b {
        font-family: 'MuseoSans-900';
    }
`;

const TrainTitle = styled.div`
    font-size: ${props => props.theme.scale * 22}px;
    font-family: 'MuseoSans-900';
    margin-bottom: ${props => props.theme.scale * 12}px;
    padding: ${props => props.theme.scale * 20}px ${props => props.theme.scale * 20}px 0 ${props => props.theme.scale * 20}px;

    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const VeloAddress = styled.div`
    font-size: ${props => props.theme.scale * 16}px;
    font-family: 'MuseoSans-500';
    margin-bottom: ${props => props.theme.scale * 30}px;
`;

const VeloLine = styled.div`
    font-size: ${props => props.theme.scale * 16}px;
    font-family: 'MuseoSans-500';
    margin-bottom: ${props => props.theme.scale * 12}px;
`;


const VeloDispo = styled.div`
    font-size: ${props => props.theme.scale * 16}px;
    font-family: 'MuseoSans-900';
`;

const VeloUrl = styled.div`
    font-size: ${props => props.theme.scale * 16}px;
    font-family: 'MuseoSans-500';
    margin-top: ${props => props.theme.scale * 12}px;
`;

const DetailImageWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${props => props.theme.scale * 300}px;
    overflow: hidden;
`;

const DetailImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

const Close = styled.div`
    cursor: Pointer;
    user-select: none;
    width: ${props => props.theme.scale * 30}px;
    height: ${props => props.theme.scale * 30}px;
    position: absolute;
    top: ${props => props.theme.scale * 28}px;
    right: ${props => props.theme.scale * 18}px;
`;

const LeftColWrapper = styled(LeftCol)`
    ${props => props.showsubnav ? props.$mr  ?`
            display: grid;
            grid-template-rows: 1fr min-content;
        ` : `
            display: grid;
            grid-template-rows: min-content 1fr;
        ` 
    : `
        max-height: calc(100% - ${props.theme.scale * 60}px);
    `};
`;

const DetailQR = styled.div`
    margin: ${props => props.theme.scale * 20}px auto;
    display: block;
    position: absolute;
    bottom: ${props => props.theme.scale * 300}px;
    left: ${props => props.theme.scale * 20}px;
    height: ${props => props.theme.scale * 150}px;    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        width: ${props => props.theme.scale * 100}px;
    }

    h4 {
        font-size: ${props => props.theme.scale * 12}px;
        text-transform: uppercase;
        margin: 0;
    }
`;

const SwipeIndicatorWrapper = styled(SwipeIndicator)`
    position: absolute;
    top: ${props => props.theme.scale * ((props.theme.contentHeight / 2) - 97)}px;
    left: ${props => props.theme.scale * 130}px;
`;

const Mobility = () => {
    const poi = useSelector(state => state.data.poi);
    const selectedPoi = useSelector(state => state.data.selectedPoi);
    const subNavItem = useSelector(state => state.config.subNavItem);
    const mobiliteReduite = useSelector(state => state.config.mobiliteReduite);
    const swipeIndicator = useSelector(state => state.config.swipeIndicator);
    const theme = useSelector(state => state.config.theme);
    const showSubNav = useSelector(state => state.config.theme.showSubNav);
    const dispatch = useDispatch();

    if (!poi) return(null);
    if (!poi.bus) return(null);
    if (!poi.velok) return(null);
    if (!poi.trains) return(null);
    if (!poi.parking) return(null);

    const itemClicked = (poi, hasInfo) => { 
        console.log("HERE")
        if (!hasInfo) return;
        
        dispatch({ type: SET_SELECTED_POI, poi: poi});
        if (poi.link) {
            dispatch({ type: SET_ACTIONBUTTON, actionButton: "mail", action: poi.link});
        } else { 
            dispatch({ type: SET_ACTIONBUTTON, actionButton: undefined});
        }        
    }

    const closeClicked = () => { 
        dispatch({ type: SET_SELECTED_POI, poi: undefined});
        dispatch({ type: SET_ACTIONBUTTON, actionButton: undefined});
    }
    
    let selectedNav = 'bus';
    if (subNavItem) selectedNav = subNavItem.id;

    let infoIcon;
    if (!theme.iconSet || theme.useDefaultListInfoIcons) {
        infoIcon = images.icons.info;
    } else {
        infoIcon = `images/themes/${theme.iconSet}/ic_info_black.svg`;
    }

    let output;

    switch (selectedNav) {
        case 'trains':

            const trainKeys = Object.keys(poi[selectedNav]);
            let trainStations = [];
            trainKeys.forEach(key => {
                let out = poi[selectedNav][key].map((item, index) => (                    
                    <TrainListItem key={index}>
                        <TrainName>{item.name}</TrainName>
                        <TrainDirection>{item.direction}</TrainDirection>
                        <TrainTime>
                            <TrainExpected>{item.time.substring(0,5)}</TrainExpected>
                        </TrainTime>
                    </TrainListItem>
                ));
                trainStations.push(out);
            });
            output = <List>{ trainStations.map((station,i) => {
                        if (station) return (<React.Fragment key={i}><TrainTitle>{trainKeys[i]}</TrainTitle>{station}</React.Fragment>);
                        else return(null);
                    })}
                    </List>;
        break;
        case 'bus':
            const busKeys = Object.keys(poi[selectedNav]);
            let busStations = [];
            busKeys.forEach(key => {

                let fDepartures = [];
                if (poi[selectedNav][key].departures.length > 0) {
                    fDepartures = poi[selectedNav][key].departures.filter((departure) => {
                        let cur = dayjs(`${departure.date} ${departure.time}`);
                        if (cur.diff(dayjs(),'minute', true) > 0) {
                            return true;
                        }  else return false;
                    });
                }

                let hasInfo = false;
                if (fDepartures.length > 0) hasInfo = true;

                let out = <BusListItem hasInfo={hasInfo} key={key} onClick={() => itemClicked(poi[selectedNav][key], hasInfo)} >
                    <BusName>{poi[selectedNav][key].name}</BusName>
                        {fDepartures.length > 0 && <>
                            <BusTime>{fDepartures[0].time.substring(0,5)}</BusTime>
                            <BusName>{fDepartures[0].name}</BusName> <BusInfo><Translate id={"mobility.bus_direction"} /></BusInfo><br/>
                            <BusDirection>{fDepartures[0].direction}</BusDirection> 
                        </>}
                </BusListItem>;
                
                busStations.push(out);
            });
            output = <List>{ busStations.map((station,i) => {
                        if (station) return (<React.Fragment key={i}>{station}</React.Fragment>);
                        else return(null);
                    })}
                    </List>;
        break;
        case 'parking':
            output = <List $mr={mobiliteReduite}>
            {poi[selectedNav].map((item, index) => (
                <Item key={index}>
                    <ItemTitle>{item.name}</ItemTitle>
                    <ItemQuartier>{item.quartier} / <ItemStatus>{item.status}</ItemStatus></ItemQuartier>
                    <ItemPlaces>
                        <ItemCapacity><Translate id={"trafic.capacity"} /> <br /><b>{item.total}</b></ItemCapacity>
                        <ItemCapacity><Translate id={"trafic.free"} /> <br /><b>{item.free}</b></ItemCapacity>
                    </ItemPlaces>
                </Item>
            ))}
            </List>;
        break;
        case 'velok':
            let hasInfo = true;
            output = <List $mr={mobiliteReduite}>
            {poi[selectedNav].map((item, index) => (
                <BusListItem hasInfo={hasInfo} key={index} onClick={() => itemClicked(item, hasInfo)} data-on="click" data-event-category="Mobility" data-event-action="open" data-event-label={item.name}>
                    <PoiTitle>{item.name}</PoiTitle>
                    <ItemCapacity>{item.distance}</ItemCapacity>
                    <ItemCapacity><Translate id={"mobility.velos_capacity"} /> <b>{item.attached}</b></ItemCapacity>
                    <ItemCapacity><Translate id={"mobility.velos_available"} /> <b>{item.ebikes + item.bikes}</b></ItemCapacity>
                </BusListItem>
            ))}
            </List>;
        break;
        default:
            output = <List $mr={mobiliteReduite}>
            {poi[selectedNav].map((item, index) => (
                <PoiItem key={index} onClick={() => itemClicked(item)} style={{ backgroundImage: `url("${infoIcon}")` }} data-on="click" data-event-category="Mobility" data-event-action="open" data-event-label={item.name}>
                    <PoiDescription>
                        <PoiTitle>{item.name}</PoiTitle>
                    </PoiDescription>
                </PoiItem>
            ))}
            </List>;
    }

    const hideSwiper = () => { 
        if (swipeIndicator === true) dispatch({ type: SET_SWIPEINDICATOR, state: false});
    }

    let placeholderImage = images.placeholder.velok;
    if (selectedNav === 'veloh') placeholderImage = images.placeholder.veloh;

    // Filter Busses
    let fDepartures = [];
    if (selectedPoi && subNavItem.id === 'bus') {
        fDepartures = selectedPoi.departures.filter((departure) => {
            let cur = dayjs(`${departure.date} ${departure.time}`);
            if (cur.diff(dayjs(),'minute', true) > 0) {
                return true;
            }  else return false;
        });
    }

    return(
        <Container>
            <LeftColWrapper onMouseDown={hideSwiper} onTouchStart={hideSwiper} onScroll={hideSwiper} showsubnav={showSubNav} $mr={mobiliteReduite}>
                {!mobiliteReduite && <SubNavigation />}
                {selectedPoi ? (
                    <Detail $mr={mobiliteReduite}>
                        <Close onClick={() => closeClicked()} $mr={mobiliteReduite}><img src={images.icons.close} alt="Close" /></Close>
                        <DetailTitle>{selectedPoi.name}</DetailTitle>
                        {subNavItem.id === 'bus' ? (
                            <React.Fragment>
                            {fDepartures.map((bus, index) => (
                                <BusDetail key={index}>
                                    <BusDetailName>{bus.name}</BusDetailName>
                                    <BusDetailTime>
                                        {bus.time.substring(0,5)}
                                    </BusDetailTime>
                                    <BusDetailInfo><Translate id={"mobility.bus_direction"} /></BusDetailInfo>
                                    <BusDetailDirection>{bus.direction}</BusDetailDirection>
                                    
                                </BusDetail>
                            ))}
                            </React.Fragment>
                        ) : (
                            <div>
                                <VeloAddress>{selectedPoi.address}</VeloAddress>
                                
                                <VeloLine><Translate id={"mobility.velos_capacity"} /> {selectedPoi.attached} <Translate id={"mobility.velos"} /></VeloLine>
                                <VeloLine><Translate id={"mobility.velos"} /><br/><VeloDispo>{selectedPoi.bikes} <Translate id={"mobility.velos_disponibles"} /></VeloDispo></VeloLine>
                                <VeloLine><Translate id={"mobility.velos_electriques"} /><br/><VeloDispo>{selectedPoi.ebikes} <Translate id={"mobility.velos_disponibles"} /></VeloDispo></VeloLine>

                                {selectedNav === 'veloh' ? <VeloUrl>www.veloh.lu</VeloUrl> : <VeloUrl>www.velok.lu</VeloUrl>}
                                {selectedPoi.qrcode && <DetailQR><h4>ITINERAIRE</h4><img src={selectedPoi.qrcode} alt="QR Code" /></DetailQR>}
                                {selectedPoi.photourl ? <DetailImageWrapper><DetailImage src={selectedPoi.photourl} alt={selectedPoi.name} /></DetailImageWrapper> : <DetailImageWrapper><DetailImage src={placeholderImage} alt={selectedPoi.name} /></DetailImageWrapper>}
                            </div>
                        )}

                    </Detail>
                ) : (
                    <React.Fragment>
                    {output}
                    </React.Fragment>
                )}
                {mobiliteReduite && <SubNavigation />}
                {swipeIndicator && <SwipeIndicatorWrapper direction="v" />}  
            </LeftColWrapper>
            <RightCol>
                <PoiMap type={selectedNav}></PoiMap>
            </RightCol>
        </Container>
    );
}

export default Mobility;