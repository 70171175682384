import React from 'react';
import styled from "styled-components";

const Iframe = styled.iframe`
    border: 0;
    width: 100%;
    height: 100%;
`;

const Webview = () => {
    return (
        <Iframe src="https://jeffgangler.wixsite.com/landofmemory">Webview</Iframe>
    );
}

export default Webview;