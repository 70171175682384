import {withLocalize} from "react-localize-redux";
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {POLL_START, POLL_STOP} from '../redux/actions';


const Toggle = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;

    ${(props) =>
        props.$displayHz &&
        `
            height: 100%;
            li { 
                display: table-cell;
                vertical-align: middle;
                height: ${props.theme.scale * 100}px;
            } 
        `}
`;

const ToggleItem = styled.li`        
    text-align: center;
    ${props => props.theme.layout === 3 && `
        display: table-cell;
        vertical-align: middle;
        height: ${props.theme.scale * 120}px;
    `}
`;

const ToggleButton = styled.button`
    background: none;
    border: none;
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-300';    
    padding: ${props => props.theme.scale * 18}px ${props => props.theme.scale * 20}px; 
    outline: none;  
    color:  ${props => props.theme.language};

    ${props => props.theme.layout === 3 ? `
        padding: ${props.theme.scale * 18}px ${props.theme.scale * 40}px; 
    ` : `
        padding: ${props.theme.scale * 18}px ${props.theme.scale * 20}px; 
    `}
`;

const ToggleButtonActive = styled.button`
    background: none;
    border: none;
    font-size: ${props => props.theme.scale * 24}px;
    font-family: 'MuseoSans-900'; 
    outline: none;

    ${(props) =>
        props.theme.languageHighlight
            ? `
            color: ${props.theme.languageHighlight};
            `
            : `
            color: ${props.theme.language};
    `}

    ${props => props.theme.layout === 3 ? `
        padding: ${props.theme.scale * 18}px ${props.theme.scale * 40}px; 
    ` : `
        padding: ${props.theme.scale * 18}px ${props.theme.scale * 20}px; 
    `}
`;

const LanguageToggle = ({ className, languages, activeLanguage, setActiveLanguage}) => {
    const dispatch = useDispatch();
    const layout = useSelector(state => state.config.layout);
    const theme = useSelector(state => state.config.theme);
    const mr = useSelector(state => state.config.mobiliteReduite);

    const itemClicked = (lang) => { 
        setActiveLanguage(lang);
        dispatch({ type: POLL_STOP});
        dispatch({ type: POLL_START});
    }

    let displayHz = false;
    if (layout === 4 && mr === true) {
        displayHz = true;
    }

    if (theme.allowedLanguages) languages = languages.filter(language => theme.allowedLanguages.includes(language.code));

    return (
        <Toggle className={className} $displayHz={displayHz}>
            {languages.map(lang => (
            <ToggleItem key={lang.code}>
                {activeLanguage.code === lang.code ? (
                    <ToggleButtonActive>
                        {lang.name}
                    </ToggleButtonActive>
                ) : (
                    <ToggleButton onClick={() => itemClicked(lang.code)} data-on="click" data-event-category="Language" data-event-action="change" data-event-label={lang.name}>
                        {lang.name}
                    </ToggleButton>
                )}
                
            </ToggleItem>
            ))}
        </Toggle>
    );
}

export default withLocalize(LanguageToggle);