import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import images from '../config/images';

const SwiperWrapper = styled.div`
    display: block;
    pointer-events: none;
`;

const SwipeIconH = styled.img`
    display: block;
    width: ${props => props.theme.scale * 100}px;
    transform: rotate(-90deg);
`;

const SwipeIconV = styled.img`
    display: block;
    width: ${props => props.theme.scale * 90}px;
`;

const SwipeIndicator = ({ className, direction }) => {
    const theme = useSelector(state => state.config.theme);

    let swipeIcon;
    if (!theme.iconSet) {
        swipeIcon = images.icons.swipe;
    } else {
        swipeIcon = `images/themes/${theme.iconSet}/ic_swipe.svg`;
    }

    return(
        <SwiperWrapper className={className}>
            {direction === 'v' ? 
                <SwipeIconV src={swipeIcon} />
            :
                <SwipeIconH src={swipeIcon} />
            }
        </SwiperWrapper>
    );
}

export default SwipeIndicator;