import agenda_big from '../images/agenda_placeholder.jpg';
import logo_esch2022 from '../images/esch2022/logo_esch2022.svg';
import logos_partenaires from '../images/esch2022/logos_partenaires.svg';
import appEsch from '../images/home_app_esch.png';
import appVelok from '../images/home_app_velok.png';
import homeHand from '../images/home_hand.svg';
import homeBg from '../images/home_velok_bg.png';
import howto_de from '../images/howto_de.png';
import howto_fr from '../images/howto_fr.png';
import close from '../images/ic_close.svg';
import {ReactComponent as downloadDown} from '../images/ic_download_down.svg';
import {ReactComponent as downloadUp} from '../images/ic_download_up.svg';
import {ReactComponent as howto} from '../images/ic_howto.svg';
import info, {ReactComponent as copyright} from '../images/ic_info.svg';
import left from '../images/ic_left.svg';
import {ReactComponent as mail} from '../images/ic_mail.svg';
import marker from '../images/ic_marker.svg';
import markerSelected from '../images/ic_marker_selected.svg';
import menu, {ReactComponent as menuComponent} from '../images/ic_menu.svg';
import {ReactComponent as mr} from '../images/ic_mr.svg';
import right from '../images/ic_right.svg';
import {ReactComponent as showtouch} from '../images/ic_showtouch.svg';
import swipe from '../images/ic_swipe.svg';
import markerBorneLFT from '../images/ic_marker_borne_lft.png';
import markerLFT from '../images/ic_marker_lft.png';
import markerSelectedLFT from '../images/ic_marker_selected_lft.png';
import appleStore from '../images/logo_apple.svg';
import esch_velok from '../images/logo_esch_velok_white.svg';
import esch from '../images/logo_esch_white.svg';
import googlePlay from '../images/logo_googleplay.svg';
import hesperange from '../images/logo_hesperange_white.svg';
import mondorf from '../images/logo_mondorf_white.svg';
import petange from '../images/logo_petange_white.svg';
import frisange from '../images/logo_frisange_color.svg';
import rtl from '../images/logo_rtl.svg';
import rtl5minutes from '../images/logo_rtl5minutes.svg';
import rtlToday from '../images/logo_rtltoday.svg';
import smarttouch from '../images/logo_st_white.svg';
import lftVideo from '../images/logo_lft_video.svg';
import lftPoster from '../images/logo_lft_poster.svg';
import lftLandscape from '../images/logo_lft_landscape.svg';
import salonDesCommunes from '../images/logo_salon-des-communes.svg';
import {ReactComponent as commune} from '../images/nav_commune.svg';
import {ReactComponent as eat_and_drink} from '../images/nav_eat_and_drink.svg';
import {ReactComponent as ereider} from '../images/nav_ereider.svg';
import {ReactComponent as events} from '../images/nav_events.svg';
import {ReactComponent as exploreNav} from '../images/nav_explorer.svg';
import {ReactComponent as floorplan} from '../images/nav_floorplan.svg';
import {ReactComponent as games} from '../images/nav_games.svg';
import {ReactComponent as health} from '../images/nav_health.svg';
import {ReactComponent as accomodationsNav} from '../images/nav_hebergement.svg';
import {ReactComponent as home} from '../images/nav_home.svg';
import {ReactComponent as hotelsNav} from '../images/nav_hotels.svg';
import {ReactComponent as information} from '../images/nav_information.svg';
import {ReactComponent as jobs} from '../images/nav_jobs.svg';
import {ReactComponent as mobility} from '../images/nav_mobility.svg';
import {ReactComponent as overview} from '../images/nav_overview.svg';
import {ReactComponent as photo} from '../images/nav_photo.svg';
import {ReactComponent as imxpoiNav} from '../images/nav_poi.svg';
import {ReactComponent as points_of_interest} from '../images/nav_points_of_interest.svg';
import {ReactComponent as poll} from '../images/nav_poll.svg';
import {ReactComponent as rtlIcon} from '../images/nav_rtl.svg';
import {ReactComponent as shops} from '../images/nav_shops.svg';
import {ReactComponent as trafic_info} from '../images/nav_trafic_info.svg';
import {ReactComponent as weather} from '../images/nav_weather.svg';
import {ReactComponent as imxrestaurantsNav} from '../images/nav_restaurants.svg';
import {ReactComponent as whattodoNav} from '../images/nav_whattodo_mondorf.svg';
import {ReactComponent as whattodorestaurantsNav} from '../images/nav_whattodorestaurants.svg';
import accomodations from '../images/ph_accomodations.jpg';
import bar from '../images/ph_bar.jpg';
import cafe from '../images/ph_cafe.jpg';
import explore from '../images/ph_explore.jpg';
import pharmacie from '../images/ph_pharmacie.jpg';
import restaurant from '../images/ph_restaurant.jpg';
import restaurants from '../images/ph_restaurants.jpg';
import veloh from '../images/ph_veloh.jpg';
import velok from '../images/ph_velok.jpg';
import hotels from '../images/ph_hotels.jpg';
import sante__bien_etre from '../images/ph_sante__bien_etre.jpg';
import cafes__bistrots from '../images/ph_cafes__bistrots.jpg';
import imxpoi from '../images/ph_imxpoi.jpg';

const images = {
    logos: {
        smarttouch : smarttouch,
        lft : lftVideo,
        lftPoster: lftPoster,
        lftLandscape: lftLandscape,
        esch : esch,
        mondorf : mondorf,
        petange : petange,
        frisange: frisange,
        hesperange : hesperange,
        rtl: rtl,
        rtl5minutes: rtl5minutes,
        rtlToday: rtlToday,
        esch_velok: esch_velok,
        appleStore: appleStore,
        googlePlay: googlePlay,
        salonDesCommunes: salonDesCommunes,
    },
    home: {
        homeBg : homeBg,
        homeHand : homeHand,
        appEsch: appEsch,
        appVelok: appVelok
    },
    nav: {
        overview: overview,
        mobility: mobility,
        eat_and_drink: eat_and_drink,
        events: events,
        photo: photo,
        weather: weather,
        hotels: hotelsNav,
        information: information,
        trafic_info: trafic_info,
        jobs: jobs,
        ereider: ereider,
        health: health,
        games: games,
        poll: poll,
        shops: shops,
        commune: commune,
        home: home,
        floorplan: floorplan,
        points_of_interest: points_of_interest,
        rtl: rtlIcon,
        webview: rtlIcon,
        imxpoi: imxpoiNav,
        accomodations: accomodationsNav,
        explore: exploreNav,
        restaurants: imxrestaurantsNav,
        whattodo: whattodoNav,
        whattodorestaurants: whattodorestaurantsNav
    },
    icons: {
        menu: menu,
        menuComponent: menuComponent,
        marker: marker,
        markerSelected: markerSelected,
        left: left,
        right: right,
        close: close,
        info: info,
        copyright: copyright,
        mail: mail,
        mr: mr,
        swipe: swipe,
        howto: howto,
        showtouch: showtouch,
        downloadUp: downloadUp,
        downloadDown: downloadDown,
        markerBorneLFT: markerBorneLFT,
        markerLFT: markerLFT,
        markerSelectedLFT: markerSelectedLFT
    },
    placeholder: {
        cafe: cafe,
        bar: bar,
        restaurant: restaurant,
        restaurants: restaurants,
        cafes__bistrots: cafes__bistrots,
        sante__bien_etre: sante__bien_etre,
        hotels: hotels,
        explore: explore,
        accomodations: accomodations,
        imxpoi: imxpoi,
        pharmacie: pharmacie,
        veloh: veloh,
        velok: velok,
        howto_de: howto_de,
        howto_fr: howto_fr,
        agenda_big: agenda_big
    },
    esch2022: {
        logo_esch2022: logo_esch2022,
        logos_partenaires: logos_partenaires
    },
};

export default images;
