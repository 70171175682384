import styled from 'styled-components';

const CopyrightTitle = styled.h1`
    font-size: ${props => props.theme.scale * 26}px;
    font-family: 'MuseoSans-500';
    margin-right: ${props => props.theme.scale * 0}px;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 7%;
`;

const ContactTitle = styled.h1`
    font-size: ${props => props.theme.scale * 27}px;
    font-family: 'MuseoSans-700';
    margin-right: ${props => props.theme.scale * 0}px;
    text-transform: uppercase;
    margin: 0;
`;

const CopyrightSubtitle = styled.h1`
    font-size: ${props => props.theme.scale * 22}px;
    font-family: 'MuseoSans-300';
    margin-right: ${props => props.theme.scale * 30}px;
    text-transform: uppercase;
    margin-top: 0;
`;

const CopyrightScroll = styled.div`
    height: calc(100% - ${props => props.theme.scale * 80}px);
    overflow-y: auto;
    font-size: ${props => props.theme.scale * 22}px; 
    font-family: 'MuseoSans-300';
`;

const InformationTitle = styled.h1`
    color: ${props => props.theme.shade1};
    font-size: ${props => props.theme.scale * 24}px;
    font-family: 'MuseoSans-300';
    margin-right: ${props => props.theme.scale * 0}px;
    text-transform: uppercase;
`;

const InformationSubTitle = styled.div`
    font-size: ${props => props.theme.scale * 22}px;
    font-family: 'MuseoSans-300';
    margin-right: ${props => props.theme.scale * 0}px;
    margin: 0;
`;

const Important = styled.div`
    color: #b34621;
    font-size: ${props => props.theme.scale * 22}px;
    font-family: 'MuseoSans-300';
    margin-right: ${props => props.theme.scale * 0}px;
    margin: 0;
`;

const InformationTable = styled.table`
    border: none;
    border-collapse: collapse;
    width: 80%;
    margin: ${props => props.theme.scale * 22}px 0;

    td {
        padding: 0;
    }
`;

const EschVelok = () => {
    return (
        <>
            <CopyrightTitle>Conditions générales d'accès et d'utilisation</CopyrightTitle>
            <CopyrightSubtitle>du service « Vël’OK » Esch-Sur-Alzette pour les abonnés</CopyrightSubtitle>
            <CopyrightScroll>
                <h2>ARTICLE 1. Objet du service</h2>
                <p>« Vël’Ok» est un service exploité par le Centre d'Initiative et de Gestion Local (CIGL) Esch-sur-Alzette ASBL (ci-après « l'Exploitant ») et visant à mettre à disposition du Client un vélo pour ses déplacements sur le territoire de la Ville.</p>
                
                <p>Coordonnées de l'Exploitant :<br />
                Cigl Esch 32a rue Zénon Bernard à L-4031 Esch/Alzette<br />
                Site web : www.ciglesch.lu / E-mail : info(@)ciglesch.lu<br />
                Téléphone +352 54 42 45 200 / Fax +352 54 42 45 500</p>

                <h2>ARTICLE 2. Disponibilité du service et durée de location</h2>
                <p>2.1. L'abonnement de longue durée a une validité maximale de 1 an, il est renouvelable chaque année par tacite reconduction.</p>
                <p>2.2. Pendant cette durée de validité, le Client ne peut utiliser ce service que pour une durée maximale de 2 heures consécutives. En cas de litige sur la durée d'utilisation du Vélo par le client, feront foi les données délivrées par le serveur informatique du service. </p>
                <p>2.3. Le service est accessible dans la limite des Vélos disponibles dans chaque station, 12 mois sur 12, 7 jours sur 7 sans interruption, sauf cas de force majeure ou édiction par les autorités compétentes d'une restriction totale ou partielle, temporaire ou définitive de l'usage d'une ou plusieurs stations ou de la circulation cycliste sur le territoire de la Ville d'Esch-sur-Alzette.</p>

                <h2>Article 3. Modalités pratiques d'accès au service</h2>
                <p>Le service Vël’Ok fonctionne uniquement avec la carte M-Kaart. Le Client passe la carte M-Kaart devant la borne électronique qui équipe la station et le vélo se libère. A la fin de la course, le Client insère le vélo dans une des bornes vide.</p>

                <h2>Article 4. Conditions d'accès au service</h2>
                <p>4.1. Le Client doit, et déclare en acceptant les présentes, être en mesure d'utiliser un Vélo, avoir la condition physique adaptée à cette utilisation et être titulaire d'une police d'assurance responsabilité civile.</p>
                <p>4.2. L'accès au service est également ouvert aux mineurs à partir de 14 ans, cependant leur abonnement doit être souscrit par leur tuteur légal et sous sa responsabilité. Le mineur doit être titulaire d'une carte M-KAART. </p>
                <p>4.3. Le Client doit avoir expressément pris connaissance et accepté les conditions générales d'accès et d'utilisation au service. A défaut d'acceptation de ces conditions générales, il ne peut poursuivre sa demande. Le Client doit remplir un formulaire qu'il peut trouver sur le site www.velok.lu ou dans les différents lieux d'inscription. Les conditions générales sont également disponibles sur demande auprès de l’exploitant. Le Client doit avoir dûment rempli et signé le formulaire d’inscription pour que l’activation au système vélo puisse se faire. Pour le contrôle de l’identité du titulaire, il est obligatoire de faire parvenir à l’exploitant une copie de la carte d’identité. Le Client doit être titulaire d’une carte M-KAART.</p>

                <h2>Article 5. Obligation du Client</h2>
                <p>5.1. Le Client s'engage à utiliser le service en personne normalement prudente, diligente et avisée et dans le respect des présentes conditions générales mais également de toute autre réglementation le cas échéant applicable notamment aux dispositions du code de la route. </p>
                <p>5.2. Le Client assume la garde du vélo qu'il a retiré et il est, pendant toute la durée de la location, seul responsable de toutes éventuelles dégradations, destructions ou disparitions de ce dernier, mais également de tout éventuel dommage causé à un tiers en quelque relation que ce soit avec la location du vélo.</p>
                <p>5.3. Le Client s'engage à retirer et restituer le Vélo dans les délais de la durée d'utilisation continue maximale autorisée (voir sub. Art. 2.2.) et à rapporter à la fin de la course le Vélo à l'une des bornes expressément prévue à cette fin. Le Client accepte par avance que tout manquement à cette obligation donne droit à l'exploitant au prélèvement d'une pénalité forfaitaire de 150 €. </p>
                <p>5.4. En cas de constatation d'une utilisation du Vélo contraire aux dispositions de l'article 6.1, le Client s'engage à restituer le Vélo à tout moment, à première demande de l’Exploitant ou d'un de ses représentants.</p>
                <p>5.5. Le Client s'engage à signaler dans les plus brefs délais à l'Exploitant la perte, le vol, toute dégradation du Vélo ou tout autre problème relatif à l'utilisation du service et au maximum dans les 24 heures suivants la survenance de l'événement au numéro suivant 8006 2456, le Vélo restant en tout état de cause sous sa responsabilité selon les termes des présentes conditions générales.</p>

                <h2>Article 6. Restriction à l'usage du service</h2>
                <p>6.1. Il est interdit au Client de prêter, louer ou céder, gratuitement ou non, son abonnement ou le Vélo, propriété de l'Exploitant, à une tierce personne et/ou de l'utiliser de quelque autre façon que celles prévues aux présentes conditions générales. L'abonnement est automatiquement suspendu dès le constat d'une quelconque infraction aux présentes conditions générales. </p>
                <p>6.2. Il est interdit de ramener le Vélo à la maison, à l'hôtel ou autres et en cas de non-utilisation le Vélo doit être rapporté sans retard et au plus tard à l'expiration de la durée d'utilisation maximale à une des bornes prévues à cette fin. </p>
                <p>6.3. Le Client est autorisé à utiliser le Vélo selon les termes des présentes pour autant qu'il en fasse un usage raisonnable, ce qui exclu notamment :</p>
                <ul>
                    <li>toute utilisation contraire aux dispositions de la réglementation routière applicables, en particulier les dispositions du Code de la route;</li>
                    <li>toute utilisation sur des terrains ou dans des conditions de nature à endommager le Vélo;</li>
                    <li>le transport de quelques passagers que ce soient de quelque façon que ce soit; </li>
                    <li>toute utilisation du Vélo pouvant mettre en péril le client ou des tiers;</li>
                    <li>tout démontage ou tentative de démontage de tout ou partie du Vélo;</li>
                    <li>plus généralement, toute utilisation anormale d'un Vélo.</li>
                </ul>

                <h2>Article 7. Responsabilités</h2>
                <p>7.1. Le Client est seul et entier responsable des dommages causés par l'utilisation faite du Vélo pendant toute la durée d'utilisation y compris lorsque celle-ci excède la durée maximale d'utilisation autorisée en cas de restitution tardive. </p>
                <p>7.2. Les parents ou représentants légaux de tout mineur abonné au service, seront tenus responsables de tout dommage causé directement ou indirectement par le mineur du fait de l'utilisation du service. </p>
                <p>7.3. Tout emprunt supérieur à la durée maximale d'utilisation autorisée est considéré comme un cas de disparition du Vélo. </p>
                <p>7.4. En cas de disparition du Vélo, le client a l'obligation de signaler cette disparition dans les 24 heures à l'Exploitant au numéro suivant : 8006 2456 et de déposer dans le même délai plainte auprès des services de la police. Le Vélo demeurant sous la pleine et entière responsabilité du Client jusqu'à communication d'une copie du dépôt de cette plainte à l'Exploitant. </p>
                <p>7.5. En cas d'accident et/ou tout autre incident mettant en cause le Vélo, le Client a l'obligation de se signaler dans les 24 heures à l'Exploitant au numéro suivant : 8006 2456. Le Vélo reste sous la pleine et entière responsabilité du Client jusqu'à sa restitution à l'Exploitant </p>
                <p>7.6. Le Vélo étant placé sous la responsabilité du Client (cf. articles 6.2. et 8.1), il est obligé de procéder, préalablement à l'utilisation effective du Vélo retiré, à une vérification élémentaire de ses principaux éléments fonctionnels apparents, et notamment (liste non limitative) : </p>
                <ul>
                    <li>la bonne fixation de la selle, des pédales et du panier; </li>
                    <li>le bon fonctionnement de la sonnette, des freins et de l'éclairage; </li>
                    <li>le bon état général du cadre et des pneumatiques. </li>
                </ul>
                <p>7.7. Il est en outre recommandé au Client : </p>
                <ul>
                    <li>d'adapter sa distance de freinage en cas d'intempéries; </li>
                    <li>d'effectuer le réglage de la selle pour adapter sa hauteur à sa morphologie; </li>
                    <li>de porter un casque homologué et des vêtements adaptés. </li>
                </ul>
                <p>7.8. Le Client déclare que toutes les informations le concernant sont exactes et qu'il est bien titulaire d'une police d'assurance responsabilité civile.</p>

                <h2>Article 8. Pénalités</h2>
                <p>8.1. En signant le formulaire d'inscription, le Client donne à l'Exploitant une autorisation de prélèvement de 150 € à titre de garantie (procuration bancaire), pouvant être levée par l'Exploitant dans les cas et selon les conditions ci-après détaillés et limitativement énumérés : détérioration, usage frauduleux et/ou disparition du Vélo dont le Client avait la responsabilité. </p>
                <p>8.2. Le montant correspondant des pénalités (cf. article 8.3) est exigible à première demande de l'Exploitant, en cas de constatation d'un manquement du Client à ses obligations au titre des présentes. </p>
                <p>8.3. La nature et/ou le montant des pénalités dues à l'Exploitant par le Client en cas de manquement de ce dernier s'établissent comme suit : </p>
                <ul>
                    <li>disparition du Vélo en contravention avec l'article 6.3. : 150 €; </li>
                    <li>vol du vélo avec violence à la personne : 35 € (le récépissé de la plainte portée au commissariat de police fait foi); </li>
                    <li>réparation des détériorations subies par le vélo imputables au client : montant forfaitaire en fonction du degré de dégradation ;</li>
                </ul>
                <p>8.4. La mise en œuvre des pénalités précitées ne préjudiciera en rien les droits de l'Exploitant de réclamer au Client l'intégralité du préjudice subi, pour le cas où la responsabilité du Client devait être engagée.</p>

                <h2>Article 9. Confidentialité et utilisation des données personnelles</h2>
                <p>L’ensemble des vélos électriques mis à disposition des Clients sont équipés d’un système de traçage GPRS géré par la société à responsabilité limitée SKYCOM S.à R.L., établie et ayant son siège social à NIEDERONVEN, 45A, rue des Romains, enregistrée au RCS sous le numéro B049002. Le traitement des données recueillies le sera conformément à la législation luxembourgeoise et européenne en vigueur, notamment en matière de protection des données à caractère personnel par la société SKYCOM S.à R.L. Ces données sont susceptibles d’être continuées / seront continuées au C.I.G.L. Esch dans le cadre de la gestion du pool de vélos.. Cette collecte de données doit permettre la géolocalisation des vélos à tout moment afin de garantir une répartition adéquate des vélos sur le territoire de la Ville. Le Client reconnait avoir connaissance de cette collecte de données et y marque son accord. Tout Client peut obtenir communication et, le cas échéant, rectification ou suppression des informations le concernant, en s'adressant à «Vël’Ok» (coordonnées à l'article 1er des présentes). </p>

                <h2>Article 10. Règlement des litiges</h2>
                <p>Les présentes sont soumises à la loi luxembourgeoise. Tout différend relatif à leur exécution et à leurs suites sera soumis à la juridiction des tribunaux compétents de Luxembourg auxquels les Parties font expressément attribution de compétence, y compris en cas de référé, d'appel en garantie ou de pluralité de défendeurs.</p>

                <h2>Article 11. Modification des présentes conditions générales</h2>
                <p>Les Clients seront systématiquement informés de toute modification des présentes conditions générales par affichage sur l'écran « Vël’Ok » des bornes et sur le site Internet de l'Exploitant. En signant les présentes conditions générales, le Client déclare expressément les avoir intégralement lues et comprises et les avoir acceptées dans leur intégralité! </p>
            </CopyrightScroll>
        </>
    )
}

const Mersch = () => {
    return (
        <>
            <InformationTitle>HEURES D'OUVERTURE</InformationTitle>
            <CopyrightScroll>
                <InformationSubTitle>Administration communale - service administratif</InformationSubTitle>
                Lundi à Vendredi de 08h30 - 11h30 et de 13:30 - 16:00<br/>
                Sauf Mercredi de 08h30 - 11h30 et de 13:30 - 19:00<br/><br/>
                
                <InformationTitle>NOUS CONTACTER</InformationTitle>
                <InformationSubTitle>Numéros de téléphone</InformationSubTitle>
                <InformationTable>
                    <tr><td>Administration communale :</td><td>+352 32 50 23 - 1</td></tr>
                </InformationTable>
                <p></p>
                <InformationSubTitle>Numéros d’urgence</InformationSubTitle>
                <InformationTable>
                    <tr><td>Dépannage (bâtiments) :</td><td>+352 621 540 500</td></tr>
                    <tr><td>Dépannage (eau potable) :</td><td>+352 621 256 8​35</td></tr>
                </InformationTable>

                <InformationTitle>NOUS TROUVER</InformationTitle>
                <InformationSubTitle>Adresses</InformationSubTitle><br/>
                <InformationSubTitle>Administration communale</InformationSubTitle>
                <InformationSubTitle>Service Administratif</InformationSubTitle>
                Place St Michel<br/>
                L-7556 Mersch
                <p></p>
                <InformationSubTitle>Adresse postale</InformationSubTitle>
                B.P. 93<br/>
                L-7501 Mersch<br/>
            </CopyrightScroll>
        </>
    )
}

const Petange = () => {
    return (
        <>
            <InformationTitle>HEURES D'OUVERTURE</InformationTitle>
            <CopyrightScroll>
                <InformationSubTitle>Administration Communale de Pétange</InformationSubTitle>
                Du lundi au vendredi de 08h30 – 11h30 et de 13h30 – 16h30<br/>
                (mercredi nocturne jusqu’à 18h30)<br/><br/>

                <InformationTitle>NOUS CONTACTER</InformationTitle>
                <InformationSubTitle>Numéro de téléphone</InformationSubTitle>
                <InformationTable>
                    <tr><td>Administration communale :</td><td>+352 50 12 51 1000</td></tr>
                </InformationTable>
                <p></p>
                <InformationSubTitle>Numéros d’urgence</InformationSubTitle>
                <InformationTable>
                    <tr><td>En dehors des heures d’ouverture :</td><td>+352 621 240 144</td></tr>
                    <tr><td>Décès et enterrements (samedis, dimanches et jours fériés) :</td><td>+352 621 256 8​35</td></tr>
                    <tr><td>Re​​pas sur roues :</td><td>+352 621 227 434</td></tr>
                </InformationTable>

                <InformationTitle>NOUS TROUVER</InformationTitle>
                <InformationSubTitle>Adresse</InformationSubTitle><br/>
                <InformationSubTitle>Administration communale - Service Administratif</InformationSubTitle>
                place J.F. Kennedy<br/>
                L – 4760 Pétange
            </CopyrightScroll>
        </>
    )
}

const Hesperange = () => {
    return (
        <>
            <InformationTitle>HEURES D'OUVERTURE</InformationTitle>
            <CopyrightScroll>
                <InformationSubTitle>Administration Communale de Hesperange</InformationSubTitle>
                Du lundi au vendredi de 07:45 à 11:45 et de 13:30 à 17:00<br/>
                (mercredi jusqu'à 18h00)<br/><br/>

                <InformationTitle>NOUS CONTACTER</InformationTitle>
                <InformationSubTitle>Numéros de téléphone</InformationSubTitle>
                <InformationTable>
                    <tr><td>Administration communale :</td><td>+352 36 08 08 – 1</td></tr>
                    <tr><td>En dehors des heures d’ouverture (numéro d’urgence) :</td><td>+352 28 80 40 – 999</td></tr>
                    <tr><td>Permanence technique en dehors des heures de bureau :</td><td>+352 36 08 08 - 9999</td></tr>
                    <tr><td>Permanence pour déclaration de décès les weekends et jours fériés :</td><td>+352 621 227 434</td></tr>
                </InformationTable>

                <InformationTitle>NOUS TROUVER</InformationTitle>
                <InformationSubTitle>Adresse</InformationSubTitle><br/>
                <InformationSubTitle>Administration communale - Service Administratif</InformationSubTitle>
                474, route de Thionville<br/>
                L-5886 Hesperange
                <p></p>
                <InformationSubTitle>Adresse postale</InformationSubTitle>
                BP 23<br/>
                L – 4701 Pétange<br/>
            </CopyrightScroll>
        </>
    )
}

const Dippach = () => {
    return (
        <>
            <InformationTitle>HEURES D'OUVERTURE</InformationTitle>
            <CopyrightScroll>
                <InformationSubTitle>Administration Communale de Dippach</InformationSubTitle>
                Lundi de 08:00 à 12:00 et de 14:00 à 18:00<br/>
                Mardi de 08:00 à 12:00 et de 14:00 à 17:00<br/>
                Mercredi de 07:30 à 12:00 et de 14:00 à 17:00<br/>
                Jeudi de 08:00 à 12:00 et de 14:00 à 17:00<br/>
                Vendredi de 08:00 à 12:00 et de 14:00 à 17:00<br/><br/>

                <InformationTitle>NOUS CONTACTER</InformationTitle>
                <InformationSubTitle>Numéro de téléphone</InformationSubTitle>
                <InformationTable>
                Administration communale :+352 27 95 25 200
                </InformationTable>

                <InformationTitle>NOUS TROUVER</InformationTitle>
                <InformationSubTitle>Administration communale</InformationSubTitle>
                11, rue de l’Eglise<br/>
                L - 4994 Schouweiler
                <p></p>
                <InformationSubTitle>Adresse postale</InformationSubTitle>
                BP 59<br/>
                L - 4901 Bascharage<br/>
            </CopyrightScroll>
        </>
    )
}

const Mondorf = () => {
    return (
        <>
            <CopyrightTitle>Conditions d'utilisations</CopyrightTitle>

            <CopyrightScroll>
                <h2>1. ACCEPTATION DES CONDITIONS D’UTILISATION</h2>
                <p>Tous les contenus sur ce site sont à titre indicatif. L’Administration communale de Mondorf-les-Bains fait tout son possible pour tenir à jour les données, ne peut cependant pas garantir que les contenus soient parfaitement valides ou justes à tout instant.</p>
                <p>La Commune de Mondorf-les-Bains ne prend aucune responsabilité pour le non-fonctionnement du réseau Internet, ni pour d’éventuels dommages provoqués par les données de tous genres.</p>
                <p>Les liens vers d’autres sites ne marquent pas l’approbation de la Commune de Mondorf-les-Bains qui n’a aucun contrôle sur le contenu de sites n’appartenant pas à la Commune de Mondorf-les-Bains. Notez que, pour tous les documents non-résidents sur ce serveur mais rapatriés d’autres serveurs au moment de votre demande, vous devez vous renseigner sur les conditions d’utilisation auprès du serveur hébergeant ces documents.</p>
                <p>Nous nous réservons le droit de faire tous les changements nécessaires à ces pages sans avertissement préalable. Toute modification remplacera pleinement une version précédente.</p>
                <p>En aucun cas la Commune de Mondorf-les-Bains ne peut être tenue responsable pour des dommages directs ou indirects causés par l’utilisation de son site ou des informations y contenues.</p>
            </CopyrightScroll>
        </>
    )
}

const Smarttouch = () => {
    return (
        <>
            <CopyrightTitle>Conditions d'utilisations</CopyrightTitle>

            <CopyrightScroll>
                <h2>1. ACCEPTATION DES CONDITIONS D’UTILISATION</h2>
                <p>Les présentes conditions d’utilisation sont conclues entre Vous et NICHE GUARDIAN (la "Société", "nous"). Les conditions d’utilisation suivantes (les « Conditions d’utilisation ») régissent votre accès aux kiosques de l’application « SMART-Touch for SMART CITIES » – raccourci « ST4SC » et leur utilisation, ainsi que les fonctionnalités fournies sur les kiosques ST4SC. L’utilisation des kiosques ST4SC et de leurs fonctionnalités est à vos risques et périls.</p>
                <p>Veuillez lire attentivement les Conditions d’utilisation avant d’utiliser les kiosques ST4SC ou l’une de leurs fonctionnalités. En utilisant les kiosques ST4SC ou l’une de leurs fonctionnalités, ou en cliquant sur Accepter les Conditions d’utilisation lorsque cette option vous est proposée, vous acceptez d’être lié par les présentes Conditions d’utilisation. Si vous ne souhaitez pas accepter les présentes Conditions d’utilisation, vous ne devez pas accéder aux kiosques ST4SC ni utiliser l’une de leurs fonctionnalités.</p>
                <p>Nous pouvons réviser et mettre à jour les présentes Conditions d’utilisation de temps à autre, à notre entière discrétion. Toutes les modifications prennent effet immédiatement lorsque nous les publions et s’appliquent à tous les accès et utilisations ultérieurs des kiosques ST4SC ou de l’une de leurs fonctionnalités. Votre utilisation continue des kiosques ST4SC ou de l’une de leurs fonctionnalités après la publication des Conditions d’utilisation révisées signifie que vous acceptez les modifications. Vous devez vérifier cette page chaque fois que vous accédez aux kiosques ST4SC ou à l’une de leurs fonctionnalités afin de prendre connaissance des modifications éventuelles, dans la mesure où elles vous engagent.</p>
                <p>De plus, vous reconnaissez que certaines fonctionnalités fournies sur les kiosques ST4SC sont exploitées ou fournies par, intègrent des API (s) de, ou nécessitent par ailleurs une communication ou une coordination avec des tiers. Nous vous informerons si une fonctionnalité implique un tiers au moment où vous la sélectionnez. Nous n’exerçons aucun contrôle sur ces tiers et vous serez soumis à leurs politiques et conditions de service/conditions d’utilisation lorsque vous utiliserez une fonctionnalité impliquant un tiers. Pour cette raison, nous vous recommandons de prendre connaissance des politiques, conditions d’utilisation et conditions d’utilisation de ces tiers avant de vous engager dans une fonctionnalité fournie par un tiers. À titre d’exemple, la fonctionnalité de guidage des kiosques ST4SC utilise les API de Google Maps et vous êtes lié aux Conditions de service de Google lors de votre utilisation de la fonctionnalité de guidage.</p>

                <h2>2. UTILISATIONS INTERDITES</h2>
                <p>Vous pouvez utiliser les kiosques ST4SC et toutes leurs fonctionnalités uniquement à des fins personnelles, non commerciales et licites, et conformément aux présentes Conditions d’utilisation. Vous acceptez de ne pas utiliser les kiosques ST4SC ni aucune de leurs fonctionnalités :</p>

                <ul>
                <li>De quelque manière que ce soit qui viole une loi ou un règlement communal, d’état, local ou international, ou qui encourage toute activité illégale.</li>
                <li>Dans le but d’exploiter, de nuire ou de tenter d’exploiter ou de nuire ou harceler des mineurs de quelque manière que ce soit, en les exposant à un contenu inapproprié, en demandant des informations personnellement identifiables ou autrement.</li>
                <li>Pour télécharger, transférer, afficher, consulter, envoyer, recevoir en connaissance de cause ou transmettre de quelque manière que ce soit, tout matériel enfreignant les lois ou réglementations fédérales, nationales, locales ou internationales applicables ou les droits de tout individu ou entité établi dans un pays.</li>
                <li>Pour télécharger, transférer, afficher, consulter, envoyer, recevoir en connaissance de cause ou transmettre de quelque manière que ce soit un contenu sexuellement explicite ou pornographique, diffamatoire, obscène, indécent, abusif, offensant, harcelant, violent, haineux, incendiaire ou autrement répréhensible.</li>
                <li>Pour transmettre ou faire envoyer de la publicité, du matériel promotionnel ou des sollicitations.</li>
                </ul>

                <h2>3. SURVEILLANCE ET APPLICATION</h2>
                <p>La Commune, à sa seule discrétion, déterminera si votre conduite est conforme aux présentes Conditions d’utilisation. Nous avons le droit de surveiller votre utilisation des kiosques ST4SC et de leurs fonctionnalités et de prendre toute mesure que nous jugeons nécessaire ou appropriée à notre seule discrétion, si nous estimons que votre conduite a enfreint les présentes Conditions d’utilisation, y compris, sans limitation, le signalement aux forces de l’ordre et le droit de coopérer à toute enquête et le droit de résilier ou de suspendre votre accès à tout ou partie du réseau de kiosques ST4SC.</p>
                <p>VOUS RENONCEZ ET DÉGAGEZ DE TOUTE RESPONSABILITÉ LA COMMUNE ET SES AFFILIÉS, LICENCES ET FOURNISSEURS DE SERVICES DE TOUTE RÉCLAMATION RESULTANT DE TOUTE ACTION ENGAGÉE PAR LA SOCIETE OU PAR L’UNE DES PARTIES PRÉCÉDENTES PENDANT, OU PRISE EN CONSÉQUENCE, DES ENQUÊTES DE LA COMMUNE OU DES AUTORITÉS CHARGÉES DE L’APPLICATION DE LA LOI.</p>
                <p>Vous acceptez de défendre, d’indemniser et de dégager de toute responsabilité la Commune, ses Communes affiliées, ses concédants de licence et ses fournisseurs de services, ainsi que leurs dirigeants, administrateurs, employés, sous-traitants, agents, concédants de licence, fournisseurs, successeurs et ayants droit respectifs, et contre toute réclamation. , responsabilités, dommages, jugements, indemnités, pertes, coûts, dépenses ou honoraires (y compris les honoraires d’avocat raisonnables) résultant ou liés à votre violation des présentes Conditions d’utilisation ou à votre utilisation des kiosques ST4SC et de leurs fonctionnalités.</p>
                <p>Toutes les questions relatives aux kiosques ST4SC, leurs fonctionnalités et les présentes Conditions d’utilisation, ainsi que tout litige ou réclamation en résultant ou apparenté (dans chaque cas, y compris des différends ou des réclamations non contractuels), sont régis par et interprétés conformément à la loi de l’État du Grand-Duché du Luxembourg sans donner effet à un choix ou à un conflit de dispositions de droit ou de règle.</p>

                <h2>4. LIMITATION DE GARANTIES</h2>
                <p>L’UTILISATION DES KIOSQUES ST4SC ET DE LEURS FONCTIONNALITÉS EST À VOS RISQUES ET PÉRILS. LE SITE WEB, SON CONTENU ET TOUT SERVICE OU OBJET OBTENU PAR LES KIOSQUES ST4SC ET LEURS FONCTIONNALITÉS SONT FOURNIS SUR UNE BASE « TEL QUEL » ET « TEL QUE DISPONIBLE », SANS AUCUNE GARANTIE DE QUELQUE NATURE QUE CE SOIT, EXPRESSE OU IMPLICITE. NI LA COMMUNE NI TOUTE PERSONNE ASSOCIÉE A LA COMMUNE NE DONNE AUCUNE GARANTIE OU REPRÉSENTATION EN CE QUI CONCERNE L’EXHAUSTIVITÉ, LA SÉCURITÉ, LA FIABILITÉ, LA QUALITÉ, L’EXACTITUDE OU LA DISPONIBILITÉ DES KIOSQUES ST4SC ET DE LEURS FONCTIONNALITÉS. SANS LIMITER CE QUI PRÉCÈDE, NI LA COMMUNE NI QUELQUE PERSONNE ASSOCIÉE N’EXPRIME NI NE GARANTIT QUE LES KIOSQUES ST4SC ET LEURS FONCTIONNALITÉS SONT EXACTS, FIABLES, EXEMPTS D’ERREUR OU ININTERROMPUS, QUE LES DÉFAUTS SERONT CORRIGÉS, QU’ILS SERONT EXEMPTS DE VIRUS OU D’AUTRES COMPOSANTS NUISIBLES, OU QUE LES KIOSQUES ST4SC ET LEURS FONCTIONNALITÉS RÉPONDRONT AUTREMENT VOS BESOINS OU VOS ATTENTES.</p>
                <p>DANS TOUTE LA MESURE PRÉVUE PAR LA LOI, LA COMMUNE EXCLUT TOUTES GARANTIES DE QUELQUE NATURE QUE CE SOIT, EXPRESSE OU IMPLICITE, STATUTAIRE OU AUTRE, INCLUANT SANS S’Y LIMITER, TOUTES GARANTIES DE QUALITÉ MARCHANDE, DE NON-CONTREFAÇON ET D’ADÉQUATION POUR UN BUT PARTICULIER.</p>
                <p>CE QUI PRECEDE N’AFFECTE PAS LES GARANTIES QUI NE PEUVENT ÊTRE EXCLUES OU LIMITÉES PAR LA LOI EN VIGUEUR.</p>
                <p>Les informations présentées sur ou via les kiosques ST4SC et leurs fonctionnalités sont mises à disposition uniquement à des fins d’information générale. Nous ne garantissons pas l’exactitude, l’exhaustivité ou l’utilité de ces informations. Toute confiance que vous accordez à ces informations est strictement à vos risques et périls. Nous déclinons toute responsabilité résultant de la confiance accordée à ces matériels par vous ou tout autre visiteur des kiosques ST4SC, ou par toute personne susceptible d’être informée de leur contenu.</p>
                <p>Toutes les informations que nous collectons sur les kiosques ST4SC ou par le biais de leurs fonctionnalités sont soumises à notre Politique de confidentialité. En utilisant les kiosques ST4SC ou l’une de leurs fonctionnalités, vous consentez à toutes les mesures que nous avons prises concernant vos informations conformément à la Politique de confidentialité.</p>

                <h2>5. INTÉGRALITÉ DE L’ACCORD</h2>
                <p>Les Conditions d’utilisation et notre Politique de confidentialité constituent le seul et entier accord entre vous et la Commune concernant les kiosques ST4SC et leurs fonctionnalités et remplacent tous les accords, compréhensions, représentations et garanties écrites et orales, antérieurs et contemporains, concernant les kiosques ST4SC et leurs fonctionnalités.</p>

            </CopyrightScroll>
        </>
    )
}

const Waldbillig = () => {
    return (
        <>
            <InformationTitle>HEURES D'OUVERTURE</InformationTitle>

            <InformationSubTitle>Administration communale - service administratif</InformationSubTitle>
            <InformationTable>
                <tr><td>Lundi à vendredi :</td><td>07.30 – 12.00h</td></tr>
                <tr><td>Mardi :</td><td>13.30 – 17.00h</td></tr>
                <tr><td>Jeudi :</td><td>13.30 – 19.00h</td></tr>
            </InformationTable>
            <p></p>
            <InformationTitle>NOUS CONTACTER</InformationTitle>

            <InformationTable>
                <tr><td>Tél. :</td><td>83 72 87</td></tr>
                <tr><td>Email :</td><td>gemeng@waldbillig.lu</td></tr>
            </InformationTable>
            <p></p>
            <InformationTitle>NOUS TROUVER</InformationTitle>

            <InformationSubTitle>Adresses</InformationSubTitle>
            Commune de Waldbillig<br/>
            1, rue André Hentges<br/>
            L-7680 Waldbillig<br/>
        </>
    )
}

const Leudelange = () => {
    return (
        <>
            <CopyrightTitle>Commune de Leudelange</CopyrightTitle>

            <CopyrightScroll>
                <p>5, place des Martyrs</p>
                <p>L-3361 Leudelange</p>
                <p></p>
                <p>Tel. 37 92 92 - 1</p>
                <p>Fax. 37 92 92 - 219</p>

                <CopyrightSubtitle>Permanence Technique</CopyrightSubtitle>

                <p>Tel. 37 92 92 – 444</p>

                <CopyrightSubtitle>Permanence Conciergerie</CopyrightSubtitle>

                <p>Tel. 37 92 92 - 400</p>

                <CopyrightSubtitle>Permanence École (intempéries)</CopyrightSubtitle>

                <p>Tel. 37 92 92 – 300</p>
            </CopyrightScroll>
        </>
    )
}

const Aerenzdall = () => {
    return (
        <>
            <InformationTitle>HEURES D'OUVERTURE</InformationTitle>
            <CopyrightScroll>
                <InformationSubTitle>Administration communale de la Vallée de l'Ernz</InformationSubTitle>
                Lundi à vendredi de 8:00–12:00 et de 14:00–17:00<br/>
                Jeudi de 8:00–12:00 et de 14:00–19:00<br/><br/>

                <InformationTitle>NOUS TROUVER</InformationTitle>
                18, rue de Larochette<br/>
                L-7661 Medernach<br/><br/>

                <InformationTitle>NOUS CONTACTER</InformationTitle>
                <InformationTable>
                Tél. : +352 83 73 02 – 1<br/>
                Email : population@aerenzdall.lu
                </InformationTable>
            </CopyrightScroll>
        </>
    )
}

const Helperknapp = () => {
    return (
        <>
            <InformationTitle>HEURES D'OUVERTURE</InformationTitle>
            <CopyrightScroll>
                <InformationSubTitle>Administration communale - service administratif</InformationSubTitle>
                Lundi de 08:00 à 11:30<br/>
                Mardi de 07:00 à 11:30 et de 13:30 à 16:30<br/>
                Mercredi de 08:00 à 11:30 et de 13:30 à 16:30*<br/>
                Jeudi de 08:00 à 11:30 et de 13:30 à 16:30**<br/>
                Vendredi de 08:00 à 11:30<br/><br/>
                * Nocturne tous les premiers mercredis du mois jusqu'à 18h30<br/><br/>
                ** Jeudi après-midi uniquement sur rendez-vous!<br/><br/>

                Nous vous invitons cependant à nous contacter en avance pour tout dossier concernant l’état civil (mariages, partenariats, indigénat, etc.) au 28 80 40-1 ou par courriel à commune@helperknapp.lu. <br/><br/>

                <InformationSubTitle>Service Technique</InformationSubTitle>
                <Important>Uniquement sur Rendez-vous!</Important><br/><br/>

                <InformationTitle>NOUS CONTACTER</InformationTitle>
                <InformationSubTitle>Numéros de téléphone</InformationSubTitle>
                <InformationTable>
                    <tr><td>Administration communale :</td><td>+352 28 80 40 – 1</td></tr>
                    <tr><td>Bureau de la Population :</td><td>+352 28 80 40 – 220</td></tr>
                    <tr><td>Secrétariat communal :</td><td>+352 28 80 40 – 210</td></tr>
                    <tr><td>Recette communale :</td><td>+352 28 80 40 – 230</td></tr>
                    <tr><td>Service technique :</td><td>+352 28 80 40 – 300</td></tr>
                </InformationTable>
                <InformationSubTitle>En dehors des heures d'ouverture:</InformationSubTitle>
                    <p>Numéro d’urgence :           +352 28 80 40 – 999</p>

                <InformationTitle>NOUS TROUVER</InformationTitle>
                <InformationSubTitle>Administration communale</InformationSubTitle>
                <InformationSubTitle>Service Administratif</InformationSubTitle>
                2, rue Hollenfels<br/>
                L-7481 Tuntange
                <p></p>
                <InformationSubTitle>Bâtiment Service Technique</InformationSubTitle>
                1b, rue de Hollenfels<br/>
                L-7481 Tuntange<br/>
            </CopyrightScroll>
        </>
    )
}

const Sanem = () => {
    return (
        <>
            <InformationTitle>HEURES D'OUVERTURE</InformationTitle>
            <CopyrightScroll>
                <InformationSubTitle>Administration communale - service administratif</InformationSubTitle><br/>
                Du lundi au vendredi : <br/>
                08h30–11h30 et 13h30–16h00<br/><br/>

                <InformationSubTitle>Ouverture exceptionnelle :</InformationSubTitle><br/>
                Les mardis à partir de 07h15<br/>
                Les mercredis jusqu'à 18h00<br/><br/>

                <InformationTitle>NOUS CONTACTER</InformationTitle>
                <InformationSubTitle>Numéro de téléphone</InformationSubTitle>
                <InformationTable>
                    <tr><td>Administration communale :</td><td>+352 59 30 75 - 1</td></tr>
                </InformationTable><br/>

                <InformationTitle>NOUS TROUVER</InformationTitle>
                <InformationSubTitle>Adresses</InformationSubTitle><br/>
                <InformationSubTitle>Commune de Sanem</InformationSubTitle>
                B.P. 74<br/>
                L-4401 Belvaux<br/>
                <p></p>
                60, rue de la Poste<br/>
                L-4477 Belvaux<br/>
            </CopyrightScroll>
        </>
    )
}

const Wincrange = () => {
    return (
        <>
            <InformationTitle>HEURES D'OUVERTURE</InformationTitle>
            <CopyrightScroll>
                Lundi au vendredi: 08:00 à 12:00<br/>
                Mardi et vendredi: 13:00 à 17:00<br/>
                mercredi: 13:00 à 17:00 (Bureau de la population jusqu’à 18:00)<br/><br/>

                <InformationTitle>NOUS CONTACTER</InformationTitle>
                <InformationTable>
                    <tr><td>Téléphone :</td><td>(+352) 99 46 96 – 1</td></tr>
                    <tr><td>Fax :</td><td>(+352) 99 46 96 – 222</td></tr>
                    <tr><td>E-Mail :</td><td>​secretaria​t@wincrange.lu​</td></tr>
                </InformationTable>

                <InformationTitle>NOUS TROUVER</InformationTitle>
                Haaptstrooss 27<br/>
                L – 9780 Wincrange
                <p></p>
            </CopyrightScroll>
        </>
    )
}

const Mamer = () => {
    return (
        <>
            <InformationTitle>HEURES D'OUVERTURE</InformationTitle>
            <CopyrightScroll>
                <InformationSubTitle>Biergerzenter</InformationSubTitle>
                <p></p>
                8h00-11h30 et 13h30-16h30<br/>
                Mercredi de 8h00-11h30 et 13h30-19h00<br/>
                (excepté la veille des jours fériés)<br/><br/>

                <InformationTitle>NOUS CONTACTER</InformationTitle>
                <InformationTable>
                    <tr><td>Tél.:</td><td>310031-1</td></tr>
                    <tr><td>Fax:</td><td>310031-72</td></tr>
                    <tr><td>E-mail:</td><td>info@mamer.lu</td></tr>
                </InformationTable>

                <InformationTitle>NOUS TROUVER</InformationTitle>
                <InformationSubTitle>Administration Communale de Mamer</InformationSubTitle>
                <p></p>
                1, Place de l'Indépendance<br/>
                L-8252 Mamer<br/>
                <p></p>
                B.P. 50 L-8201 Mamer<br/>
            </CopyrightScroll>
        </>
    )
}

const Troisvierges = () => {
    return (
        <>
            <InformationTitle>HEURES D'OUVERTURE</InformationTitle>
            <CopyrightScroll>
                <InformationSubTitle>Administration communale</InformationSubTitle>
                <p></p>
                Lundi / Jeudi : 08h00 – 11h30 et 14h00 – 16h30<br/>
                Mardi : fermee<br/>
                Mercredi : 8h00 – 11h30 et 14h00 – 18h00<br/>
                Vendredi : 8h00 – 11h30<br/>
                Mardi, ainsi que vendredi après-midi sur rendez-vous.<br/>
                (excepté la veille des jours fériés)<br/><br/>

                <InformationTitle>NOUS CONTACTER</InformationTitle>
                <InformationTable>
                    <tr><td>Tél.:</td><td>998050 - 1</td></tr>
                    <tr><td>Fax:</td><td>998050 - 255</td></tr>
                </InformationTable>

                <InformationTitle>NOUS TROUVER</InformationTitle>
                <InformationSubTitle>Administration communale</InformationSubTitle>
                <p></p>
                9-11 Grand-Rue<br/>
                L- 9905 Troisvierges (Ëlwen)<br/>
                <p></p>
                <InformationSubTitle>Adresse postale</InformationSubTitle>
                BP 9 L-9901 Troisvierges<br/>
            </CopyrightScroll>
        </>
    )
}

const Bettendorf = () => {
    return (
        <>
            <InformationTitle>HEURES D'OUVERTURE</InformationTitle>
            <CopyrightScroll>
                <InformationSubTitle>Bureau de la population</InformationSubTitle>
                <p></p>
                Lundi: 08.00 – 11.30 heures et de 13.30 – 18.00 heures<br/>
                Mardi: 08.00 – 11.30 heures et de 13.30 – 16.00 heures<br/>
                Mercredi: fermé<br/>
                Jeudi : 08.00 – 11.30 heures et de 13.30 – 16.00 heures<br/>
                Vendredi: 07.30 – 11.30 heures et de 13.30 – 16.00 heures<br/><br/>

                <InformationSubTitle>Bureau du service technique</InformationSubTitle>
                <p></p>
                Lundi : 8.00 – 11.30 après-midi sur R.V.<br/>
                Mardi : 8.00 – 11.30 après-midi sur R.V.<br/>
                Mercredi: fermé<br/>
                Jeudi : 8.00 – 11.30 après-midi sur R.V.<br/>
                Vendredi : 7.30 – 11.30 après-midi sur R.V.<br/><br/>

                <InformationTitle>NOUS CONTACTER</InformationTitle>
                <InformationTable>
                    <tr><td>Tél.:</td><td>(+352) 281 254</td></tr>
                    <tr><td>Fax:</td><td>(+352) 281 254 299</td></tr>
                </InformationTable>

                <InformationTitle>NOUS TROUVER</InformationTitle>
                <InformationSubTitle>Administration communale</InformationSubTitle>
                <p></p>
                1, Rue Neuve<br/>
                L – 9353 Bettendorf<br/>
                <p></p>
            </CopyrightScroll>
        </>
    )
}

const Vianden = () => {
    return (
        <>
            <ContactTitle>CONTACT</ContactTitle>
            <CopyrightScroll>
                <p></p>
                Administration Communale de la Ville de Vianden<br/>
                Grand-Rue 29<br/>
                L-9410 Vianden<br/><br/>

                B.P. 10<br/>
                L-9401 Vianden<br/>
                <p></p>
                HEURES D’OUVERTURE<br/>
                Bureau de la Population - Etat Civil<br/>
                Lundi, Mercredi et Vendredi de 09h00 à 11h30 et de 13h30 à 16h00<br/>
                Mardi et Jeudi de 09h00 à 11h30 (ou sur rendez-vous)<br/>
                Tél. : (+352) 83 48 21 - 1<br/>
                Mail: secretariat@vianden.lu<br/><br/>

                Recette Communale - UNIQUEMENT SUR RENDEZ-VOUS<br/>
                Tél.: 83 48 21 - 24<br/>
                Service Technique - UNIQUEMENT SUR RENDEZ-VOUS<br/>
                Tél.: 83 48 21 - 25<br/><br/>
                <p></p>
            </CopyrightScroll>
        </>
    )
}

const Steinsel = () => {
    return (
        <>
            <InformationTitle>HEURES D'OUVERTURE</InformationTitle>
            <CopyrightScroll>
                <InformationSubTitle>Administration Communale de Steinsel</InformationSubTitle>
                <p></p>
                Lundi : 8h30 – 11h30 / 13h30 – 18h30*<br/>
                Mardi : 8h30 – 11h30 / 13h30 – 16h00<br/>
                Mercredi : 8h30 – 11h30 / 13h30 – 16h00<br/>
                Jeudi : 8h30 – 11h30 / 13h30 – 16h00<br/>
                Vendredi : 8h30 – 11h30 / 13h30 – 16h00<br/>
                * Bureau de la Population et Réception<br/><br/>

                <InformationTitle>NOUS CONTACTER</InformationTitle>
                <InformationTable>
                    <tr><td>Tél.:</td><td>33 21 39 1</td></tr>
                    <tr><td>Fax:</td><td>33 21 39 39</td></tr>
                    <tr><td>Mail:</td><td>commune@steinsel.lu</td></tr>
                </InformationTable>

                <InformationSubTitle>Services communaux</InformationSubTitle>
                etat.civil@steinsel.lu<br/>
                population@steinsel.lu<br/>
                reception@steinsel.lu<br/>
                recette@steinsel.lu<br/>
                secretariat@steinsel.lu<br/>
                durable@steinsel.lu<br/>
                service.scolaire@steinsel.lu<br/>
                service.technique@steinsel.lu<br/><br/>

                <InformationTitle>NOUS TROUVER</InformationTitle>
                <InformationSubTitle>Administration Communale de Steinsel</InformationSubTitle>
                <p></p>
                9 rue Paul Eyschen<br/>
                L-7317 Steinsel<br/><br/>
                <p></p>
            </CopyrightScroll>
        </>
    )
}

const Useldange = () => {
    return (
        <>
            <InformationTitle>HEURES D'OUVERTURE</InformationTitle>
            <CopyrightScroll>
                <InformationSubTitle>Administration Communale</InformationSubTitle>
                <p></p>
                Lundi: 8:00 - 12:00<br/>
                Mardi: 13:00 - 19:00<br/>
                Mercredi/Jeudi/Vendredi: 8:00 - 12:00<br/><br/>

                <InformationTitle>NOUS CONTACTER</InformationTitle>
                <InformationTable>
                    <tr><td>Tél.:</td><td>(+352) 23 63 00 51 - 1</td></tr>
                    <tr><td>Fax:</td><td>(+352) 23 63 82 27</td></tr>
                </InformationTable>

                <InformationTitle>NOUS TROUVER</InformationTitle>
                <InformationSubTitle>Administration Communale</InformationSubTitle>
                <p></p>
                2, rue de l’Eglise<br/>
                L – 8706 Useldange<br/><br/>
                <p></p>
            </CopyrightScroll>
        </>
    )
}

const Weiswampach = () => {
    return (
        <>
            <InformationTitle>HEURES D'OUVERTURE</InformationTitle>
            <CopyrightScroll>
                <InformationSubTitle>Administration Communale</InformationSubTitle>
                <p></p>
                ---- Service technique uniquement le matin ----<br/>
                Lundi, Mardi et Jeudi: 8:30 - 11:30 / 13:00 - 16:30<br/>
                Mercredi: 8:30 - 11:30 / 13:00 - 16:30 - Chaque 2e<br/>
                Mercredi entre 16:30 et 18:00 heures uniquement sur RDV<br/>
                Vendredi: 8:30 - 11:30 / 13:00 - 16:00<br/><br/>

                <InformationTitle>NOUS CONTACTER</InformationTitle>
                <InformationTable>
                    <tr><td>Tél.:</td><td>+352 97 80 75 - 10</td></tr>
                    <tr><td>Fax:</td><td>+352 97 80 78</td></tr>
                </InformationTable>
                <InformationSubTitle>Data Protection Officer</InformationSubTitle>
                <InformationTable>
                    <tr><td>Tél.:</td><td>+352 97 80 75 - 10</td></tr>
                </InformationTable>

                <InformationTitle>NOUS TROUVER</InformationTitle>
                <InformationSubTitle>Administration Communale</InformationSubTitle>
                <p></p>
                Om Leempuddel<br/>
                L-9991 Weiswampach<br/>
                Luxembourg<br/><br/>

                B.P. 10<br/>
                L-9401 Vianden<br/>
                <p></p>
            </CopyrightScroll>
        </>
    )
}

const Frisange = () => {
    return (
        <>
            <InformationTitle>HEURES D'OUVERTURE</InformationTitle>
            <CopyrightScroll>
                <InformationSubTitle>Services de la commune - Accueil</InformationSubTitle>
                <p></p>
                Lundi: 8h00 – 11h30 / 13h00 – 16h00<br/>
                Mardi: 7h00 – 11h30 / 13h00 – 16h00	<br/>
                Mercredi: 8h00 – 11h30 / 13h00 – 16h00<br/>
                Jeudi: 8h00 – 11h30 / 13h00 – 18h30<br/>
                Vendredi: 8h00 – 11h30 / fermé<br/><br/>
                Les bureaux sont fermés le matin de chaque premier lundi ouvrable du mois.<br/><br/>

                <InformationTitle>NOUS CONTACTER</InformationTitle>
                <InformationTable>
                    <tr><td>Tél.:</td><td>(+352) ​23 66 84 08 - 1</td></tr>
                </InformationTable>
                Mairie, bâtiment principal, rez-de-chaussée.<br/><br/>

                <InformationTitle>NOUS TROUVER</InformationTitle>
                <InformationSubTitle>Administration Communale</InformationSubTitle>
                <p></p>
                10, Munnereferstrooss<br/>
                L-5750 Frisange<br/><br/>

                <p></p>
            </CopyrightScroll>
        </>
    )

}

const LFT = () => {
    return (
        <>
            <CopyrightTitle>Conditions d'utilisations</CopyrightTitle>

            <CopyrightScroll>
                <h2>1. ACCEPTATION DES CONDITIONS D’UTILISATION</h2>
                <p>Les présentes conditions d’utilisation sont conclues entre Vous et NICHE GUARDIAN (la "Société", "nous"). Les conditions d’utilisation suivantes (les « Conditions d’utilisation ») régissent votre accès aux kiosques de l’application « SMART-Touch for SMART CITIES » – raccourci « ST4SC » et leur utilisation, ainsi que les fonctionnalités fournies sur les kiosques ST4SC. L’utilisation des kiosques ST4SC et de leurs fonctionnalités est à vos risques et périls.</p>
                <p>Veuillez lire attentivement les Conditions d’utilisation avant d’utiliser les kiosques ST4SC ou l’une de leurs fonctionnalités. En utilisant les kiosques ST4SC ou l’une de leurs fonctionnalités, ou en cliquant sur Accepter les Conditions d’utilisation lorsque cette option vous est proposée, vous acceptez d’être lié par les présentes Conditions d’utilisation. Si vous ne souhaitez pas accepter les présentes Conditions d’utilisation, vous ne devez pas accéder aux kiosques ST4SC ni utiliser l’une de leurs fonctionnalités.</p>
                <p>Nous pouvons réviser et mettre à jour les présentes Conditions d’utilisation de temps à autre, à notre entière discrétion. Toutes les modifications prennent effet immédiatement lorsque nous les publions et s’appliquent à tous les accès et utilisations ultérieurs des kiosques ST4SC ou de l’une de leurs fonctionnalités. Votre utilisation continue des kiosques ST4SC ou de l’une de leurs fonctionnalités après la publication des Conditions d’utilisation révisées signifie que vous acceptez les modifications. Vous devez vérifier cette page chaque fois que vous accédez aux kiosques ST4SC ou à l’une de leurs fonctionnalités afin de prendre connaissance des modifications éventuelles, dans la mesure où elles vous engagent.</p>
                <p>De plus, vous reconnaissez que certaines fonctionnalités fournies sur les kiosques ST4SC sont exploitées ou fournies par, intègrent des API (s) de, ou nécessitent par ailleurs une communication ou une coordination avec des tiers. Nous vous informerons si une fonctionnalité implique un tiers au moment où vous la sélectionnez. Nous n’exerçons aucun contrôle sur ces tiers et vous serez soumis à leurs politiques et conditions de service/conditions d’utilisation lorsque vous utiliserez une fonctionnalité impliquant un tiers. Pour cette raison, nous vous recommandons de prendre connaissance des politiques, conditions d’utilisation et conditions d’utilisation de ces tiers avant de vous engager dans une fonctionnalité fournie par un tiers. À titre d’exemple, la fonctionnalité de guidage des kiosques ST4SC utilise les API de Google Maps et vous êtes lié aux Conditions de service de Google lors de votre utilisation de la fonctionnalité de guidage.</p>

                <h2>2. UTILISATIONS INTERDITES</h2>
                <p>Vous pouvez utiliser les kiosques ST4SC et toutes leurs fonctionnalités uniquement à des fins personnelles, non commerciales et licites, et conformément aux présentes Conditions d’utilisation. Vous acceptez de ne pas utiliser les kiosques ST4SC ni aucune de leurs fonctionnalités :</p>

                <ul>
                <li>De quelque manière que ce soit qui viole une loi ou un règlement communal, d’état, local ou international, ou qui encourage toute activité illégale.</li>
                <li>Dans le but d’exploiter, de nuire ou de tenter d’exploiter ou de nuire ou harceler des mineurs de quelque manière que ce soit, en les exposant à un contenu inapproprié, en demandant des informations personnellement identifiables ou autrement.</li>
                <li>Pour télécharger, transférer, afficher, consulter, envoyer, recevoir en connaissance de cause ou transmettre de quelque manière que ce soit, tout matériel enfreignant les lois ou réglementations fédérales, nationales, locales ou internationales applicables ou les droits de tout individu ou entité établi dans un pays.</li>
                <li>Pour télécharger, transférer, afficher, consulter, envoyer, recevoir en connaissance de cause ou transmettre de quelque manière que ce soit un contenu sexuellement explicite ou pornographique, diffamatoire, obscène, indécent, abusif, offensant, harcelant, violent, haineux, incendiaire ou autrement répréhensible.</li>
                <li>Pour transmettre ou faire envoyer de la publicité, du matériel promotionnel ou des sollicitations.</li>
                </ul>

                <h2>3. SURVEILLANCE ET APPLICATION</h2>
                <p>La Commune, à sa seule discrétion, déterminera si votre conduite est conforme aux présentes Conditions d’utilisation. Nous avons le droit de surveiller votre utilisation des kiosques ST4SC et de leurs fonctionnalités et de prendre toute mesure que nous jugeons nécessaire ou appropriée à notre seule discrétion, si nous estimons que votre conduite a enfreint les présentes Conditions d’utilisation, y compris, sans limitation, le signalement aux forces de l’ordre et le droit de coopérer à toute enquête et le droit de résilier ou de suspendre votre accès à tout ou partie du réseau de kiosques ST4SC.</p>
                <p>VOUS RENONCEZ ET DÉGAGEZ DE TOUTE RESPONSABILITÉ LA COMMUNE ET SES AFFILIÉS, LICENCES ET FOURNISSEURS DE SERVICES DE TOUTE RÉCLAMATION RESULTANT DE TOUTE ACTION ENGAGÉE PAR LA SOCIETE OU PAR L’UNE DES PARTIES PRÉCÉDENTES PENDANT, OU PRISE EN CONSÉQUENCE, DES ENQUÊTES DE LA COMMUNE OU DES AUTORITÉS CHARGÉES DE L’APPLICATION DE LA LOI.</p>
                <p>Vous acceptez de défendre, d’indemniser et de dégager de toute responsabilité la Commune, ses Communes affiliées, ses concédants de licence et ses fournisseurs de services, ainsi que leurs dirigeants, administrateurs, employés, sous-traitants, agents, concédants de licence, fournisseurs, successeurs et ayants droit respectifs, et contre toute réclamation. , responsabilités, dommages, jugements, indemnités, pertes, coûts, dépenses ou honoraires (y compris les honoraires d’avocat raisonnables) résultant ou liés à votre violation des présentes Conditions d’utilisation ou à votre utilisation des kiosques ST4SC et de leurs fonctionnalités.</p>
                <p>Toutes les questions relatives aux kiosques ST4SC, leurs fonctionnalités et les présentes Conditions d’utilisation, ainsi que tout litige ou réclamation en résultant ou apparenté (dans chaque cas, y compris des différends ou des réclamations non contractuels), sont régis par et interprétés conformément à la loi de l’État du Grand-Duché du Luxembourg sans donner effet à un choix ou à un conflit de dispositions de droit ou de règle.</p>

                <h2>4. LIMITATION DE GARANTIES</h2>
                <p>L’UTILISATION DES KIOSQUES ST4SC ET DE LEURS FONCTIONNALITÉS EST À VOS RISQUES ET PÉRILS. LE SITE WEB, SON CONTENU ET TOUT SERVICE OU OBJET OBTENU PAR LES KIOSQUES ST4SC ET LEURS FONCTIONNALITÉS SONT FOURNIS SUR UNE BASE « TEL QUEL » ET « TEL QUE DISPONIBLE », SANS AUCUNE GARANTIE DE QUELQUE NATURE QUE CE SOIT, EXPRESSE OU IMPLICITE. NI LA COMMUNE NI TOUTE PERSONNE ASSOCIÉE A LA COMMUNE NE DONNE AUCUNE GARANTIE OU REPRÉSENTATION EN CE QUI CONCERNE L’EXHAUSTIVITÉ, LA SÉCURITÉ, LA FIABILITÉ, LA QUALITÉ, L’EXACTITUDE OU LA DISPONIBILITÉ DES KIOSQUES ST4SC ET DE LEURS FONCTIONNALITÉS. SANS LIMITER CE QUI PRÉCÈDE, NI LA COMMUNE NI QUELQUE PERSONNE ASSOCIÉE N’EXPRIME NI NE GARANTIT QUE LES KIOSQUES ST4SC ET LEURS FONCTIONNALITÉS SONT EXACTS, FIABLES, EXEMPTS D’ERREUR OU ININTERROMPUS, QUE LES DÉFAUTS SERONT CORRIGÉS, QU’ILS SERONT EXEMPTS DE VIRUS OU D’AUTRES COMPOSANTS NUISIBLES, OU QUE LES KIOSQUES ST4SC ET LEURS FONCTIONNALITÉS RÉPONDRONT AUTREMENT VOS BESOINS OU VOS ATTENTES.</p>
                <p>DANS TOUTE LA MESURE PRÉVUE PAR LA LOI, LA COMMUNE EXCLUT TOUTES GARANTIES DE QUELQUE NATURE QUE CE SOIT, EXPRESSE OU IMPLICITE, STATUTAIRE OU AUTRE, INCLUANT SANS S’Y LIMITER, TOUTES GARANTIES DE QUALITÉ MARCHANDE, DE NON-CONTREFAÇON ET D’ADÉQUATION POUR UN BUT PARTICULIER.</p>
                <p>CE QUI PRECEDE N’AFFECTE PAS LES GARANTIES QUI NE PEUVENT ÊTRE EXCLUES OU LIMITÉES PAR LA LOI EN VIGUEUR.</p>
                <p>Les informations présentées sur ou via les kiosques ST4SC et leurs fonctionnalités sont mises à disposition uniquement à des fins d’information générale. Nous ne garantissons pas l’exactitude, l’exhaustivité ou l’utilité de ces informations. Toute confiance que vous accordez à ces informations est strictement à vos risques et périls. Nous déclinons toute responsabilité résultant de la confiance accordée à ces matériels par vous ou tout autre visiteur des kiosques ST4SC, ou par toute personne susceptible d’être informée de leur contenu.</p>
                <p>Toutes les informations que nous collectons sur les kiosques ST4SC ou par le biais de leurs fonctionnalités sont soumises à notre Politique de confidentialité. En utilisant les kiosques ST4SC ou l’une de leurs fonctionnalités, vous consentez à toutes les mesures que nous avons prises concernant vos informations conformément à la Politique de confidentialité.</p>

                <h2>5. INTÉGRALITÉ DE L’ACCORD</h2>
                <p>Les Conditions d’utilisation et notre Politique de confidentialité constituent le seul et entier accord entre vous et la Commune concernant les kiosques ST4SC et leurs fonctionnalités et remplacent tous les accords, compréhensions, représentations et garanties écrites et orales, antérieurs et contemporains, concernant les kiosques ST4SC et leurs fonctionnalités.</p>
            </CopyrightScroll>
        </>
    )
}

const SalonDesCommunes = () => {
    return (
        <>
            <CopyrightTitle>CONTACT</CopyrightTitle>
            <p></p>
            <CopyrightScroll>
                <InformationSubTitle>NOUS CONTACTER</InformationSubTitle>
                <p></p>
                Administration communale de la Ville d'Ettelbruck<br/>
                Place de l'Hôtel de Ville<br/>
                L-9087 Ettelbruck<br/>
                <p></p>
                Addresse postale:<br/>
                B.P.116<br/>
                L-9002 Ettelbruck<br/>
                <p></p>
                En dehors des heures de bureau<br/>
                Permanence eau: 621 466 163<br/>
                Permanence électricité: 621 188 158<br/>
                Permanence canalisation: 621 234 364<br/>
                <p></p>
                Tél. (+352) 81 91 81-1<br/>
                Fax (+352) ​81 91 81-364<br/>
                <p></p>
                <p></p>
                <InformationSubTitle>NOS HORAIRES D'OUVERTURE</InformationSubTitle>
                <p></p>
                Du lundi au vendredi de 08:00 à 11:30 et de 13:30 à 16:30<br/>
                Nocturne mercredi (pour les services «Accueil», «Bureau de la population» et «Etat Civil» de 08:00 à 11:30 et de 13:30 à 18:30<br/>

            </CopyrightScroll>
        </>
    )
}

const Ettelbruck = () => {
    return (
        <>
            <CopyrightTitle>CONTACT</CopyrightTitle>
            <p></p>
            <CopyrightScroll>
                <InformationSubTitle>NOUS CONTACTER</InformationSubTitle>
                <p></p>
                Administration communale de la Ville d'Ettelbruck<br/>
                Place de l'Hôtel de Ville<br/>
                L-9087 Ettelbruck<br/>
                <p></p>
                Addresse postale:<br/>
                B.P.116<br/>
                L-9002 Ettelbruck<br/>
                <p></p>
                En dehors des heures de bureau<br/>
                Permanence eau: 621 466 163<br/>
                Permanence électricité: 621 188 158<br/>
                Permanence canalisation: 621 234 364<br/>
                <p></p>
                Tél. (+352) 81 91 81-1<br/>
                Fax (+352) ​81 91 81-364<br/>
                <p></p>
                <p></p>
                <InformationSubTitle>NOS HORAIRES D'OUVERTURE</InformationSubTitle>
                <p></p>
                Du lundi au vendredi de 08:00 à 11:30 et de 13:30 à 16:30<br/>
                Nocturne mercredi pour les services «Accueil», «Bureau de la population» et «Etat Civil» de 08:00 à 11:30 et de 13:30 à 18:30<br/>

            </CopyrightScroll>
        </>
    )

}

export {
    EschVelok,
    Mersch,
    Petange,
    Hesperange,
    Dippach,
    Mondorf,
    Smarttouch,
    Waldbillig,
    Leudelange,
    Aerenzdall,
    Helperknapp,
    Sanem,
    Wincrange,
    Mamer,
    Troisvierges,
    Bettendorf,
    Vianden,
    Steinsel,
    Useldange,
    Weiswampach,
    Frisange,
    LFT,
    SalonDesCommunes,
    Ettelbruck,
};
