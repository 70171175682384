import React, {useEffect, useState} from 'react';
import {Translate} from 'react-localize-redux';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useLocation} from 'react-router-dom';
import styled, {css} from 'styled-components';
import images from '../config/images';
import SwipeIndicator from '../components/SwipeIndicator';
import {
    SET_ACTIONBUTTON,
    SET_LARGE_LAYOUT,
    SET_NAVITEM,
    SET_SELECTED_EVENT,
    SET_SELECTED_IMXPOI,
    SET_SELECTED_POI,
    SET_SELECTED_WHATTODO,
    SET_SUBNAVITEM,
    SET_SWIPEINDICATOR
} from '../redux/actions';

const Nav = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    ${props => (props.theme.layout !== 4) && `background: ${props.theme.shade1};`}

    // ${props => (props.theme.layout === 4) ? `
    //     border-left: 1px solid white;
    // ` : `
    //     border-left: none;
    // `}
`;

const NavContent = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: flex-start;
    transition: max-height 0.3s linear;    

    ${props => (props.theme.layout === 3 || props.theme.layout === 4) ? `
        flex-direction: column;
        
    ` : `
        ${props.$navstate === 'open' ? `max-height: ${props.theme.scale * 800}px;` : `max-height: ${props.theme.scale * 185}px;`};
    `}
`;

const activeclassname = 'active';
const NavItem = styled(NavLink).attrs({
        activeclassname
    })`
    text-align: center;
    display: flex;
    flex-direction: column; 
    font-size: ${props => props.theme.scale * 20}px; 
    font-family: 'MuseoSans-900';
    height: ${props => props.theme.scale * 185}px; 
    justify-content: center;
    align-content: center;
    box-sizing: border-box; 
    text-decoration: none;
    display: block;
    cursor: pointer;
    user-select: none;    

    ${(props) =>
        props.theme.navDefault
            ? `
            color: ${props.theme.navDefault};
            svg path, svg line {
                stroke: ${props.theme.navDefault}; 
            }
    
            svg .needfill {
                stroke: none;
                fill: ${props.theme.navDefault}; 
            }
            `
            : `
            color: ${props.theme.white};
            svg path, svg line {
                stroke: ${props.theme.white}; 
            }
    
            svg .needfill {
                stroke: none;
                fill: ${props.theme.white}; 
            }
    `}

    &.${activeclassname} {
        ${(props) => props.theme.navHighlightBackground && `background-color: ${props.theme.navHighlightBackground};` }
        
        ${(props) =>
            props.theme.navHighlight
                ? `
                color: ${props.theme.navHighlight};
                svg path, svg line {
                    stroke: ${props.theme.navHighlight}; 
                }
        
                svg .needfill {
                    stroke: none;
                    fill: ${props.theme.navHighlight}; 
                }
                `
                : `
                color: ${props.theme.shade4};
                svg path, svg line {
                    stroke: ${props.theme.shade4}; 
                }
        
                svg .needfill {
                    stroke: none;
                    fill: ${props.theme.shade4}; 
                }
        `}
    }

    .rtl {
        fill: white !important;
        stroke: none !important;
    }

    ${props => (props.theme.layout === 3 || props.theme.layout === 4) ? `
        width: 100%;
        padding: ${props.theme.scale * 28}px ${props.theme.scale * 10}px; 
        font-size: ${props.theme.scale * 20}px; 
    ` : `
        width: 20%;
        padding: ${props.theme.scale * 28}px 0; 
        font-size: ${props.theme.scale * 20}px; 
    `}
`;

const NavItemNoLink = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column; 
    font-family: 'MuseoSans-900';
    height: ${props => props.theme.scale * 185}px; 
    justify-content: center;
    align-content: center;
    box-sizing: border-box; 
    text-decoration: none;
    display: block;
    cursor: pointer;
    user-select: none;
    opacity: 0.2;

    ${(props) =>
        props.theme.navDefault
            ? `
            color: ${props.theme.navDefault};
            svg path, svg line {
                stroke: ${props.theme.navDefault}; 
            }
    
            svg .needfill {
                stroke: none;
                fill: ${props.theme.navDefault}; 
            }
            `
            : `
            color: ${props => props.theme.white};
            svg path, svg line {
                stroke: ${props.theme.white}; 
            }
    
            svg .needfill {
                stroke: none;
                fill: ${props.theme.white}; 
            }
    `}

    ${props => (props.theme.layout === 3 || props.theme.layout === 4) ? `
        width: 100%;
        padding: ${props.theme.scale * 28}px ${props.theme.scale * 10}px; 
        font-size: ${props.theme.scale * 20}px; 
    ` : `
        width: 20%;
        padding: ${props.theme.scale * 28}px 0; 
        font-size: ${props.theme.scale * 20}px; 
    `}
`;

const NavIcon = styled.div`
    margin: 0 auto;

    svg {
        height: 100%;
    }

    ${props => (props.theme.layout === 4) ? `
        width: ${props.theme.scale * 60}px; 
        height: ${props.theme.scale * 60}px; 
    ` : `
        width: ${props.theme.scale * 70}px; 
        height: ${props.theme.scale * 70}px; 
    `}
`;

const NavTitle = styled.div`
    margin-top: ${props => props.theme.scale * 18}px;
`;

const SwipeIndicatorWrapper = styled(SwipeIndicator)`
    position: absolute;
    top: ${props => props.theme.scale * ((props.theme.contentHeight / 2) - 400)}px;
    left: ${props => props.theme.scale * 37}px;
`;

const SwipeIndicatorWrapperLandscape = styled(SwipeIndicator)`
    position: absolute;
    top: ${props => props.theme.scale * ((props.theme.contentHeight / 2) - 200)}px;
    left: ${props => props.theme.scale * 63}px;
`;

const lbTop = css`
    top: ${props => props.theme.scale * props.theme.navIconTop}px;
`;

const lbBottom = css`
    bottom: ${props => props.theme.scale * 320}px;
`;

const NavMenuIcon = styled.div`
    position: absolute;
    right: -${props => props.theme.scale * props.theme.asideWidth}px;
    width: ${props => props.theme.scale * props.theme.asideWidth}px;
    height: ${props => props.theme.scale * 80}px;
    z-index: 999;
    text-align: center;
    cursor: pointer;
    user-select: none;

    ${props => props.$mr ? lbBottom : lbTop};

    ${props => props.theme.layout === 4 && `
        display: none;
    `}

    ${(props) =>
        props.theme.menuIconColor
            ? `
            color: ${props.theme.white};
            svg {
                width: ${props.theme.scale * 62}px;
                height: ${props.theme.scale * 62}px;
                color: ${props.theme.menuIconColor};

            
                path, line {
                    stroke: ${props.theme.menuIconColor} !important;
                }
            }
            `
            : `
            color: ${props.theme.white};
            svg {
                width: ${props.theme.scale * 62}px;
                height: ${props.theme.scale * 62}px;
                color: ${props.theme.white};
        
            
                path, line {
                    stroke: ${props.theme.white} !important;
                }
            }
    `}
`;

const MenuIconLabel = styled.div`
    font-size: ${props => props.theme.scale * 20}px; 
    font-family: 'MuseoSans-500';

    ${(props) =>
        props.theme.menuIconColor
            ? `
            color: ${props.theme.menuIconColor};
            `
            : `
            color: ${props.theme.white};
    `}
`;

const checkSubnav = (item) => {
    let firstNav;
    
    if (item.subnavigation) {
        for (let i = 0; i < item.subnavigation.length; i++) {
            const subnav = item.subnavigation[i];
            if (subnav.status === 2) {
                firstNav = subnav;
                break;
            }
        }
        
        return firstNav;
    } else return;
}

const Navigation = ({className}) => {
    const navItems = useSelector(state => state.config.navigation);
    const navItem = useSelector(state => state.config.navItem);
    const layout = useSelector(state => state.config.layout);
    const theme = useSelector(state => state.config.theme);
    let location = useLocation();
    const mobiliteReduite = useSelector(state => state.config.mobiliteReduite);
    const swipeIndicator = useSelector(state => state.config.swipeIndicator);
    const [navState, setNavState] = useState('closed');
    const dispatch = useDispatch();

    //console.log("nav", navItems);

    useEffect(() => {
        if (location) {
            navItems.forEach(navItem => {
                if (location.pathname.includes(navItem.id)) {
                    //Set MainNav
                    dispatch({ type: SET_NAVITEM, navItem: navItem});
                    // Set Subnav if possible
                    dispatch({ type: SET_SUBNAVITEM, subNavItem: checkSubnav(navItem)});
                }
            });
        }
    }, [location, navItems, dispatch]);

    if(!navItems) return(null);

    const toggleNav = (e) => { 
        if (navState === 'open') setNavState('closed');
        else setNavState('open');
        e.stopPropagation();
    }

    const itemClicked = (item) => { 
        setNavState('closed');

        // Set MainNav
        dispatch({ type: SET_NAVITEM, navItem: item});

        // Set Subnav if possible
        dispatch({ type: SET_SUBNAVITEM, subNavItem: checkSubnav(item)});

        // Clear the rest
        dispatch({ type: SET_SELECTED_POI, poi: undefined});
        dispatch({ type: SET_SELECTED_WHATTODO, whattodo: undefined});
        dispatch({ type: SET_SELECTED_IMXPOI, imxpoi: undefined});
        dispatch({ type: SET_SELECTED_EVENT, event: undefined});
        dispatch({ type: SET_ACTIONBUTTON, actionButton: undefined});
        // dispatch({ type: SET_SWIPEINDICATOR, state: true});
        dispatch({ type: SET_LARGE_LAYOUT, largeLayout: false});
    }

    const MenuIconSVG = images.icons.menuComponent;

    const hideSwiper = () => { 
        if (swipeIndicator === true) dispatch({ type: SET_SWIPEINDICATOR, state: false});
    }

    return (
        <Nav className={className} onMouseDown={hideSwiper} onTouchStart={hideSwiper} onScroll={hideSwiper}>
            <NavContent $navstate={navState}>
            {navItems.map((item) => {
                let navIconContainer;

                if (!theme.iconSet || theme.useDefaultNavIcons) {
                    let Icon = images.nav[item.id];
                    navIconContainer = <NavIcon><Icon /></NavIcon>;
                } else {

                    if (!navItem) navIconContainer = <NavIcon><img src={`images/themes/${theme.iconSet}/nav_${item.id}.svg`} alt={item.id} /></NavIcon>;
                    else {
                        if (location.pathname.includes(item.id)) {
                            navIconContainer = <NavIcon><img src={`images/themes/${theme.iconSet}/nav_${item.id}_active.svg`} alt={item.id} /></NavIcon>;
                        } else {
                            navIconContainer = <NavIcon><img src={`images/themes/${theme.iconSet}/nav_${item.id}.svg`} alt={item.id} /></NavIcon>;
                        }
                    }
                }

                return (
                    <React.Fragment key={item.id}>
                    {(item.status === 1 && item.id !== 'home') && 
                        <NavItemNoLink activeclassname={activeclassname}>
                            {navIconContainer}
                            <NavTitle><Translate id={"navigation." + item.id} /></NavTitle>
                        </NavItemNoLink>
                    }
                    {(item.status === 2 && item.id !== 'home') && 
                        <NavItem to={'/' + item.id} activeclassname={activeclassname} onClick={() => itemClicked(item)}>
                            {navIconContainer}
                            <NavTitle><Translate id={"navigation." + item.id} /></NavTitle>
                        </NavItem>
                    }
                    </React.Fragment>
                )
                
            })}
            
            {(swipeIndicator && layout === 4) && <SwipeIndicatorWrapper direction="v" />}
            {(swipeIndicator && layout === 3) && <SwipeIndicatorWrapperLandscape direction="v" />}
            </NavContent>
            
            {navItems.length > 5 && (
                <NavMenuIcon $mr={mobiliteReduite}>
                    <MenuIconSVG alt="Menu" onClick={toggleNav} data-on="click" data-event-category="Navigation" data-event-action="open"/>
                    <MenuIconLabel>MENU</MenuIconLabel>
                </NavMenuIcon>
                
            )}
        </Nav>
    );
}

export default Navigation;