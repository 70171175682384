import React, {useRef} from "react";
import {useSelector} from "react-redux";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";
import videojs from "video.js";
import {Container, Loading} from "../components/Layout";
import VideoJS from "../components/Player";

const HomeContainer = styled(Container)`
    background: ${(props) => props.theme.white};
`;

const SliderWrapper = styled.div`
    height: ${(props) => props.theme.scale * props.theme.contentHeight}px;
    width: ${(props) => props.theme.scale * props.theme.contentWidth}px;
    overflow: hidden;
    flex: 0 0 auto;
    margin-bottom: ${(props) => props.theme.scale * 20}px;
`;

const SwiperItem = styled.div`
    .swiper-slide__container {
        max-height: ${(props) =>
            props.theme.scale * props.theme.contentHeight}px;
        transition: max-height 0.5s;
        overflow: hidden;

        video {
            width: ${(props) => props.theme.scale * props.theme.contentWidth}px;
            height: ${(props) =>
                props.theme.scale * props.theme.contentHeight}px;
        }
    }

    img {
        width: ${(props) => props.theme.scale * props.theme.contentWidth}px;
        height: ${(props) => props.theme.scale * props.theme.contentHeight}px;
    }

    h2 {
        position: absolute;
        color: white;
        bottom: ${(props) => props.theme.scale * 30}px;
        left: ${(props) => props.theme.scale * 55}px;
        right: ${(props) => props.theme.scale * 55}px;
        font-size: ${(props) => props.theme.scale * 40}px;
        font-family: "MuseoSans-700";
        background-color: rgba(0, 0, 0, 0.6);
        padding: ${(props) => props.theme.scale * 10}px;
    }
`;

const StyledSlickSlider = styled(SlickSlider)`
    &:focus {
        outline: none;
    }
    .slick-slide {
        position: relative;
        width: ${(props) => props.theme.scale * props.theme.contentWidth}px;
        height: ${(props) => props.theme.scale * props.theme.contentHeight}px;
        transition: padding 0.5s, opacity 0.5s;
        overflow: hidden;
        opacity: 0.7;
    }

    .slick-current {
        opacity: 1;
    }
`;

const VideoSlider = () => {
    const ads = useSelector((state) => state.data.videoads);
    const SliderTime = 10000; // Default 10000
    const mySlider = useRef(undefined);

    let autoplay = false;

    const params = {
        infinite: true,
        speed: 500, // Default 500
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        fade: true,
        autoplaySpeed: 1000,
        autoplay: autoplay,
        pauseOnHover: false,
        afterChange: function (index) {
            mySlider.current.autoplay = false;

            let video = document.querySelector('div[data-index="' + index + '"] .video-js');
            if (video) {
                let player = videojs(video);
                player.play();
                player.on("ended", () => {
                    mySlider.current.slickNext();
                });
            } else {
                setTimeout(() => { mySlider.current.slickGoTo(index+1);}, SliderTime);
            }
        },
        beforeChange: function (index, newIndex) {
            let video = document.querySelector('div[data-index="' + index + '"] .video-js');
            if (video) {
                let player = videojs(video);
                if (index !== newIndex) {
                    player.pause();
                }
            }
        },
    };

    const playerRef = React.useRef(null);

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // you can handle player events here
        player.on("ended", () => {
            mySlider.current.slickNext();
        });
    };

    const handleImgOnload = () => {
        if (mySlider.current) {
            setTimeout(() => {
                mySlider.current.slickGoTo(1);
            }, SliderTime);
        }   
    };

    let slides = [];

    if (ads) {
        slides = ads.map(
            (ad, i) => {
                if (ad.video) {
                    let defaultOptions = {
                        muted: true,
                        controls: false,
                        responsive: true,
                        preload: "auto",
                        fluid: true,
                        aspectRatio: "540:763",
                        sources: [{
                                src: ad.video,
                                type: "video/mp4",
                            },],
                        poster: ad.placeholderurl,
                    };

                    let videoJsOptionsAuto = {...defaultOptions, autoplay: "any"};
                    let videoJsOptions = {...defaultOptions, autoplay: false};

                    return (
                        <SwiperItem key={i}>
                            <div className="swiper-slide__container" id={"video" + i}>
                                {i === 0 ? (
                                    <VideoJS
                                        options={videoJsOptionsAuto}
                                        onReady={handlePlayerReady}
                                        key={i}
                                    />
                                ) : (
                                    <VideoJS options={videoJsOptions} key={i} />
                                )}
                            </div>
                        </SwiperItem>
                    );
                } else {
                    return (
                        <SwiperItem key={i}>
                            {i === 0 ? (
                                <img
                                    src={ad.image}
                                    alt={ad.title}
                                    onLoad={handleImgOnload}
                                />
                            ) : (
                                <img src={ad.image} alt={ad.title} />
                            )}
                        </SwiperItem>
                    );
                }
            },
            [SwiperItem]
        );
    }

    return (
        <HomeContainer>
            <SliderWrapper>
                {ads ? (
                    <StyledSlickSlider ref={mySlider} {...params}>
                        {slides}
                    </StyledSlickSlider>
                ) : (
                    <Loading />
                )}
            </SliderWrapper>
        </HomeContainer>
    );
};

export default VideoSlider;
