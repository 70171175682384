import React from 'react';
import {createRoot} from 'react-dom/client';
import {LocalizeProvider} from "react-localize-redux";
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import './index.css';
import SmartTouch from './pages/SmartTouch';
import reducer from './redux/reducers';
import rootSaga from './redux/sagas';
import * as serviceWorker from './serviceWorker';

import 'autotrack';

// Send Ping
function fetchStatus() {
    fetch('http://localhost:8000/integrity_ping')
      .catch(function (err) { });
}

window.addEventListener('load', function () {
    setInterval(fetchStatus, 10000);
});

if (typeof document !== 'undefined') {
    window.addEventListener('load', () => {
        const ga = window.ga;
        ga('create', 'UA-151880220-1', 'auto');

        // Only require the plugins you've imported above.
        ga('require', 'eventTracker',{
            attributePrefix: 'data-'
          });
        ga('require', 'urlChangeTracker');
        ga('require', 'maxScrollTracker');

        ga('send', 'pageview');
    });
}

const sagaMiddleware = createSagaMiddleware();

const middlewares = [];
middlewares.push(sagaMiddleware);

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);

  middlewares.push(logger);
}

const store = createStore(
    reducer,
    applyMiddleware(...middlewares),
 );

 sagaMiddleware.run(rootSaga);

const container = document.getElementById('smart-touch');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <LocalizeProvider store={store}>
            <SmartTouch />
        </LocalizeProvider>
    </Provider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();