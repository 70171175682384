import {useSelector} from 'react-redux';
import GeoPortailMap from "./GeoPortailMap";
import GMap from "./GMap";

const PoiMap = ({type, poitype}) => {
    const theme = useSelector((state) => state.config.theme);

    let map;
    (theme.maptype === 'geoportail') ? map = <GeoPortailMap type={type} poitype={poitype} /> :  map = <GMap type={type} poitype={poitype} />;

    return (
        <>
            {map}
        </>
    );
}

export default PoiMap;