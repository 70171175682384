import {useSelector} from 'react-redux';
import GeoPortailEventMap from "./GeoPortailEventMap";
import GEventMap from "./GEventMap";

const EventMap = ({type, poitype}) => {
    const theme = useSelector((state) => state.config.theme);

    let map;
    (theme.maptype === 'geoportail') ? map = <GeoPortailEventMap /> :  map = <GEventMap />;


    return (
        <>
            {map}
        </>
    );
}

export default EventMap;