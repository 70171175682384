import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Container} from '../components/Layout';
import images from '../config/images';
import {
    SET_HOME
} from '../redux/actions';

const HomeContainer = styled(Container)`
    background: ${props => props.theme.shade1};
`;

const HomeBG = styled.img`
    width: 100%;
    position: absolute;
    top: ${props => props.theme.scale * - 68}px;
    left: ${props => props.theme.scale * 0}px;
    z-index: 0;
`;

const Home = () => {
    const events = useSelector(state => state.data.eventsToday);
    const dispatch = useDispatch();

    if (!events) return null;

    const toggleNav = (e) => { 
        dispatch({ type: SET_HOME, home: false});
        e.stopPropagation();
    }

    return (
        <HomeContainer direction="column">
            <Link to="/mobility" onClick={toggleNav}><HomeBG src={images.home.homeBg} alt="Home" /></Link>
        </HomeContainer>
    );
}

export default Home;