
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

const DateTimeWrapper = styled.div`
    text-align: center;
`;

const Time = styled.div`
    font-size: ${props => props.theme.scale * 36}px; 
    line-height: ${props => props.theme.scale * 34}px; 
    font-family: 'MuseoSans-900',serif;
    color:  ${props => props.theme.datetime};
`;

const Date = styled.div`
    font-size: ${props => props.theme.scale * 20}px; 
    font-family: 'MuseoSans-500',serif;
    color:  ${props => props.theme.datetime};
`;

const DateTime = ({ className }) => {
    const [time, setTime] = useState(dayjs().format('HH:mm'));
    const [date, setDate] = useState(dayjs().format('DD.MM.YYYY'));

    useEffect(() => {
        let interval = null;
        
        interval = setInterval(() => {
            let newTime = dayjs().format('HH:mm');
            let newDate = dayjs().format('DD.MM.YYYY');
            setTime(newTime);
            setDate(newDate);
        }, 1000);
        
        return () => clearInterval(interval);
    }, [time, date]);

    return (
        <DateTimeWrapper className={className}>
            <Time>{time}</Time>
            <Date>{date}</Date>
        </DateTimeWrapper>
    );
}

export default DateTime;