import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import images from '../config/images';
import {SET_MOBILITE_REDUITE} from '../redux/actions';


const NavItem = styled.div`
    text-align: center;
    display: block;
    padding: ${props => props.theme.scale * 12}px;
    width: 100%;
    box-sizing: border-box; 
    text-decoration: none;
`;

const NavIcon = styled.div`
   
    ${(props) =>
        props.theme.mrIconColor
            ? `
            svg {
                width: ${props.theme.scale * 58}px;
                height: ${props.theme.scale * 58}px;
                color: ${props.theme.white}; 
            }
        
            path {
                fill: ${props.$mr ? props.theme.mrIconActiveColor : props.theme.mrIconColor };
            }
            `
            : `
            svg {
                width: ${props.theme.scale * 58}px;
                height: ${props.theme.scale * 58}px;
                color: ${props.theme.white}; 
            }
        
            path {
                fill: ${props.$mr ? props.theme.mrIconActiveColor : props.theme.mrIconColor };
            }
    `}
`;

const ThemeIcon = styled.div`
    text-align: center;
    display: block;

    img {
        width: ${props => props.theme.scale * 34}px;
    }
`;

const ThemeIconActive = styled.div`
    text-align: center;
    display: block;

    img {
        width: ${props => props.theme.scale * 40}px;
    }
`;

const MobiliteReduite = ({ className }) => {
    const mobiliteReduite = useSelector(state => state.config.mobiliteReduite);
    const theme = useSelector(state => state.config.theme);
    const dispatch = useDispatch();

    const buttonClicked = () => {
        dispatch({ type: SET_MOBILITE_REDUITE, mobiliteReduite: !mobiliteReduite});
    }

    let icon;
    if (!theme.iconSet || theme.useDefaultMrIcons) {
        let Icon = images.icons.mr;
        icon = <Icon />;
    } else {
        if (mobiliteReduite) {
            icon = <ThemeIconActive><img src={`images/themes/${theme.iconSet}/ic_mr_active.svg`} alt="MR" /></ThemeIconActive>;
        } else {
            icon = <ThemeIcon><img src={`images/themes/${theme.iconSet}/ic_mr.svg`} alt="MR" /></ThemeIcon>;
        }
    }

    return (
        <div className={className}>
            <NavItem to="/" onClick={() => buttonClicked()} data-on="click" data-event-category="MobiliteReduite" data-event-action="click">
                <NavIcon $mr={mobiliteReduite}>{icon}</NavIcon>
            </NavItem>
        </div>
    );
}

export default MobiliteReduite;