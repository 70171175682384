import {useState} from "react";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {
    SET_HOME,
    SET_MOBILITE_REDUITE,
    SET_SHOWCOPYRIGHT,
    SET_SHOWHOWTO
} from "../redux/actions";

const InactivityCheck = () => {
    const [isRunning, setIsRunning] = useState(false);
    const dispatch = useDispatch();
    let location = useLocation();
    let navigate = useNavigate();

    var inactivityTime = function () {
        let time;
        setIsRunning(true);
        window.onload = resetTimer;
        // DOM Events
        document.ontouchstart = resetTimer;
        document.ontouchmove = resetTimer;
        document.onkeypress = resetTimer;
        document.onclick = resetTimer;
        document.onmousemove = resetTimer;
        document.onmousedown = resetTimer;
        document.ontouchmove = resetTimer;

        var gotToHome = function () {
            let cUrl = new URL(window.location);
            //console.log(location.pathname);
            //console.log(cUrl.pathname);
            if (cUrl.pathname !== location.pathname) {
                if (location.pathname === "/home" || location.pathname === "/ads" || location.pathname === "/") {
                    dispatch({
                        type: SET_MOBILITE_REDUITE,
                        mobiliteReduite: false,
                    });
                    dispatch({type: SET_HOME, home: true});
                } else {
                    //console.log("NOT HOME");
                    dispatch({type: SET_HOME, home: false});
                }
                navigate(location.pathname);
            }

            // Hide Copyright / Howto
            dispatch({type: SET_SHOWCOPYRIGHT, value: false});
            dispatch({type: SET_SHOWHOWTO, value: false});
        };

        function resetTimer() {
            clearTimeout(time);
            time = setTimeout(gotToHome, 60000); // 60s 60000
        }
    };

    if (isRunning === false) {
        inactivityTime();
    }

    return null;
};

export default InactivityCheck;
